import React from 'react'
import TopCard from './TopCard'
import UserList from './UserList'
import userServices from 'services/httpService/userAuth/userServices';
import { toast } from "react-toastify";

const GuestCard = ({ setStepsAndDetail, Guest, Host, allUser }) => {
  const headers = ["Registration Date", "Ratings", "Verified", "Status", "Last Login", "Action"];
 
  const heading = "Guests/Hosts";

  const handleDeleteUser = async (userId) => {
      try {
        await userServices.deleteUser(`/userAuth/deleteUser/${userId}`);
        toast.success('User deleted successfully');
      } catch (error) {
        toast.error('Failed to delete user');
        console.error('Error deleting user:', error);
      }
    };
  
  
  return (
    <>
      <TopCard allUsers={allUser} />
      <UserList headers={headers} data={Guest} heading={heading} setStepsAndDetail={setStepsAndDetail} onDeleteUser={handleDeleteUser} />
    </>
  )
}

export default GuestCard