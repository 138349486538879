import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import userService from "services/httpService/userAuth/userServices";
import {
  nextStep,
  previousStep,
  setInitialFormData,
  resetForm
} from "redux/store/actions/userAction/formAction";
import { localStorageData } from "services/auth/localStorageData";
import PropertyStarting from "./PropertyStarting";
import PropertyType from "./PropertyType";
import SpaceType from "./SpaceType";
import PropertyAddress from "./PropertyAddress";
import AddAmenities from "./AddAmenities";
import AddImages from "./AddImages";
import AddCharacteristics from "./AddCharacteristics";
import AddTitle from "./AddTitle";
import SetPricing from "./SetPricing";
import AddOtherSitesLinks from "./AddOtherSitesLinks";
import Preview from "./Preview";

const steps = [
  "Start",
  "Property Type",
  "Space Type",
  "Property Address",
  "Amenities",
  "Images",
  "Title",
  "Characteristics",
  "Pricing",
  "OtherSitesLinks",
  "Preview",
];

function PropertyListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const { activeStep, formData } = useSelector((state) => state.form);
  const propertyData = location?.state || null;
  const handleNext = () => {
    if (activeStep === 1) {
      if (!formData.propertyType) {
        toast.error("Please select a property type before proceeding!");
        return;
      }
    }

    if (activeStep === 2) {
      if (!formData.spaceType) {
        toast.error("Please select a space type before proceeding!");
        return;
      }

      const { spaceTypeDetail } = formData;
      if (
        !spaceTypeDetail ||
        spaceTypeDetail.guests === 0 ||
        spaceTypeDetail.bedrooms === 0 ||
        spaceTypeDetail.bathrooms === 0
      ) {
        toast.error(
          "Please provide the number of guests, bedrooms, and bathrooms before proceeding!"
        );
        return;
      }
    }

    if (activeStep === 3) {
      if (!formData.address) {
        toast.error("Please provide a property address before proceeding!");
        return;
      }
    }

    if (activeStep === 4) {
      if (!formData.amenities || formData.amenities.length === 0) {
        toast.error("Please select at least one amenity before proceeding!");
        return;
      }
    }

    if (activeStep === 5) {
      if (!formData.photos || formData.photos.length < 5) {
        toast.error("Please upload at least 5 images before proceeding!");
        return;
      }
    }

    if (activeStep === 6) {
      if (!formData.title) {
        toast.error("Please provide a title before proceeding!");
        return;
      }

      if (!formData.description) {
        toast.error("Please provide a description before proceeding!");
        return;
      }
    }

    if (activeStep === 7) {
      if (!formData.characteristics || formData.characteristics.length === 0) {
        toast.error("Please select at least one characteristic before proceeding!");
        return;
      }
    }

    if (activeStep === 8) {

      if (!formData.points || formData.points.length === 0) {
        toast.error("Please provide guest price details before proceeding!");
        return;
      }
    }

    dispatch(nextStep());
  };


  useEffect(() => {
    if (propertyData) {
      const mappedData = {
        propertyType: propertyData.propertyType || "",
        spaceType: propertyData.spaceType || "",
        spaceTypeDetail: propertyData.spaceTypeDetail || { guests: 0, bedrooms: 0, bathrooms: 0 },
        address: propertyData.address || "",
        amenities: propertyData.amenities || [],
        photos: propertyData.pics || [],
        title: propertyData.title || "",
        description: propertyData.description || "",
        listing: propertyData.listing || "",
        points: propertyData.guestPrice || [],
        characteristics: propertyData.characteristics || [],
        price: propertyData.price || 0,
        cleaningFee: propertyData.cleaningFee || 0,
        discountPrice: propertyData.discountPrice || 0,
        amenitiesFee: propertyData.amenitiesFee || 0,
        isPropertyAvaibleOnOtherSites: propertyData.isPropertyAvaibleOnOtherSites || [],
        lat: propertyData.loc?.coordinates[1] || [],
        long: propertyData.loc?.coordinates[0] || [],
        id: propertyData?._id,
      };
      dispatch(setInitialFormData(mappedData));
    }
  }, [propertyData, dispatch]);

  const { mutate: createProperty, isLoading: createLoading } = useMutation(
    (data) => userService.commonPostService("/property/uploadProperty", data),
    {
      onSuccess: () => {
        toast.success("Property uploaded! Please wait for admin approval.");
        dispatch(resetForm());
        navigate("/");
      },
      onError: (error) => {
        toast.error("Failed to upload property. Please try again.");
      },
    }
  );

  const { mutate: updateProperty, isLoading: updateLoading } = useMutation(

    (data) => {
      return (
        userService.updatePostService(
          `/property/updatePropertyById/${propertyData._id}`,
          data
        )
      )
    },
    {
      onSuccess: () => {
        toast.success("Property updated successfully!");
        dispatch(resetForm());
        navigate("/");
      },
      onError: (error) => {
        toast.error("Failed to update property. Please try again.");
      },
    }
  );

  const onSubmit = () => {
    const formDataPayload = new FormData();

    formData.amenities.forEach((item) => {
      const formattedAmenity = {
        id: item.id,
        label: item.label,
        status: item.status,
        value: item.value,
      };
      formDataPayload.append("amenities", JSON.stringify(formattedAmenity));
    });

    const existingPhotos = [];
    const newPhotos = [];
    formData.photos.forEach((photo) => { if (typeof photo === "string") { existingPhotos.push(photo);  

    } else if (photo.file) { newPhotos.push(photo.file);  } });
    // existingPhotos.forEach((photo) => { formDataPayload.append("photos", photo); });
    formDataPayload.append("photos", JSON.stringify(existingPhotos));
    newPhotos.forEach((photo) => { formDataPayload.append("pics", photo); });
    
    formData.characteristics.forEach((item) => {
      const formattedCharacteristic = {
        id: item.id,
        label: item.label,
        status: item.status,
        value: item.value,
      };
      formDataPayload.append(
        "characteristics",
        JSON.stringify(formattedCharacteristic)
      );
    });
    if (Array.isArray(formData.isPropertyAvaibleOnOtherSites)) {
      formData.isPropertyAvaibleOnOtherSites.forEach((link) => {
        formDataPayload.append("isPropertyAvaibleOnOtherSites[]", link);
      });
    } else {
      formDataPayload.append("isPropertyAvaibleOnOtherSites[]", "");
    }
    formDataPayload.append("address", formData.address || "");
    formDataPayload.append("lat", formData.lat || 0);
    formDataPayload.append("long", formData.long || 0);
    formDataPayload.append("price", formData.price || 0);
    formDataPayload.append("guestPrice", JSON.stringify(formData.points || []));
    formDataPayload.append("cleaningFee", formData.cleaningFee || 0);
    formDataPayload.append("discountPrice", formData.discountPrice || 0);
    formDataPayload.append("amenitiesFee", formData.amenitiesFee || 0);
    formDataPayload.append("propertyType", formData.propertyType || "house");
    formDataPayload.append("spaceType", formData.spaceType || "entire");

    if (formData.spaceTypeDetail) {
      formDataPayload.append(
        "spaceTypeDetail",
        JSON.stringify(formData.spaceTypeDetail)
      );
    }

    formDataPayload.append("title", formData.title || "Default Title");
    formDataPayload.append(
      "description",
      formData.description || "Default Description"
    );
    formDataPayload.append("listing", formData.listing || "");
    formDataPayload.append("points", JSON.stringify(formData.points || []));
    formDataPayload.append("userCurrency", formData.userCurrency || "");
    formDataPayload.append("isAvailable", true);
    formDataPayload.append("userId", localStorageData("_id"));
    if (formData.id) {
      formDataPayload.append("propertyId", formData.id);
    }
    ;
    if (formData.id) {
      updateProperty(formDataPayload);
    } else {
      createProperty(formDataPayload);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <PropertyStarting />;
      case 1:
        return <PropertyType />;
      case 2:
        return <SpaceType />;
      case 3:
        return <PropertyAddress />;
      case 4:
        return <AddAmenities />;
      case 5:
        return <AddImages />;
      case 6:
        return <AddTitle />;
      case 7:
        return <AddCharacteristics />;
      case 8:
        return <SetPricing />;
      case 9:
        return <AddOtherSitesLinks />;
      case 10:
        return <Preview />;
      default:
        return;
    }
  };

  return (
    <div>
      <div>{getStepContent(activeStep)}</div>
      <div>
        {activeStep > 0 && (
          <button onClick={() => dispatch(previousStep())}>Back</button>
        )}
        <div className="fixed bottom-0 right-0 w-full z-[20px] bg-white h-20">
          <LinearProgress
            variant="determinate"
            value={(activeStep / (steps.length - 1)) * 100}
            sx={{
              width: "100%",
              height: "8px",
              backgroundColor: "#AFB5C1",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#E8AD21",
              },
            }}
          />

          <div className={`flex my-3 justify-between`}>
            {activeStep !== 0 ? (
              <button
                className="text-black font-bold ml-20"
                onClick={() => dispatch(previousStep())}
              >
                Back
              </button>
            ) : (
              <div></div>
            )}

            {activeStep === steps.length - 1 ? (
              <>
                {createLoading || updateLoading ? (
                  <CircularProgress />
                ) : (
                  <button
                    className="border-red-700 mr-8 bg-[#2459BF] w-[150px] h-[50px] rounded-full py-2 px-4 text-white shadow hover:shadow-lg mb-1 ease-linear transition-all duration-150"
                    onClick={onSubmit}
                  >
                    {propertyData ? "Update Listing" : "Publish Listing"}
                  </button>
                )}
              </>
            ) : (
              <button
                className="border-red-700 bg-[#2459BF] w-[150px] h-[50px] rounded-full py-2 px-4 text-white shadow hover:shadow-lg mb-1 ease-linear transition-all duration-150"
                onClick={handleNext}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyListing;
