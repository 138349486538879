import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import profileIcon from "../../../assets/img/profile.png";
import cameraIcon from "../../../assets/img/camera.svg";
import plusIcon from "../../../assets/img/plusIcon.svg";
import { ButtonFilled } from "common/buttons/buttonFilled";
import { CiCircleRemove } from "react-icons/ci";
import { localStorageData } from "services/auth/localStorageData";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { useFormik } from "formik";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import { Logout } from "services/auth/localStorageData";
import ErrorService from "services/formatError/ErrorService";
import { storeLocalData } from "services/auth/localStorageData";
import { CircularProgress } from "@mui/material";
import like from "../../../assets/img/like.png"
import { FaUtensils, FaRunning, FaBook, FaMusic, FaDog, FaBasketballBall, FaPlane, FaCamera, FaBeer, FaLandmark, FaArchway, FaPaintBrush, FaBalanceScale, FaSeedling, FaPenFancy, FaTshirt } from 'react-icons/fa';
import Rating from "react-rating";

const InterestsContainer = ({ icon, name, onRemove }) => {
  return (
    <div className="flex w-[170px] cursor-pointer relative items-center capitalize justify-center p-4 h-[50px] bg-[#F9F9F9] border-2 text-[#646464]  border-[#C1E1C2] gap-4  font-medium rounded-2xl">
      {icon && <span>{icon}</span>}
      {!icon && <img src={like} alt="" className="w-6 h-6 " />}
      <p className="text-[16px] ">{name}</p>
      <span className="cursor-pointer " onClick={onRemove}>
        <CiCircleRemove className="text-red-600" />
      </span>
    </div>
  );
};

export const UpdateProfile = ({ setSteps }) => {
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState(
    localStorageData("pic") || profileIcon
  );
  const [userInterests, setUserInterests] = useState([]);
  const [newInterest, setNewInterest] = useState("");
  const [history, setHistory] = useState([]);
  const [showAddInterestModal, setShowAddInterestModal] = useState(false);

  const getReservation = async () => {
    try {
      const apiUrl = `/reservation/reservations/${localStorageData("_id")}`;
      let res = await userServices.getReservationByuserId(apiUrl);

      const currentDate = new Date();

      const historyFilteredData =
        res.data &&
        res.data.filter((item) => {
          if (item.offerState && item.offerState.checkIn) {
            const checkInDate = new Date(item.offerState.checkIn);
            return checkInDate < currentDate;
          }
          return false;
        });

      setHistory(historyFilteredData);
    } catch (error) {
      console.error("Error fetching reservation data", error);
    }
  };

  useEffect(() => {
    getReservation();
  }, []);

  useEffect(() => {
    const interestsFromLocalStorage = localStorageData("interest") || [];
    setUserInterests(interestsFromLocalStorage);
    formik.setFieldValue("interest", interestsFromLocalStorage);
  }, []);

  const formik = useFormik({
    initialValues: {
      userId: localStorageData("_id"),
      pics: localStorageData("pic"),
      fname: localStorageData("fname"),
      lname: localStorageData("lname"),
      phoneNumber: localStorageData("phoneNumber"),
      address: localStorageData("address"),
      pic: localStorageData("pic"),
      about: localStorageData("about") || "",
      interest: [],
    },
    validationSchema: Yup.object().shape({
      userId: Yup.string().required("User ID is required"),
      about: Yup.string()
        .required("About is required")
        .min(50, "About must be at least 50 characters")
        .max(4000, "About must be at most 4000 characters"),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("pics", values.pic);
      formData.append("userId", values.userId);
      formData.append("fname", values.fname);
      formData.append("lname", values.lname);
      formData.append("phoneNumber", values.phoneNumber);
      formData.append("address", values.address);
      formData.append("about", values.about);
      userInterests.forEach((interest, index) => {
        formData.append(`interest[${index}][name]`, interest.name);
      });

      mutate(formData);
    },
  });

  const letterCount = formik.values.about.length;

  const { mutate, isLoading } = useMutation(
    (token) =>
      userServices.commonPostService("/userAuth/updateuserinfo", token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        if (data?.data?.data.isEnable == "no") {
          toast.success("your account deactivated successfully");
          Logout();
          navigate("/");
        } else {
          storeLocalData(data?.data.data);
          setSteps("profileDetails");
          toast.success("updated successfully!");
        }
      },
    }
  );
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
        formik.setFieldValue("pic", file);
      };
      reader.readAsDataURL(file);
    }
  };

  const predefinedInterests = [
    { name: "Cooking", icon: <FaUtensils /> },
    { name: "Exercise", icon: <FaRunning /> },
    { name: "Reading", icon: <FaBook /> },
    { name: "Music", icon: <FaMusic /> },
    { name: "Pets", icon: <FaDog /> },
    { name: "Sports", icon: <FaBasketballBall /> },
    { name: "Travel", icon: <FaPlane /> },
    { name: "Photography", icon: <FaCamera /> },
    { name: "Restaurants", icon: <FaUtensils /> },
    { name: "Wine & Beer", icon: <FaBeer /> },
    { name: "History", icon: <FaLandmark /> },
    { name: "Architecture", icon: <FaArchway /> },
    { name: "Art", icon: <FaPaintBrush /> },
    { name: "Politics", icon: <FaBalanceScale /> },
    { name: "Gardening", icon: <FaSeedling /> },
    { name: "Writing", icon: <FaPenFancy /> },
    { name: "Fashion", icon: <FaTshirt /> },
  ];

  const interestsLocalStorage = localStorageData("interest");

  const findIconByName = (interestName) => {
    const predefinedInterest = predefinedInterests.find(
      (interest) => interest.name === interestName
    );
    return predefinedInterest ? predefinedInterest.icon : null;
  };

  const combinedInterests = interestsLocalStorage?.map((interest) => {
    const icon = findIconByName(interest.name);
    return { name: interest.name, icon: icon };
  });

  const addInterest = (name, icon) => {
    if (!userInterests.find((interest) => interest.name == name)) {
      setUserInterests((prevInterests) => [...prevInterests, { name, icon }]);
      formik.setFieldValue("interest", userInterests);
      setShowAddInterestModal(false);
    }
  };

  const addOtherInterest = (e) => {
    e.preventDefault();
    if (newInterest.trim() !== "") {
      addInterest(newInterest, null);
      setNewInterest("");
    }
    else{
      toast.success("Please fill in the interest field before adding!");
    }
  };

  const removeInterest = (name) => {
    setUserInterests((prevInterests) =>
      prevInterests.filter((interest) => interest.name !== name)
    );

    let interestValue = formik.values.interest.filter(
      (interest) => interest.name != name
    );

    formik.setFieldValue("interest", interestValue);
  };
  const handleForm = (e) => {
    e.preventDefault();
    if (formik.errors.about) {
      toast.error(formik.errors.about);
    } else {
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    setUserInterests(combinedInterests);
  }, []);

  return (
    <div className="p-5 md:px-20 md:py-6 ">
      <div className=" text-black font-Avenir text-[36px] font-medium leading-[42px]">
        Your Profile
      </div>
      <form>
        <div className="flex flex-col md:flex-row w-full h-auto my-4 border rounded-[15px] border-color-green overflow-hidden">
          <div className="flex flex-col items-center justify-start px-5 py-10 space-y-6 bg-white md:px-none md:w-4/12">
            <div className="relative">
              <img
                className="h-[187px] w-[187px] rounded-full object-cover"
                alt="Profile"
                src={imageSrc}
              />
              <div className="absolute w-full -bottom-3">
                <label
                  htmlFor="fileInput"
                  className=" mx-auto h-[31px] cursor-pointer w-[130px] text-white bg-[#E8AD21] flex gap-4 items-center justify-center rounded-full"
                >
                  <div className="text-white">
                    <img src={cameraIcon} alt="" />
                  </div>
                  <input
                    id="fileInput"
                    className="hidden"
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                  <span>Add Image</span>
                </label>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl font-medium ">Add a profile picture</p>
              <p className="text-base font-normal text-[#4B4B4B] text-center">
                We do not accept company logos or names, and they will be
                removed if applied.
              </p>
            </div>
          </div>
          <div className="w-full h-auto px-5 py-10 space-y-6 bg-white border-l border-color-green md:px-14">
            <p className="text-2xl font-semibold">About yourself</p>
            <textarea
              className="w-full p-4 text-xl bg-gray-100 border-2 rounded-md outline-none border-color-green hover:border-blue-500"
              id="about"
              name="about"
              placeholder="Type here"
              rows="4"
              onChange={formik.handleChange}
              value={formik.values.about}
            />
            <span>{letterCount}/4000</span>
            <div className="">
              <p className="text-xl font-semibold">What you're into</p>
              <p className="py-5 text-lg">
                Find common ground with other guests and Hosts by adding
                interests to your profile.
              </p>
              <div className="flex flex-wrap gap-4">
                {userInterests?.map((interest, index) => (
                  <InterestsContainer
                    key={index}
                    name={interest.name}
                    icon={interest.icon}
                    onRemove={() => removeInterest(interest.name)}
                  />
                ))}
                <div
                  className="flex w-[150px] cursor-pointer items-center justify-center h-[50px] bg-[#F9F9F9] border-2 text-[#646464] border-color-green font-medium rounded-2xl"
                  onClick={() => setShowAddInterestModal(true)}
                >
                  <img src={plusIcon} alt="" />
                </div>
              </div>
              {showAddInterestModal && (
                <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50 overflow-y-auto">
                  <div className="p-8 pt-52 md:pt-16 xl:pt-5 bg-white rounded-md ">
                    <p className="mb-4 text-lg font-semibold">
                      Select an interest
                    </p>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4">
                      {predefinedInterests?.map((interest) => (
                        <div
                          key={interest.name}
                          className="flex items-center justify-start px-1 md:px-4 py-2 bg-[#F9F9F9] border-2 border-[#AFB5C1] rounded-2xl cursor-pointer"
                          onClick={() =>
                            addInterest(interest.name, interest.icon)
                          }
                        >
                          <span className="mr-2 text-[#AFB5C1]">
                            {interest.icon}</span>
                          <p className="text-gray-500">{interest.name}</p>
                        </div>
                      ))}
                    </div>
                    <div className="my-2">
                      <div className="mb-5">
                        <p className="mt-3 mb-1 font-bold">Add others</p>
                        <div className="flex gap-5">
                          <input
                            type="text"
                            placeholder="Add any other interest or sports "
                            className="border border-slate-300 rounded-md p-2 w-full"
                            value={newInterest}
                            onChange={(e) => setNewInterest(e.target.value)}
                          />
                          <button className="py-2 px-4 text-white bg-blue-500 rounded-md"
                            onClick={addOtherInterest}
                          >
                            Add
                          </button>
                        </div>
                      </div>

                    </div>
                    <div className="flex justify-center items-center">
                      <button
                        className="p-2 mt-4 text-white bg-blue-500 rounded-md"
                        onClick={() => setShowAddInterestModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="text-lg font-medium">
              <span className="border-b border-black">
                Add interests and sports
              </span>
            </p>
            <div className="w-[20%] mr-auto">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <ButtonFilled text="save" onClick={handleForm} />
              )}
            </div>
            <p className="text-xl font-semibold ">Your past trips</p>

            <div className="flex flex-wrap w-full gap-2 ">
              {history.slice(0, 4).map((items, index) => {

                const minPoint = Array.isArray(items.points) && items.points.length > 0
                ? items.points.reduce((min, p) => (p.point < min ? p.point : min), items.points[0].point)
                : null;

                return (
                  <div className="md:w-[49%] my-4 border border-color-grey rounded-md overflow-hidden">
                    <div className="md:flex gap-2">
                      <div className="w-full flex justify-between p-2 ">
                        <div>
                          <div className="flex items-center ">
                            <Rating
                              name="size-small"
                              readOnly
                              defaultValue={2}
                              size="small"
                            />
                            <span> (2)</span>
                          </div>
                          <p className="text-xl font-semibold mt-4">
                            {items.property_id.title}
                          </p>


                          <hr className="" />
                          <div className=" my-1 flex justify-between items-center">

                            <p>
                              <span className="text-color-grey text-xs line-through">
                                {minPoint }
                              </span>{" "}
                              <span className="text-color-red">
                                {minPoint } Points
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="17"
                            viewBox="0 0 18 17"
                            fill="none"
                          >
                            <path
                              d="M12.9287 0.754883C12.2506 0.755708 11.58 0.901914 10.958 1.18448C10.336 1.46705 9.77595 1.87999 9.31233 2.39783C8.63292 1.63891 7.75121 1.11128 6.78167 0.883437C5.81213 0.655595 4.79954 0.738065 3.87535 1.12014C2.95115 1.50221 2.15804 2.16625 1.59898 3.02604C1.03992 3.88583 0.740724 4.90166 0.740234 5.94166C0.740234 11.106 8.69889 16.175 9.04445 16.3741C9.12778 16.4265 9.22316 16.4542 9.32037 16.4542C9.41758 16.4542 9.51296 16.4265 9.59628 16.3741C9.92577 16.175 17.8844 11.106 17.8844 5.94166C17.883 4.5665 17.3604 3.24808 16.4314 2.2757C15.5023 1.30331 14.2426 0.756367 12.9287 0.754883ZM9.31233 15.233C7.9274 14.3078 1.81175 9.98455 1.81175 5.94166C1.81243 5.07812 2.07583 4.23724 2.56377 3.54088C3.05171 2.84453 3.73884 2.32889 4.52557 2.06868C5.3123 1.80848 6.15777 1.81723 6.93943 2.09367C7.72108 2.37012 8.39833 2.89989 8.87301 3.60621C8.92247 3.67968 8.98807 3.73963 9.06429 3.78102C9.14051 3.8224 9.22512 3.844 9.31099 3.844C9.39686 3.844 9.48147 3.8224 9.55769 3.78102C9.63392 3.73963 9.69952 3.67968 9.74897 3.60621C10.2233 2.89868 10.9007 2.36776 11.6828 2.09054C12.465 1.81332 13.3112 1.80422 14.0987 2.06456C14.8861 2.3249 15.5738 2.84114 16.0619 3.53833C16.55 4.23551 16.813 5.07736 16.8129 5.94166C16.8129 9.98175 10.6973 14.305 9.31233 15.233Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="w-full relative">
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
