import React, { useEffect, useState } from 'react';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { localStorageData } from "../../../services/auth/localStorageData";
import userService from '../../../services/httpService/userAuth/userServices';

export const TrackPayments = () => {
    const [allPayments, setAllPayments] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [filterStatus, setFilterStatus] = useState("All");

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const formatReservationId = (id) => {
        if (!id) return '';
        if (id.length <= 4) return id;
        return `...${id.slice(-4)}`;
    };

    const getPaymentDetails = async () => {
        try {
            const userId = localStorageData("_id");

            if (!userId) {
                console.error('No user ID found');
                return;
            }

            const response = await userService.getPaymentDetailsById(userId);

            if (response && response.data) {
                setAllPayments(response.data);
                setFilteredPayments(response.data);
            }
        } catch (error) {
            console.error('Error fetching payment details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
        }
    };

    useEffect(() => {
        getPaymentDetails();
    }, []);

    useEffect(() => {
        if (filterStatus === "All") {
            setFilteredPayments(allPayments);
        } else {
            setFilteredPayments(allPayments.filter(payment => {
                if (filterStatus === "Refund") {
                    return payment.refund_status === "refunded";
                } else if (filterStatus === "Paid") {
                    return payment.payment_status === "pending" || payment.payment_status === "paid";
                }
                return false;
            }));
        }
    }, [filterStatus, allPayments]);

    const handleFilterChange = (status) => {
        setFilterStatus(status);
    };

    return (
        <div className='md:my-10 md:mx-20 flex flex-col gap-4'>
            <div className="rounded-2xl border border-[#C1E1C2] h-auto w-full p-4">
                <div className='p-5 flex justify-between'>
                    <p className="text-2xl capitalize font-medium">
                        Transaction History
                    </p>
                    <div className="h-[40px] w-[100px] rounded-[50px] border border-[#AEAFB0] text-[#AEAFB0] bg-[#FFFFFF] flex justify-between px-4 py-2 relative cursor-pointer" onClick={() => document.getElementById('filterDropdown').classList.toggle('hidden')}>
                        <span>{filterStatus}</span>
                        <div className="my-auto">
                            <IoIosArrowDown />
                        </div>
                        <div id="filterDropdown" className="absolute top-full left-0 mt-1 w-full bg-white border border-[#AEAFB0] rounded-md shadow-lg hidden">
                            <div className="py-1">
                                <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleFilterChange("All")}>All</div>
                                <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleFilterChange("Refund")}>Refund</div>
                                <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleFilterChange("Paid")}>Paid</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto max-h-[600px] overflow-y-auto">
                    <table className="min-w-full table-fixed">
                        <thead className='bg-color-secondry bg-opacity-10'>
                            <tr className="space-x-3">
                                <th className="py-4 px-4 text-left font-semibold">Booking ID#</th>
                                <th className="py-4 px-4 text-left font-semibold">Check-In's & Out's</th>
                                <th className="py-5 px-4 text-left font-semibold">Location</th>
                                <th className="py-4 px-4 text-left font-semibold">Transactions</th>
                                <th className="py-4 px-4 text-left font-semibold">Status</th>
                                <th className="py-4 px-4 text-left font-semibold"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPayments.map((payment, index) => {
                                return (
                                    <tr key={index} className="border-b-2 border-color-[#AFB5C1] text-[#4B4B4B] text-[16px]">
                                        <td className="py-5 px-4 font-normal">{formatReservationId(payment.reservation_id)}</td>
                                        <td className='py-5 px-4 font-normal'>
                                            {`${formatDate(payment.check_in)} - ${formatDate(payment.check_out)}`}
                                        </td>
                                        <td className='py-5 px-4 font-normal'>{payment.property_location}</td>
                                        <td className="py-5 px-4 font-normal">
  {/* Container arranged in a vertical column */}
  <div className="flex flex-col gap-2">
    <span
      className={`p-1 px-5 rounded-md whitespace-nowrap ${
        payment.refund_status === 'refunded'
          ? 'text-[#EC3434] bg-[#EC3434] bg-opacity-10'
          : 'text-[#2459BF] bg-[#58C0D0] bg-opacity-10'
      } font-medium`}
    >
      {payment.total_cost} Points
    </span>
    <span className={`p-1 px-5 rounded-md ${
        payment.refund_status === 'refunded'
          ? 'text-[#EC3434] bg-[#EC3434] bg-opacity-10'
          : 'text-[#2459BF] bg-[#58C0D0] bg-opacity-10'
      } font-medium`}>
    $ {payment.serviceFee} Fee
    </span>
  </div>
</td>



                                        <td className='py-5 px-4 font-normal'>
                                            {payment.refund_status === 'refunded'
                                                ? 'Refund'
                                                : payment.refund_status === 'completed'
                                                    ? 'Completed'
                                                    : 'Paid'}
                                        </td>
                                        <td className="py-5 px-4">
                                            <IoIosArrowForward className="w-6 h-6 cursor-pointer" />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};