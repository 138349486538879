import React from "react";
import createProfileImg from "../../../assets/img/createProfileImg.png";
import { ButtonFilled } from "common/buttons/buttonFilled";
import { ProfileLeftSection } from "components/ProfileLeftSection";

export const CreateProfile = ({ setSteps }) => {
    return (
        <div className="flex flex-col w-full gap-10 px-5 md:flex-row lg:px-32 py-7 md:py-20">
            <ProfileLeftSection hidediv={true} />
            <div className="w-full h-auto bg-white rounded-xl overflow-hidden border border-[#C1E1C2] py-6 px-14 space-y-6">
                <div className="flex items-center justify-center mt-20">
                    <img
                        className="w-419.7 h-267.8 object-cover"
                        src={createProfileImg}
                        alt="Create Profile"
                    />
                </div>
                <div className="py-8 mt-4 space-y-4 text-center">
                    <p className="text-black text-center font-Avenir text-[20px] font-medium leading-[23px]">
                        It's time to create your profile
                    </p>
                    <p className="text-[#929293] text-center font-Lato text-[16px] font-normal leading-[22px]">
                        Your BnByond profile is an important part of every reservation.
                        Create yours to help other Hosts and guests get to know you.
                    </p>
                    <div className="w-full md:w-[80%] lg:w-2/5 flex justify-center mx-auto">
                        <ButtonFilled text="Create Profile" onClick={() => setSteps('updateProfile')} />
                    </div>
                </div>
            </div>
        </div>
    );
};
