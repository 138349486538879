import React, { useState } from 'react';
import { Payments } from './payments';
import { Payouts } from './payouts';

export const PaymentsAndPayouts = ({ setViewPayout, viewPayout }) => {
    const [selectedTab, setSelectedTab] = useState('payments');

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    return (
        <div className="md:my-10 md:mx-20 flex flex-col gap-4">
            <div className="text-xl font-medium text-center text-gray-500 border-b-2 border-gray-200">
                <ul className="flex flex-wrap -mb-px cursor-pointer text-[#AFB5C1]">
                    <li className="me-2">
                        <p
                            onClick={() => handleTabClick('payments')}
                            className={`inline-block p-4 border-b-2 ${
                                selectedTab === 'payments'
                                    ? 'border-yellow-500 rounded-t-lg text-black'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                            }`}
                        >
                            Payments
                        </p>
                    </li>
                    {/* Comented code will be used in future */}
                    {/* payout system(code) is still there , we just comment this tab */}
                    {/* <li className="me-2">
                        <p
                            onClick={() => handleTabClick('payouts')}
                            className={`inline-block p-4 border-b-2 ${
                                selectedTab === 'payouts'
                                    ? 'border-yellow-500 rounded-t-lg text-black'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                            }`}
                        >
                            Payouts
                        </p>
                    </li> */}
                </ul>
            </div>

            <div className="rounded-2xl border border-color-green h-auto w-full p-5">
                {selectedTab === 'payments' && <Payments />}
                {selectedTab === 'payouts' && <Payouts setViewPayout={setViewPayout} viewPayout={viewPayout} />}
            </div>
        </div>
    );
};
