import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

function Sidebar() {

  const style = ({ isActive }) => {
    return {
      color: isActive ? "#0066b2" : "black",
    };
  };

  const [collapseShow, setCollapseShow] = React.useState("hidden");

  const dispatch = useDispatch();
  return (
    <>

      <nav className="relative z-10 flex flex-wrap items-center justify-between px-6 py-4 bg-white shadow md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden md:w-64">
        <div className="flex flex-wrap items-center justify-between w-full px-0 mx-auto md:flex-col md:items-stretch md:min-h-full md:flex-nowrap">
          <button
            className="px-3 py-1 text-xl leading-none text-black bg-transparent border border-transparent border-solid rounded opacity-50 cursor-pointer md:hidden"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          <Link
            className="inline-block p-4 px-0 mr-0 text-sm font-bold text-left uppercase md:block md:pb-2 text-blueGray-600 whitespace-nowrap"
            to="/"
          >
            BnByond
          </Link>
          <ul className="flex flex-wrap items-center list-none md:hidden">
          </ul>
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            <div className="block pb-4 mb-4 border-b border-solid md:min-w-full md:hidden border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="inline-block p-4 px-0 mr-0 text-sm font-bold text-left uppercase md:block md:pb-2 text-blueGray-600 whitespace-nowrap"
                    to="/"
                  >
                    BESCHDULED
                  </Link>
                </div>
                <div className="flex justify-end w-6/12">
                  <button
                    type="button"
                    className="px-3 py-1 text-xl leading-none text-black bg-transparent border border-transparent border-solid rounded opacity-50 cursor-pointer md:hidden"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <hr className="my-4 md:min-w-full" />
            <h6 className="block pt-1 pb-4 text-xs font-bold no-underline uppercase md:min-w-full text-blueGray-500">
              Admin Layout
            </h6>
            <ul className="flex flex-col list-none md:flex-col md:min-w-full">
              <li className="items-center">
                <NavLink
                  className="block py-3 text-xs font-bold uppercase "
                  style={style}
                  to="/member/propertylisting"
                >
                  <i className="mr-2 text-sm fas fa-map "></i> Property Listing
                </NavLink>
              </li>

              <li className="items-center">
                <NavLink
                  className="block py-3 text-xs font-bold uppercase "
                  style={style}
                  to="/member/viewproperty"
                >
                  <i className="mr-2 text-sm fas fa-location-arrow "></i> View
                  Property
                </NavLink>
              </li>
            </ul>

            <ul className="flex flex-col mt-6 list-none md:flex-col md:min-w-full">
              <li className="items-center">
                <button
                  type="button"
                  className="btn-styl"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
