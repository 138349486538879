import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import RefundStep1 from "components/Refund/RefundStep1";
import RefundStep2 from "components/Refund/RefundStep2";
import RefundStep3 from "components/Refund/RefundStep3";
import { FaPersonBreastfeeding } from "react-icons/fa6";
import { BiSolidCalendar } from "react-icons/bi";
import { useParams } from 'react-router-dom';
import userServices from "services/httpService/userAuth/userServices";
import moment from "moment";

const Refund = () => {
  const params = useParams();
  const [step, setStep] = useState("2");
  const [reservationDetails, setReservationDetails] = useState('');
  const [refundReason, setRefundReason] = useState("");

  const getReservation = async () => {
    try {
      const apiUrl = `/reservation/reservation/${params.id}`;
      const res = await userServices.getReservationByuserId(apiUrl);
      setReservationDetails(res.data);
    } catch (error) {
      console.error("Error fetching reservation data", error);
    }
  };

  useEffect(() => {
    getReservation();
  }, []);

  const formattedCheckIn = reservationDetails?.offerState
    ? moment(reservationDetails?.offerState?.checkIn).format('ddd, MMM D -YY')
    : '';
  const formattedCheckOut = reservationDetails?.offerState
    ? moment(reservationDetails?.offerState?.checkOut).format('ddd, MMM D -YY')
    : '';

    const calculateRefund = () => {
      if (!reservationDetails.offerState) return { refundPoints: 0, refundServiceFee: 0, reducedPoints: 0 };
    
      const now = moment();
      const checkIn = moment(reservationDetails.offerState.checkIn);
      const daysBeforeCheckIn = checkIn.diff(now, 'days');
      const points = reservationDetails.offerState.points;
      const serviceFee = reservationDetails.offerState.serviceFee;
    
      let refundPoints, refundServiceFee, reducedPoints;
    
      // Guest cancellation
      if (!reservationDetails.hostCanceled) {
        if (daysBeforeCheckIn >= 60) {
          refundPoints = points;
          refundServiceFee = serviceFee;
          reducedPoints = 0;
        } else if (daysBeforeCheckIn >= 15) {
          refundPoints = points * 0.5;
          refundServiceFee = serviceFee * 0.5;
          reducedPoints = points * 0.5;
        } else {
          refundPoints = 0;
          refundServiceFee = 0;
          reducedPoints = points;
        }
      } 
      // Host cancellation
      else {
        const penalty = 50;
        if (daysBeforeCheckIn < 10) {
          refundPoints = points * 0.5;
          reducedPoints = points * 0.5;
        } else if (daysBeforeCheckIn < 30) {
          refundPoints = points * 0.75;
          reducedPoints = points * 0.25;
        } else {
          refundPoints = points * 0.9;
          reducedPoints = points * 0.1;
        }
        refundServiceFee = serviceFee;
      }
    
      return {
        refundPoints: Math.floor(refundPoints),
        refundServiceFee: Math.floor(refundServiceFee),
        reducedPoints: Math.floor(reducedPoints)
      };
    };

  const { refundPoints, refundServiceFee, reducedPoints } = calculateRefund();

  return (
    <>
      <Container>
        <div className="py-10">
          <span className="font-medium text-[36px]">Refund</span>
        </div>
        <div className="md:flex gap-10 ">
          <div className="w-[50%]">
            {step === "1" && <RefundStep1 step={step} setStep={setStep} />}
            {step === "2" && <RefundStep2 step={step} setStep={setStep} setRefundReason={setRefundReason} refundReason={refundReason} />}
            {step === "3" && <RefundStep3 step={step} setStep={setStep} reservationDetails={reservationDetails} refundReason={refundReason}  refundPoints={refundPoints} refundServiceFee={refundServiceFee} reducedPoints={reducedPoints}/>}
          </div>

          <div className="md:w-[50%] h-fit p-5 border rounded-md gap-2 mt-3">
            <div className="md:flex gap-2 items-center">
              <img src={reservationDetails?.property_id?.pics[0]} alt="" className="w-[80px] h-[80px] rounded-lg" />
              <div className="flex flex-col">
                <p className="font-bold">{reservationDetails?.property_id?.title}</p>
                <p>{reservationDetails?.property_id?.spaceType}</p>
              </div>
            </div>
            <hr className="mt-2 "></hr>
            <div className="py-3">
              <div className="flex items-center  gap-2">
                <FaPersonBreastfeeding className="text-2xl text-color-darkgrey/80" />
                <p className="text-md text-color-darkgrey/80">
                  {reservationDetails?.property_id?.spaceTypeDetail?.guests} Guest
                </p>
              </div>
              <div className="flex items-center pt-3 gap-2">
                <BiSolidCalendar className="text-2xl text-color-darkgrey/80" />
                <p className="text-md text-color-darkgrey/80">
                  {formattedCheckIn} ➡ {formattedCheckOut}
                </p>
              </div>
            </div>
            <hr className="mt-2" />
            <div className="flex justify-between items-center pt-2 my-2">
              <p className="text-color-darkgrey/80">Total Points</p>
              <p className="text-color-darkgrey/80">{reservationDetails?.offerState?.points}</p>
            </div>
            <div className="flex justify-between items-center pt-2 my-2">
              <p className="text-color-darkgrey/80">Service Fee</p>
              <p className="text-color-darkgrey/80">{reservationDetails?.offerState?.serviceFee}</p>
            </div>
            <div className="flex justify-between items-center pt-2 my-2">
              <p className="text-color-darkgrey/80">reduced points</p>
              <p className="text-color-darkgrey/80">{reducedPoints}</p>
            </div>
            <hr className="mt-2" />
            <div className="flex justify-between items-center pt-2 my-2">
              <p className="font-bold">Refund Points</p>
              <p className="font-bold">{refundPoints}</p>
            </div>
            <div className="flex justify-between items-center pt-2 my-2">
              <p className="font-bold">Refund Service Fee</p>
              <p className="font-bold">{refundServiceFee}</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Refund;