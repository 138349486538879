import {
  UPDATE_FORM_DATA,
  NEXT_STEP,
  PREVIOUS_STEP,
  SET_INITIAL_FORM_DATA,
} from "../actions/userAction/formAction";

const initialState = {
  activeStep: 0,
  formData: {
    propertyType: "",
    spaceType: "",
    spaceTypeDetail: { guests: 0, bedrooms: 0, bathrooms: 0 },
    address: "",
    amenities: [],
    photos: [],
    title: "",
    description: "",
    points: [],
    characteristics: [],
    price: 0,
    cleaningFee: 0,
    discountPrice: 0,
    amenitiesFee: 0,
    isPropertyAvaibleOnOtherSites: [],
  },
};

export default function formReducer(state = initialState, action) {
  
  switch (action.type) {
    case UPDATE_FORM_DATA:
      // Handle nested updates for spaceTypeDetail
      if (action.payload.spaceTypeDetail) {
        return {
          ...state,
          formData: {
            ...state.formData,
            spaceTypeDetail: {
              ...state.formData.spaceTypeDetail,
              ...action.payload.spaceTypeDetail,
            },
          },
        };
      }
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };

    case SET_INITIAL_FORM_DATA:
      return {
        ...state,
        formData: { ...state.formData, ...action.payload },
      };
    case NEXT_STEP:
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };

    case PREVIOUS_STEP:
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };

      case "RESET_FORM":
        return initialState;

    default:
      return state;
  }
}