// actions/formActions.js
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";
export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const SET_INITIAL_FORM_DATA = "SET_INITIAL_FORM_DATA";

export const updateFormData = (data) => 
   ({
  
  type: UPDATE_FORM_DATA,
  payload: data,
});

export const nextStep = () => ({
  type: NEXT_STEP,
});

export const previousStep = () => ({
  type: PREVIOUS_STEP,
});

export const setInitialFormData = (data) => {
  return {
    type: SET_INITIAL_FORM_DATA,
    payload: data,
  };
};

export const resetForm = () => {
  return {
    type: "RESET_FORM",
  };
}

