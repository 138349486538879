import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import UserCard from "./UserCard";

const UserManagement = ({ setSteps, allUser, setStepsAndDetail }) => {
  const [active, setActive] = useState(1);
  const [AllGuest, setAllGuest] = useState([]);
  const [Guest, setGuest] = useState([]);
  const [Host, setHost] = useState([]);
  const [Influencers, setInfluencers] = useState([]);

  const handleActive = (num) => {
    setActive(num);
  };

  const handleGuestHost = () => {
    const guestList = allUser.filter(user => {
      return !user.roles.includes("Influencer");
    });
    setGuest(guestList);
  };

  const handleHost = () => {
    const hostList = allUser.filter(user => {
       return !user.roles.includes("Influencer");
    });
    setHost(hostList);
  };

  const handleInfluencer = () => {
    const Influencer = allUser.filter(user => {
      console.log("Influencers", user.roles.includes("Influencer"));
      return user.roles.includes("Influencer");
    });
    setInfluencers(Influencer)
  };

  useEffect(() => {
    handleGuestHost()
    handleInfluencer()
    handleHost()
  }, [active]);

   return (
    <>
      <section className="bg-[#f8f7fa]">
        <Container maxWidth="xl">
          <div className="my-4 flex md:flex-row flex-col gap-4 md:gap-0 justify-between pt-7">
            <div className="">
              <h1 className="text-xl font-bold ml-4">User Management</h1>
            </div>
          </div>
          <div className="">
            <div className="flex items-center border-b border-b-color-grey relative ">
              <p
                onClick={() => handleActive(1)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mr-2 relative -bottom-[0.5px] ${active == 1
                  ? "border-b-color-yellow text-color-black"
                  : "border-b-transparent"
                  } `}
              >
                All
              </p>
              <p
                onClick={() => handleActive(2)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 2
                  ? "border-b-color-yellow text-color-black"
                  : "border-b-transparent"
                  } `}
              >
                Guest/Host
              </p>
              <p
                onClick={() => handleActive(3)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 3
                  ? " border-b-color-yellow text-color-black"
                  : "border-b-transparent"
                  }`}
              >
                Influencers
              </p>
            </div>
            <UserCard
              active={active}
              All={AllGuest}
              Guest={Guest}
              allUser={allUser}
              Host={Host}
              Influencers={Influencers}
              setStepsAndDetail={setStepsAndDetail}
            />
          </div>
        </Container>
      </section>
    </>
  );
};

export default UserManagement;
