import { localStorageData } from "services/auth/localStorageData";
import Rating from "react-rating";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";

export const PropertyCard = ({ allReservation, handleWishlist, card }) => {

  return (
    <div className="space-y-3">
      <p className="text-2xl font-medium">Your listings</p>
      {card?.length > 0 ? (
        card?.map((item, index) => {
          const favourite =
            item?.favouriteList &&
            item.favouriteList.find(
              (user) => user.userId === localStorageData("_id")
            );

          const ratings = item.reviews.map((review) => review.rating);
          const total = ratings.reduce((acc, rating) => acc + rating, 0);
          const averageRating = ratings.length > 0 ? Number((total / ratings.length).toFixed(1)) : 0;

          const formattedDate = new Date(item.timeStamp).toLocaleDateString();
         
          const minPoint = Array.isArray(item.points) && item.points.length > 0
          ? item.points.reduce((min, p) => (p.point < min ? p.point : min), item.points[0].point)
          : null;
          
          return (
            <>
              <div
                key={index}
                className="flex flex-col justify-between w-full md:flex-row"
              >
                <div className=" w-full md:w-[60%] flex flex-col justify-between border order-last md:order-first border-color-green md:border-r-0 md:rounded-tl-lg md:rounded-bl-lg md:rounded-tr-none p-4">
                  <div className="">
                    <div className="flex flex-row md:flex-col lg:flex-row  items-center justify-between ">
                      {averageRating > 0 ?
                        <div className="flex  items-center justify-start ">
                          <Rating
                            initialRating={averageRating}
                            readonly
                            emptySymbol={<FaRegStar color="#d3d3d3" />}
                            halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                            fullSymbol={<FaStar color="#ffd700" />}
                            fractions={2}
                          />
                          <span>({averageRating})</span>
                        </div>
                        :
                        <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                      }
                      <div className="">
                        <button
                          className={`fa-regular ${favourite && "text-color-red fa-solid"
                            } fa-heart w-[17.14px] h-[15.7px]`}
                          onClick={() => handleWishlist(item._id)}
                        ></button>
                      </div>
                    </div>
                    <p className="text-lg font-medium ">{item.address}</p>
                  </div>

                  <div className="">
                    <hr className="mt-4 mb-2 border-t border-[#AFB5C1]" />
                    <div className="flex justify-between">
                      <p className="text-[#AFB5C1] md:pr-3 lg:pr-0">From</p>
                      <div className="flex  gap-4">
                        <p className="text-[#AFB5C1] !line-through italic">
                          {minPoint}
                        </p>
                        <p className="text-[#C0392D] font-medium">
                          {" "}
                          {minPoint} Points
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full h-40 md:h-60 lg:h-52 md:w-[40%] overflow-hidden rounded-lg rounded-br-none md:rounded-br-lg rounded-bl-none md:rounded-tl-none md:rounded-bl-none md:rounded-tr-lg  order-first md:order-last">
                  <img
                    src={item.pics[0]}
                    alt=""
                    className="object-cover w-full h-full flex-shrink-0"
                  />
                </div>
              </div>
              <p className="text-[#4B4B4B]">
                Publish date {formattedDate} . {item.reviews.length} Reviews . {averageRating} Ratings
              </p>
            </>
          );
        })
      ) : (
        <h1 className="text-xl font-medium">Card not found</h1>
      )}
    </div>
  );
};
