import React from 'react'
import { Container } from "@mui/material";
import bay from "../../../assets/img/bay.png";
import { Rating } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import TopCard from '../UserManagement/TopCard';
import Dropdown from '../UserManagement/Dropdown';

const PropertyManagement = () => {
  return (
    <>
      <section className="bg-[#f8f7fa]">
        <Container maxWidth="xl">
          <div className="my-4 flex justify-between pt-7">
            <div className="">
              <h1 className="text-xl font-medium ml-4">User Management</h1>
            </div>
            <div className="flex gap-2">
              <Dropdown />
              <button className="bg-[#2459BF] w-130 rounded-full w-[120px] h-[40] text-color-cream" >+ ADD NEW</button>
            </div>
          </div>
          <TopCard />
          <div className="flex flex-wrap justify-between mt-6 p-5 rounded-tl-[15px] rounded-tr-[15px] border border-gray-300 items-center bg-white">
            <div>
              <h2 className="font-medium text-lg leading-7 text-black">Properties</h2>
            </div>
            <div className="flex gap-3 items-center ">
              <div className="md:w-[100px] relative gap-2 flex items-center w-full md:h-[30px] h-[40px] border border-[#AFB5C1] rounded-md">
                <span className="ml-2">
                  <img src={require("assets/img/filter_tag.png")} className="text-[#AFB5C1] text-base" />
                </span>
                <button className="text-[#AFB5C1] text-xs font-normal ">FILTERS</button>
              </div>
              <div>
                <button className="rounded-md border border-gray-300 px-4 py-2 w-[100px] h-[40px] text-[#4B4B4B] text-sm font-normal">Export</button>
              </div>
              <div>
                <input
                  className="placeholder-gray-700::placeholder font-normal text-sm leading-5 px-3 w-[298px] h-[40px] py-2 rounded-md border border-gray-300"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
          <table className="table-auto border-collapse w-full bg-white">
            <thead>
              <tr className='bg-[#fafbff]'>
                <th className="px-4 py-2 border-t border-b text-left">
                  <input type="checkbox" className="mr-2" />
                  User
                </th>
                <th className="px-4 py-2 border-t text-left border-b">Location</th>
                <th className="px-4 py-2 border-t text-left border-b">Room</th>
                <th className="px-4 py-2 border-t text-left border-b">price</th>
                <th className="px-4 py-2 border-t text-left border-b">Host</th>
                <th className="px-4 py-2 border-t  text-left border-b">Listing Status</th>
                <th className="px-4 py-2 border-t text-left border-b"></th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 border-t border-b flex flex-col items-start">
                    <div className="flex items-center">
                      <input type="checkbox" className="mr-2" />
                      <img src={bay} className="w-24 h-20 rounded-md mr-2" />
                      <div className='flex flex-col'>
                        <span className="font-medium text-xs leading-4 text-gray-400">#1234</span>
                        <span className="font-medium text-sm leading-5 text-black">Mahone Bay, Nova Scotia, Canada{index + 1}</span>
                        <div className="flex items-center">
                          <Rating name="size-small" readOnly defaultValue={5} size="small" />
                          <span className="text-sm ml-2">(23)</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-[#4B4B4B]">
                    39266 Reynolds Junctions, Heathcoteport, North Dakota
                  </td>
                  <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                    <div className="flex flex-col">
                      <span className="text-sm text-[#4B4B4B] ">3 bed</span>
                      <span className="text-sm text-[#4B4B4B] ">3 bath</span>
                      <span className="text-sm text-[#4B4B4B]">1 kit</span>
                    </div>
                  </td>
                  <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                    $244
                  </td>
                  <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                    Nela Scarlett
                  </td>
                  <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                    <div className="flex flex-col gap-3">
                      <div className="w-[56px] h-[30px] bg-green-500 bg-opacity-10 rounded-full flex justify-center items-center">
                        <button className="font-extrabold text-xs leading-4 text-center text-green-500">Active</button>
                      </div>
                      <div>
                        <span className="text-sm text-[#AFB5C1] ">Oct 24th-26th</span>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black"><BsThreeDotsVertical /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </Container>
      </section>

    </>
  )
}

export default PropertyManagement