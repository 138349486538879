import React from "react";

const Modal = ({ isOpen, onClose, children }) => {
  const modalStyle = isOpen ? "block" : "hidden";

  return (
    <div>
      <div
        className={`fixed inset-0 bg-black opacity-50 z-40 ${modalStyle}`}
        onClick={onClose}
      >
      </div>
      <div
        className={`fixed top-1/2 w-[90%] left-1/2 md:w-[500px] md:h-[525px] h-[400px] rounded-[20px] transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-md z-50 ${modalStyle}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
