import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import GoogleMapReact from "google-map-react";
import { useSelector, useDispatch } from "react-redux";
import { updateFormData } from "redux/store/actions/userAction/formAction";
import ListingColor from "components/Cards/ListingColor";
import AutoAddress from "components/AutoAddress";
import useGoogleMapsAPI from "../../../hooks/useGoogleMapsAPI";

// Component to display the map marker
const MyCustomMarker = () => (
  <img
    src="https://i.ibb.co/xqDW27s/Vector.png"
    alt="Vector"
    className="object-contain w-10 h-10"
  />
);

export default function PropertyAddress() {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.form);
  const { google, error } = useGoogleMapsAPI({
    libraries: ["places"],
  });

  // Default properties for the map: center position and zoom level
  const defaultProps = {
    center: {
      lat: 31.7634,
      lng: -82.2673,
    },
    zoom: 6,
  };

  // State for map coordinates
  const [coordinates, setCoordinates] = useState({
    center: {
      lat: formData.lat || defaultProps.center.lat,
      lng: formData.long || defaultProps.center.lng,
    },
    zoom: defaultProps.zoom,
  });

  // Update coordinates when formData changes
  useEffect(() => {
    setCoordinates({
      center: {
        lat: formData.lat || defaultProps.center.lat,
        lng: formData.long || defaultProps.center.lng,
      },
      zoom: defaultProps.zoom,
    });
  }, [formData]);

  // Function to update Redux state with the new address, latitude, and longitude
  const changeAddress = (address, lat, long) => {
    dispatch(updateFormData({ address, lat, long }));
  };

  if (error) {
    return <div>Error loading Google Maps API</div>;
  }

  if (!google) {
    return <div>Loading Google Maps.....</div>;
  }

  return (
    <div>
      <Grid container>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={{ height: "85vh", marginTop: "10vh" }}
        >
          <ListingColor
            bg="bg-color-darknavyblue"
            text="Where Is Your Rental Property Located?"
            color="text-[#FFFFFF]"
            btn="Step 3"
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          style={{
            marginTop: "10vh",
            height: "85vh",
            paddingBottom: "50px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div style={{ position: "relative", height: "80vh", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_MAPKEY,
              }}
              yesIWantToUseGoogleMapApiInternals
              center={coordinates.center}
              defaultZoom={coordinates.zoom}
              onGoogleApiLoaded={({ map, maps }) => {

              }}
            >
              <MyCustomMarker
                lat={coordinates.center.lat}
                lng={coordinates.center.lng}
              />
            </GoogleMapReact>
            <div
              className="absolute top-12 left-1/2 transform w-[80%] -translate-x-1/2 -translate-y-1/2 z-10"
              style={{
                zIndex: 10,
              }}
            >
              <div className="rounded-md flex bg-white">
                <img
                  src={require("assets/img/location.png")}
                  alt=""
                  className="w-12 p-2 h-12"
                />
                <div className="w-full">
                  <AutoAddress
                    className="p-3 text-md rounded-lg w-full outline-none"
                    placeholder="Enter your Address"
                    onChangeAddress={changeAddress}
                    address={formData.address}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}