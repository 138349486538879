import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PiGraph = () => {
  const series = [44, 55, 41, 17];

  const options = {
    chart: {
      width: 380,
      type: 'donut',
      dropShadow: {
        enabled: true,
        color: '#111',
        top: -1,
        left: 3,
        blur: 3,
        opacity: 0.2
      }
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
                label: 'AVG. Exceptions',
              showAlways: true,
              show: true
            },
            
          }
        }
      }
    },
    labels: ["Exceptions 1", "Exceptions 2", "Exceptions 3", "Exceptions 4"],
     colors: ['#F4D999', '#A0522D', '#A27711', '#4A3608'],
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8
      }
    },
    fill: {
      type: 'solid',
      opacity: 1,
      colors: ['#F4D999', '#A0522D', '#A27711', '#4A3608'],
    },
    states: {
      hover: {
        filter: 'none'
      }
    },
    theme: {
      palette: 'palette2'
    },
    legend: {
      position: 'bottom',
      itemMargin: {
      }
    },
  };

  return (
    <div className='flex items-center justify-center'>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="donut" width={380} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default PiGraph;