import React from "react";
import { Route, Routes } from "react-router-dom";
import SidebarAdmin from "components/Sidebar/SidebarAdmin";
import AdminLogin from "pages/admin/AdminLogin";
import AdminProtectedRoute from "./AdminProtectedRoute";
import BlogPost from "pages/Blogs/BlogPost";
import AdminSettings from "pages/admin/AdminSettings/AdminSettings";
import FinancialTransactions from "pages/admin/FinancialTransactions/FinancialTransactions";
import AdminDashboard from "pages/admin/MainDashboard";
import Properties from "pages/admin/PropertyManagement/Properties";
import ReferralPrograms from "pages/admin/ReferralProgram";
import Reservation from "pages/admin/ReservationManagement/Reservation";
import AdminReviews from "pages/admin/Reviews/AdminReviews";
import Permissions from "pages/admin/Roles_Permissions";
import SupportTicketing from "pages/admin/SupportTicketing/SupportTicketing";
import UserManagement2 from "pages/admin/UserManagement/UserManagement2";
import RefundReservation from "pages/admin/RefundReservation/RefundReservation";
import AddUser from "pages/admin/AddNewUser/AddUser";
import AllPost from "pages/Blogs/AllPost";
import StrInnovator from "pages/admin/StrInnovator/StrInnovator";

function Admin() {
  return (
    <>
      <Routes>
        <Route element={<AdminProtectedRoute />}>
          <Route path="/dashboard" element={<SidebarAdmin />}>
            <Route index element={<AdminDashboard />} />
            <Route path="user-management" element={<UserManagement2 />} />
            <Route path="properties" element={<Properties />} />
            <Route path="reservation" element={<Reservation />} />
            <Route path="financial" element={<FinancialTransactions />} />
            <Route path="reviews" element={<AdminReviews />} />
            <Route path="referral-programs" element={<ReferralPrograms />} />
            <Route path="support-ticketing" element={<SupportTicketing />} />
            <Route path="permissions" element={<Permissions />} />
            <Route path="settings" element={<AdminSettings />} />
            <Route path="blog-post" element={<BlogPost />} />
            <Route path="refund-reservation" element={<RefundReservation />} />
            <Route path="all-posts" element={<AllPost />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="str-innovator" element={<StrInnovator />} />
          </Route>
        </Route>
        <Route path="/login" element={<AdminLogin />} />
      </Routes>
    </>
  );
}

export default Admin;