import React, { useEffect, useState } from 'react'
import UserManagement from './userManagement'
import InfluencerDetails from '../InfluencerDetails'
import HostDetail from '../HostDetail/HostDetail'
import userServices from 'services/httpService/userAuth/userServices';

const UserManagement2 = () => {
  const [allUser, setAllUser] = useState([]);
  const [userDetails, setuserDetails] = useState([]);
  const [steps, setSteps] = useState('UserManagement')

  const getUsers = async () => {
    let res = await userServices.commonGetService(
      `/userAuth/allusers`
    );
    setAllUser(res.data);
  };

  const handleSetStepsAndDetail = (_id) => {
    const detail = allUser.find(allUser => allUser._id === _id);
      setuserDetails(detail);
      setSteps('GuestHost')
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      {steps === "UserManagement" &&
        <UserManagement setStepsAndDetail={handleSetStepsAndDetail} allUser={allUser} />
      }
      {steps === "GuestHost" &&
        <HostDetail setSteps={setSteps} allUser={allUser} userDetails={userDetails} />
      }
    </>
  )
}

export default UserManagement2