import React, { useEffect, useState } from "react";
import img1 from "../../assets/img/luxury-home-with-swimming-pool-2021-08-26-17-04-57-utc 5.png";
import { useNavigate } from "react-router-dom";
import userServices from "services/httpService/userAuth/userServices";
import { CircularProgress } from "@mui/material";
import Footerbn from "pages/Footer/Footerbn";
import BnbNav from "components/NavBar/BnbNav";
import Navbar from "components/Navbars/AuthNavbar";

export const Blogs = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch blogs from server
  const getBlogs = async () => {
    try {
      const res = await userServices.getBlogPost("post/blogs");
      setBlogs(res.data.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  // Run getBlogs on component mount
  useEffect(() => {
    getBlogs();
  }, []);

  // Splitting blogs into sections
  const upperSectionBlogs = blogs.slice(0, 3); // First 3 blogs
  const mapSectionBlogs = blogs.slice(3); // Remaining blogs

  // Helper function to format date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full h-screen">
          <CircularProgress />
        </div>
      ) : (
        <>
          <BnbNav />
          <Navbar />
          <div className="relative">
            <img className="w-full h-24" src={img1} alt="Blog Banner" />
            <div className="absolute text-white top-5 left-12 sm:left-20">
              <p className="text-2xl font-semibold">Latest Blogs & News</p>
              <p className="text-sm font-normal">Travel-related topics</p>
            </div>
          </div>

          <div className="px-6 py-8 space-y-10 sm:px-20 lg:px-30 md:px-10">
            {/* Upper Section: Featured Blogs */}
            <div className="flex flex-col gap-8 md:flex-row">
              <div
                onClick={() => navigate(`/blog/${upperSectionBlogs[0]?._id}`)}
                className="cursor-pointer md:w-[60%]"
              >
                <img
                  className="md:w-full lg:h-[550px] md:h-[460px] rounded-[15px] w-[900px] object-cover"
                  src={upperSectionBlogs[0]?.pic}
                  alt=""
                />
                <h1
                  className="py-3 font-bold text-2xl"
                  dangerouslySetInnerHTML={{
                    __html:
                      upperSectionBlogs[0]?.title?.length > 50
                        ? `${upperSectionBlogs[0]?.title.substring(0, 50)}...`
                        : upperSectionBlogs[0]?.title,
                  }}
                />
                <p className="font-medium underline cursor-pointer">Read More</p>
              </div>
              <span className="space-y-8 md:w-[40%]">
                {upperSectionBlogs.slice(1).map((blog) => (
                  <div
                    key={blog._id}
                    onClick={() => navigate(`/blog/${blog._id}`)}
                    className="cursor-pointer"
                  >
                    <img
                      className="lg:w-full lg:h-[220px] md:h-auto rounded-[15px] w-[900px] object-cover"
                      src={blog.pic}
                      alt=""
                    />
                    <p
                      className="py-3 font-bold lg:text-2xl text-xl"
                      dangerouslySetInnerHTML={{
                        __html:
                          blog.title?.length > 35
                            ? `${blog.title.substring(0, 35)}...`
                            : blog.title,
                      }}
                    />
                    <p className="font-medium underline cursor-pointer lg:px-3">
                      Read More
                    </p>
                  </div>
                ))}
              </span>
            </div>

            {/* Lower Section: Remaining Blogs */}
            <div className="grid grid-cols-1 gap-8 space-y-6 lg:grid-cols-3 lg:space-y-0">
              {mapSectionBlogs.length > 0 &&
                mapSectionBlogs.map((blog) => (
                  <div
                    key={blog?._id}
                    className="flex flex-col space-y-3 cursor-pointer"
                    onClick={() => navigate(`/blog/${blog._id}`)}
                  >
                    <img
                      className="rounded h-[240px] object-cover"
                      src={blog.pic}
                      alt={blog.title}
                    />
                    <p className="font-normal text-md text-gray-500">
                      {formatDate(blog.timeStamp)} | {blog?.comments?.length} Comments
                    </p>
                    <p className="text-2xl font-semibold">
                      {blog?.title?.length > 30
                        ? blog?.title.substring(0, 30) + "..."
                        : blog?.title}
                    </p>
                    <p
                      className="font-normal text-md"
                      dangerouslySetInnerHTML={{
                        __html:
                          blog?.description.length > 70
                            ? `${blog?.description.substring(0, 70)}...`
                            : blog?.description,
                      }}
                    />
                    <p className="font-medium underline cursor-pointer">Read More</p>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
      <Footerbn />
    </>
  );
};
