import { useEffect, useState } from "react";

const useGoogleMapsAPI = ({ libraries = [] }) => {
  const [google, setGoogle] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {

    if (window.google) {
      setGoogle(window.google);
      return;
    }

    const existingScript = document.querySelector(`script[src*="maps.googleapis.com"]`);
    if (existingScript) {
      return;
    }

    const apiKey = process.env.REACT_APP_MAPKEY;
    if (!apiKey) {
      setError(new Error("Google Maps API key not found in environment variables"));
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}${
      libraries.length ? `&libraries=${libraries.join(",")}` : ""
    }`;
    script.async = true;
    script.defer = true;

    const handleScriptLoad = () => {
      if (window.google) {
        setGoogle(window.google);
      } else {
        setError(new Error("Google Maps not available"));
      }
    };

    script.addEventListener("load", handleScriptLoad);
    script.addEventListener("error", () => 
      setError(new Error("Failed to load Google Maps"))
    );

    document.head.appendChild(script);

    return () => {
      script.removeEventListener("load", handleScriptLoad);
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, [libraries]);

  return { google, error };
};

export default useGoogleMapsAPI;