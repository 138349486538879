import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

function AutoAddress({ className, placeholder, onChangeAddress, address, label, ...rest }) {
  const inputRef = useRef(null);
  const { state } = useLocation();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [initialValueSet, setInitialValueSet] = useState(false);
  
  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const apiKey = process.env.REACT_APP_MAPKEY;
      if (!apiKey) {
        console.error("Google Maps API key not found in environment variables");
        return;
      }

      if (window.google?.maps) {
        setIsScriptLoaded(true);
        return;
      }

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;

      script.addEventListener('load', () => {
        setIsScriptLoaded(true);
      });

      script.addEventListener('error', () => {
        console.error('Failed to load Google Maps script');
      });

      document.head.appendChild(script);

      return () => {
        if (document.head.contains(script)) {
          document.head.removeChild(script);
        }
      };
    };

    loadGoogleMapsScript();
  }, []);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
    initOnMount: isScriptLoaded,
    defaultValue: "South Carolina, USA"
  });

  useEffect(() => {
    if (ready && !initialValueSet) {
      if (!address) {
        setValue("South Carolina, USA", false);
        setInitialValueSet(true);
        
        const initializeLocation = async () => {
          try {
            const results = await getGeocode({ address: "South Carolina, USA" });
            const { lat, lng } = await getLatLng(results[0]);
            
            const addressComponents = results[0].address_components;
            let fullState = '';
            let stateAbbreviation = '';
            let fullCountry = '';
            let countryAbbreviation = '';

            addressComponents.forEach(component => {
              if (component.types.includes('administrative_area_level_1')) {
                fullState = component.long_name;
                stateAbbreviation = component.short_name;
              }
              if (component.types.includes('country')) {
                fullCountry = component.long_name;
                countryAbbreviation = component.short_name;
              }
            });
            
            onChangeAddress(
              "South Carolina, USA",
              lat,
              lng,
              "",
              fullState || "South Carolina",
              fullCountry || "United States",
              stateAbbreviation || "SC",
              countryAbbreviation || "USA"
            );
          } catch (error) {
            console.error('Error during initial geocoding:', error);
          }
        };
        
        initializeLocation();
      }
    }
  }, [ready, initialValueSet, setValue, onChangeAddress, address]);

  const handleSelect = ({ description }) => async () => {
    setValue(description, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address: description });
      const { lat, lng } = getLatLng(results[0]);

      const addressComponents = results[0].address_components;
      let city = '';
      let state = '';
      let stateAbbreviation = '';
      let country = '';
      let countryAbbreviation = '';

      addressComponents.forEach(component => {
        const types = component.types;
        if (types.includes('locality')) {
          city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          state = component.long_name;
          stateAbbreviation = component.short_name;
        }
        if (types.includes('country')) {
          country = component.long_name;
          countryAbbreviation = component.short_name;
        }
      });

      onChangeAddress(
        description,
        lat,
        lng,
        city,
        state,
        country,
        stateAbbreviation,
        countryAbbreviation
      );
    } catch (error) {
      console.error('Error during geocoding:', error);
    }
  };

  useEffect(() => {
    if (address) {
      setValue(address, false);
      setInitialValueSet(true);
    }
  }, [address, setValue]);

  useEffect(() => {
    if (state?.fullAddress?.address) {
      setValue(state.fullAddress.address, false);
      clearSuggestions();
    }
  }, [state, setValue, clearSuggestions]);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className='text-center cursor-pointer hover:text-color-primary'
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <strong className='text-lg'>{main_text}</strong> {secondary_text}
        </li>
      );
    });

  if (!isScriptLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
    <>
      <div className='flex items-center bg-white pl-1 rounded-l-md'>
        <input
          ref={inputRef}
          className={className}
          placeholder={placeholder || label}
          value={value}
          onChange={handleInput}
          disabled={!ready}
          {...rest}
        />
      </div>

      <div className='absolute flex w-64 ml-3 center-styl'>
        {status === 'OK' && (
          <ul className='w-full relative z-50 p-2 bg-white shadow-lg'>
            {renderSuggestions()}
          </ul>
        )}
      </div>
    </>
  );
}

export default AutoAddress;