import React, { useEffect, useState } from "react";
import { ProfileDetails } from "./profileDetails";
import { UpdateProfile } from "./updateProfile";
import { GuestReview } from "./review/guestReview";
import { CreateProfile } from "./createProfile";
import Footerbn from "pages/Footer/Footerbn";
import { localStorageData, Logout } from "services/auth/localStorageData";
import userServices from "services/httpService/userAuth/userServices";

export const ProfileV2 = ({ isProfileUpdated }) => {
  const [userData, setUserData] = useState(null);
  const [steps, setSteps] = useState('createProfile')
  const [allReservation, setAllReservation] = useState([])
  const [pastTrips, setPastTrips] = useState([]);

  const getReservation = async () => {
    try {
      const userId = localStorageData("_id");
      const apiUrl = `/reservation/reservations/${userId}`;
      const res = await userServices.getReservationByuserId(apiUrl);
      setAllReservation(res.data);
      const currentDate = new Date().toISOString();
      const historyFilteredData =
        res.data &&
        res.data.filter((item) => {
          if (item.offerState && item.offerState.checkOut) {
            const checkOutDate = new Date(item.offerState.checkOut).toISOString();
            return checkOutDate < currentDate;
          }
          return false;
        });
      setPastTrips(historyFilteredData);
    } catch (error) {
      console.error("Error fetching reservation data", error);
    }
  };

  useEffect(() => {
    const aboutData = ("localStorageData", localStorageData("about"))
    if (aboutData) {
      setUserData(aboutData);
      setSteps("profileDetails");
    }
    getReservation();
  }, []);

  return (
    <>
      {steps === "createProfile" &&
        <CreateProfile userData={userData} setSteps={setSteps} />}
      {steps === "updateProfile" &&
        <UpdateProfile userData={userData} setSteps={setSteps} />}
      {steps === "profileDetails" &&
        <ProfileDetails userData={userData} setSteps={setSteps} pastTrips={pastTrips} />
      }
      {steps === "review" &&
        <GuestReview userData={userData} setSteps={setSteps} pastTrips={pastTrips} />
      }
      <Footerbn />
    </>
  );
};
