import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Rating from 'react-rating';
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";

export default function SearchCard({ data, isHighlighted }) {
  let parsedData;

  if (typeof data?.spaceTypeDetail === "string") {
    try {
      parsedData = JSON.parse(data.spaceTypeDetail);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  } else {
    parsedData = data?.spaceTypeDetail;
  }

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/propertydetails/${data._id}`);
  };

  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    if (data?.reviews && data.reviews.length > 0) {
      const ratings = data.reviews.map((review) => review.rating);
      const total = ratings.reduce((acc, rating) => acc + rating, 0);
      const average = total / ratings.length;
      setAverageRating(Number(average.toFixed(1)));
    } else {
      setAverageRating(0);
    }
  }, [data?.reviews]);

  const minPoint =
    Array.isArray(data.points) && data.points.length > 0
      ? data.points.reduce((min, p) => (p.point < min ? p.point : min), data.points[0].point)
      : null;

  return (
    <div
      className={`flex w-full my-3 relative cursor-pointer transition-all duration-300 ${
        isHighlighted ? "bg-neutral-200" : "bg-white"
      }`}
      key={data._id}
      onClick={handleClick}
    >
      <div>
        <img src={data.pics[0]} className="w-64 h-40 object-cover rounded" alt="review card" />
      </div>
      <div className="mx-3 relative">
        {averageRating > 0 ? (
          <div className="flex items-center">
            <Rating
              initialRating={averageRating}
              readonly
              emptySymbol={<FaRegStar color="#d3d3d3" />}
              halfSymbol={<FaStarHalfAlt color="#ffd700" />}
              fullSymbol={<FaStar color="#ffd700" />}
              fractions={2}
            />
            <span>({averageRating})</span>
          </div>
        ) : (
          <span className="text-[#AFB5C1]">Not Rated Yet.</span>
        )}

        <h2 className="text-lg font-semibold hover:text-blue-700">{data.title}</h2>
        <div className=" text-sm font-semibold">{data.address}</div>
        <div className=" text-sm text-gray-400 ">
          bath: {parsedData && parsedData.bathrooms} bedrooms: {parsedData && parsedData.bedrooms} guests:{" "}
          {parsedData && parsedData.guests}{" "}
        </div>

        <div className=" md:my-2 text-lg text-rose-800 flex justify-between gap-2 items-center absolute bottom-0 left-0 font-semibold">
          <p className="flex justify-start">From</p>
          <p className="flex justify-end">{minPoint} Points/Night</p>
        </div>
      </div>
    </div>
  );
}

