import React from "react";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const pricingData = [
  {
    title: "Maintaining Community Integrity",
    content: `
      BnByond is designed to foster a sense of community, trust, 
      and shared value among STR property owners. The community is built on the idea that 
      members will treat each other’s properties with care and respect. Guests or Hosts making or 
      accepting speculative bookings and not honoring their commitments would unfairly affect the 
      community's flow.
    `,
  },
  {
    title: "Protecting Hosts",
    content: `
      Hosts on BnByond make their properties available with the understanding 
      that they will receive points for a stay. If guests could cancel without consequence, hosts would 
      lose out on the opportunity to earn points and potentially have their properties remain unbooked, 
      which is counter to the entire purpose of the platform. 

      The cancellation policy helps to ensure Members acting as both hosts and guests are 
      compensated fairly for the time and opportunity cost associated with making their property 
      available or travel arrangements around a booked stay.
    `,
  },
  {
    title: "Ensuring Fair Point Transactions",
    content: `
      BnByond's points-based system is designed to be a fair 
      exchange where points are used as currency to facilitate stays at other member properties. 
      When a member makes a booking, the points are held in reserve until the stay is complete. 

      When a guest cancels at the last minute, it causes disruption to the balance of the points 
      system. The cancellation fee helps to mitigate that disruption and maintain a level playing field.
    `,
  },
  {
    title: "Level Playing Field",
    content: `
      BnByond members are required to list their properties at point rates that 
      are consistent with their advertised rates on other platforms to ensure a level playing field and 
      avoid price gouging. 

      Cancellation policies work to support that concept so that hosts can offer stays 
      through BnByond with confidence. Similarly, the cancellation policies provide assurance that 
      their booked stay will be honored.
    `,
  },
  {
    title: "Below is BnByond’s cancellation policy when the GUEST cancels",
    content: `
      - <strong>100% point refund</strong> and <strong>100% service fee refund</strong> 60 days before check-in.<br>
      - <strong>50% point refund</strong> and <strong>50% service fee refund</strong> 15-60 days before check-in.<br>
      - <strong>No point refund</strong> and <strong>no service fee refund</strong> when cancellation occurs less than 15 days 
        prior to the first night of the booked stay.
    `,
  },
  {
    title: "Below is BnByond’s cancellation policy when the HOST cancels",
    content: `
      - <strong>When canceling within 10 days of stay</strong>: $50 (US) cancellation fee applies. 
        A penalty of 50% of the point value of the stay will be applied, with those points being transferred 
        from Host to Guest.<br>
      - <strong>When canceling within 10-30 days of stay</strong>: $50 (US) cancellation fee applies. 
        A penalty of 25% of the point value of the stay will be applied, with those points being transferred 
        from Host to Guest.<br>
      - <strong>When canceling more than 30 days in advance of stay</strong>: $50 (US) cancellation fee applies. 
        A penalty of 10% of the value of the stay will be applied, with those points being transferred from Host to Guest.
    `,
  },
  {
    title: "Multiple cancellations may result in account suspension",
    content: `
      In the event of an act of God, natural disaster, or other circumstances where it is impossible for 
      the Host to honor the stay, exceptions will be considered.
    `,
  },
];



const Pricing = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full p-5">
        <div className="md:flex md:p-5 p-2 ">
          <div className="md:w-[65%] ">
            <h1 className="text-[30px] font-semibold mb-3">BnByond Cancellation Policy - 2025</h1>
            <p className=" mb-5 text-[#4B4B4B]">BnByond applies restrictions and cancellation fees to ensure the smooth operation of its unique
              exchange community and to protect the interests of both hosts and guests.</p>
            <p className=" mb-5 text-[#4B4B4B]">Unlike traditional booking platforms, BnByond operates on a points-based system within an
              exclusive community of short-term rental (STR) property owners. This system necessitates a
              structured approach to cancellations to maintain fairness and prevent abuse.
            </p>
            <p className=" mb-5 text-[#4B4B4B]">Here’s why restrictions and cancellation fees are essential for BnByond:</p>

            {pricingData.map((item, index) => (
              <div key={index}>
                <p className="font-bold text-[#4B4B4B]">
                  {index + 1}. {item.title}:
                </p>
                <p className="flex">
                  <GoDotFill className="text-xl mt-2 mr-2" />
                  <span
                    className="text-justify my-2 text-[#4B4B4B]"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></span>
                </p>
              </div>
            ))}

          </div>
          <div className="md:w-[35%] mt-10 md:p-5 p-2 ">
            <div className="border p-3 rounded-xl">
              <p className="font-semibold text-[18px] ">Need to get in touch?</p>
              <p className="text-[16px] text-[#4B4B4B] py-2">
                We’ll start with some questions and get you to the right place.
              </p>
              <button onClick={() => navigate("/contactus",)}
                className="text-yellow-500 rounded-3xl border p-2  my-2">
                CONTACT US
              </button>
              <p className="py-2">
                You can also{" "}
                <strong className="underline">give us feedback.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
