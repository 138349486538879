import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import userServices from "services/httpService/userAuth/userServices";
import { FaCloudUploadAlt } from "react-icons/fa";

const roles = [
  { title: "Financial", value: "Financial" },
  { title: "Blogger", value: "Blogger" },
  { title: "Sub Admin", value: "subadmin" },
];

const UserForm = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [showRoles, setShowRoles] = useState(false);
  const fileInputRef = React.createRef();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowRoles(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const validationSchema = Yup.object({
    emplname: Yup.string().required("Username is required"),
    jobTitle: Yup.string().required("Job Title is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    gender: Yup.string().required("Gender is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    pic: Yup.string().required("Profile picture is required"),
    role: Yup.array().min(1, "At least one role must be selected"),
  });

  const formik = useFormik({
    initialValues: {
      emplname: "",
      jobTitle: "",
      email: "",
      phone: "",
      gender: "",
      password: "",
      pic: null,
      role: [],
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const bnbyondData = JSON.parse(localStorage.getItem("Bnbyond"));
      const userId = bnbyondData?.data?._id;
      const formData = new FormData();
      formData.append("emplname", values.emplname);
      formData.append("jobTitle", values.jobTitle);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("gender", values.gender);
      formData.append("password", values.password);
      formData.append("role", JSON.stringify(values.role));
      if (values.pic) {
        formData.append("pic", values.pic);
      }
      formData.append("registeredByAdmin", userId);

      try {
        await userServices.createEmployee(formData);
        toast.success("Employee created successfully!");
        resetForm();
        setImagePreview(null);
      } catch (error) {
        toast.error("Error creating employee.");
        console.error("Error:", error);
      }
    },
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        formik.setFieldValue("pic", file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-4xl"
      >
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
          Create Employee
        </h2>
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Username
            </label>
            <input
              type="text"
              name="emplname"
              value={formik.values.emplname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your username"
            />
            {formik.touched.emplname && formik.errors.emplname && (
              <p className="text-red-500 text-sm">{formik.errors.emplname}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Job Title
            </label>
            <input
              type="text"
              name="jobTitle"
              value={formik.values.jobTitle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter job title"
            />
            {formik.touched.jobTitle && formik.errors.jobTitle && (
              <p className="text-red-500 text-sm">{formik.errors.jobTitle}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your email"
            />
            {formik.touched.email && formik.errors.email && (
              <p className="text-red-500 text-sm">{formik.errors.email}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <PhoneInput
              country={"us"}
              value={formik.values.phone}
              onChange={(phone) => formik.setFieldValue("phone", phone)}
              inputStyle={{
                width: "100%",
                height: "2.5rem",
                fontSize: "0.875rem",
                borderRadius: "0.375rem",
                paddingLeft: "3rem",
              }}
              containerStyle={{ width: "100%" }}
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className="text-red-500 text-sm">{formik.errors.phone}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Gender
            </label>
            <select
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            {formik.touched.gender && formik.errors.gender && (
              <p className="text-red-500 text-sm">{formik.errors.gender}</p>
            )}
          </div>

          <div className="flex flex-col gap-0">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Roles
            </label>
            <div
              className={`w-full p-2 border border-gray-300 rounded-md cursor-pointer bg-white ${formik.values.role.length === 0 ? "text-gray-400" : "text-gray-900"
                }`}
              onClick={() => setShowRoles((prev) => !prev)}
            >
              {formik.values.role.length > 0
                ? formik.values.role.join(", ")
                : "Select Roles"}
            </div>
            {showRoles && (
              <div
                ref={dropdownRef}
                className="absolute z-10 mt-1 w-auto pr-32 bg-white border border-gray-300 rounded-md shadow-lg"
              >
                {roles.map((role) => (
                  <div
                    key={role.value}
                    className={`p-2 hover:bg-gray-100 ${formik.values.role.includes(role.value) ? "bg-blue-50" : ""
                      }`}
                  >
                    <label className="flex items-center space-x-2 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={formik.values.role.includes(role.value)}
                        onChange={() =>
                          formik.setFieldValue(
                            "role",
                            formik.values.role.includes(role.value)
                              ? formik.values.role.filter((r) => r !== role.value)
                              : [...formik.values.role, role.value]
                          )
                        }
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                      <span>{role.title}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
            {formik.touched.role && formik.errors.role && (
              <p className="text-red-500 text-sm">{formik.errors.role}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter password"
            />
            {formik.touched.password && formik.errors.password && (
              <p className="text-red-500 text-sm">{formik.errors.password}</p>
            )}
          </div>

          <div className="">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Profile Picture
            </label>

            <div
              className="w-full p-2 rounded-md text-center cursor-pointer border border-gray-500 bg-gray-300"
              onClick={handleClickUpload}
            >
             
                <div className="flex items-center justify-center gap-2">
                  <FaCloudUploadAlt />
                  <p>Click to select an image</p>
                </div>
              
            </div>

            <input
              type="file"
              name="pic"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />

            {formik.touched.pic && formik.errors.pic && (
              <p className="text-red-500 text-sm">{formik.errors.pic}</p>
            )}
          </div>

          <div>
          {imagePreview && (
              <div className="mt-2 flex justify-start">
                <img
                  src={imagePreview}
                  alt="Profile Preview"
                  className="w-24 h-24 object-cover rounded-full"
                />
              </div>
            )}
          </div>

        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Create Employee
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserForm;