// import React, { useState } from 'react';
// import Grid from '@mui/material/Grid';
// import ListingColor from 'components/Cards/ListingColor';
// import MultiDec from 'components/Select/MultiDec';
// export default function AddCharacteristics({ state, setState, characteristics }) {
//   const [data, setData] = useState(characteristics);

//   const onSelect = (selectedCharacteristic) => {

//     // Toggles the selection of a characteristic: removes it if already selected, adds it if not present
//     setState((prevState) => {
//       const existingIndex = prevState.characteristics.findIndex(
//         (char) => char.id === selectedCharacteristic.id
//       );

//       if (existingIndex !== -1) {
//         // Characteristic with the same ID exists, remove it
//         const updatedCharacteristics = [...prevState.characteristics];
//         updatedCharacteristics.splice(existingIndex, 1);

//         return {
//           ...prevState,
//           characteristics: updatedCharacteristics,
//         };
//       } else {
//         // Characteristic with the ID doesn't exist, add it
//         return {
//           ...prevState,
//           characteristics: [...prevState.characteristics, selectedCharacteristic],
//         };
//       }
//     });
//   };


//   return (
//     <div>
//       <Grid container spacing={5}>
//         <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "84vh", marginTop: '10vh' }}>
//           <ListingColor
//             bg='bg-color-darknavyblue'
//             text='Choose Some Descriptive Words'
//             color='text-[#FFFFFF]'
//             btn='step 7'
//           />
//         </Grid>
//         <Grid item lg={7} md={6} sm={12} xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} pb={{ xs: 5, sm: 2, md: 0, lg: 5 }}
//           style={{ marginTop: '10vh', paddingBottom: "17vh", paddingTop: "15vh", height: "84vh", overflowY: 'auto', overflowX: 'hidden' }}
//         >
//           <div className='grid grid-cols-2 gap-2 md:gap-4 px-2 md:grid-cols-2 lg:grid-cols-3 sm:4 md:px-0 xl:gap-10'>
//             {data.map((item) => (
//               <MultiDec
//                 data={item}
//                 withImg={false}
//                 allData={data}
//                 onSelect={onSelect}
//               />
//             ))}
//           </div>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }


import React from "react";
import Grid from "@mui/material/Grid";
import ListingColor from "components/Cards/ListingColor";
import MultiDec from "components/Select/MultiDec";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "redux/store/actions/userAction/formAction";
import { CharacteristicsData } from "data/CharacteristicsData";

export default function AddCharacteristics() {
  const dispatch = useDispatch();

  // Access Redux state for characteristics and form data
  const formData = useSelector((state) => state.form?.formData || {});
  const selectedCharacteristics = formData.characteristics || [];

  // Handler to toggle characteristics selection
  const onSelect = (selectedCharacteristic) => {
    const existingIndex = selectedCharacteristics.findIndex(
      (char) => char.id === selectedCharacteristic.id
    );

    let updatedCharacteristics;

    if (existingIndex !== -1) {
      // Remove characteristic if it already exists
      updatedCharacteristics = [...selectedCharacteristics];
      updatedCharacteristics.splice(existingIndex, 1);
    } else {
      // Add the characteristic if it doesn't exist
      updatedCharacteristics = [...selectedCharacteristics, selectedCharacteristic];
    }

    // Update Redux store with new characteristics
    dispatch(updateFormData({ characteristics: updatedCharacteristics }));
  };


  // const onSelect = (selectedCharacteristic) => {
  //   const existingIndex = selectedCharacteristics.findIndex(
  //     (char) => char.id === selectedCharacteristic.id
  //   );

  //   let updatedCharacteristics;

  //   if (existingIndex !== -1) {
  //     // Remove characteristic and set status to false
  //     updatedCharacteristics = [...selectedCharacteristics];
  //     updatedCharacteristics.splice(existingIndex, 1);
  //     // Update the status in CharacteristicsData
  //     selectedCharacteristic.status = false;
  //   } else {
  //     // Add characteristic and set status to true
  //     updatedCharacteristics = [...selectedCharacteristics, { ...selectedCharacteristic, status: true }];
  //     selectedCharacteristic.status = true;
  //   }

  //   // Update Redux store with new characteristics
  //   dispatch(updateFormData({ characteristics: updatedCharacteristics }));
  // };


  return (
    <div>
      <Grid container spacing={5}>
        <Grid
          item
          lg={5}
          md={6}
          sm={12}
          xs={12}
          style={{ height: "84vh", marginTop: "10vh" }}
        >
          <ListingColor
            bg="bg-color-darknavyblue"
            text="Choose Some Descriptive Words"
            color="text-[#FFFFFF]"
            btn="Step 7"
          />
        </Grid>
        <Grid
          item
          lg={7}
          md={6}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          pb={{ xs: 5, sm: 2, md: 0, lg: 5 }}
          style={{
            marginTop: "10vh",
            paddingBottom: "17vh",
            paddingTop: "15vh",
            height: "84vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div className="grid grid-cols-2 gap-2 md:gap-4 px-2 md:grid-cols-2 lg:grid-cols-3 sm:4 md:px-0 xl:gap-10">
            {CharacteristicsData.map((item) => (
              <MultiDec
                key={item.id}
                data={item}
                isSelected={selectedCharacteristics.some((char) => char.id === item.id)}
                onSelect={onSelect}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

