import React from "react";

export default function MultiSelect({
  data,
  withImg,
  onSelect,
  selectedAmenities,
}) {
  const isSelected = selectedAmenities.some(
    (amenity) => amenity.id === data.id
  );

  const onCheckmarkPress = () => {
    onSelect(data);
  };

  return (
    <div
      className={`overflow-hidden rounded-2xl border w-[170px] lg:w-[140px] xl:w-[200px] flex justify-center items-center ${
        isSelected ? `bg-[#58C0D0] bg-opacity-[50%]` : ""
      }`}
    >
      <div
        onClick={onCheckmarkPress}
        className="border-2 border-[#C1E1C2] cursor-pointer w-full h-[149px] flex flex-col justify-center items-center rounded-2xl"
      >
        {withImg ? (
          <div
            className={`w-16 h-16 flex justify-center items-center rounded-full ${
              isSelected ? "bg-[#FFFFFF]" : "bg-[#58C0D0] bg-opacity-[10%]"
            }`}
          >
            <img
              src={data.img}
              alt="amenity"
              className="w-[30px] h-[30px]"
            />
          </div>
        ) : (
          <div className="text-center py-2">{data.label}</div>
        )}
        <p className="text-center text-[18px] font-medium leading-6 font-avenir text-black mt-2">
          {data.text}
        </p>
      </div>
    </div>
  );
}
