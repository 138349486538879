import React, { useEffect, useState } from 'react';
import ManageProperty from './PropertyManagement';
import AdminPropertyDetail from './AdminPropertyDetail';
import userServices from 'services/httpService/userAuth/userServices';
import ErrorService from 'services/formatError/ErrorService';
import { toast } from 'react-toastify';
import { useMutation } from "react-query";

const Properties = () => {

  const [allProperty, setAllProperty] = React.useState([]);
  const [propertyDetail, setPropertyDetail] = React.useState(null);
  const [steps, setSteps] = useState('ManageProperty');

  const getproperty = async () => {
    const role = 'admin';
    let res = await userServices.commonGetService(
      `/property/getAllPropertyAvailable?role=${role}`
    );
    setAllProperty(res.data.data);
  };

  const { mutate: updateProperty } = useMutation(
    (data) =>
      userServices.updatePropertyStatus(`/property/updatePropertystatus/${data.itemId}`, { status: data.status }),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        console.log("data", data)
      },
    }
  );

  useEffect(() => {
    getproperty();
  }, []);

  const handleStatusChange = async (itemId, newStatus) => {
   
    await updateProperty({ itemId, status: newStatus });
  };

  const handleSetStepsAndDetail = (_id) => {
    const detail = allProperty.find(property => property._id === _id);
    setSteps('AdminPropertyDetail');
    setPropertyDetail(detail);
  };

  return (
    <>
      {steps === "ManageProperty" &&
        <ManageProperty setStepsAndDetail={handleSetStepsAndDetail} allProperty={allProperty} handleStatusChange={handleStatusChange} />
      }
      {steps === "AdminPropertyDetail" &&
        <AdminPropertyDetail setSteps={setSteps} propertyDetail={propertyDetail} allProperty={allProperty} />
      }
    </>
  );
};

export default Properties;
