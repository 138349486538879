import React from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "redux/store/actions/userAction/formAction";
import ListingColor from "components/Cards/ListingColor";
import SelectOption from "components/Select/SelectOption";
import doorSvg from "../../../assets/img/doorSvg.svg";
import homeSvg from "../../../assets/img/spaceWhiteHome.svg";
import sharedHomeSvg from "../../../assets/img/sharedHome.svg";

export default function SpaceType() {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.form);
  const data = [
    {
      value: "entire",
      label: "An Entire Place",
      description: "Guests have the whole place to themselves.",
      icon: homeSvg,
    },
    {
      value: "private",
      label: "A Room",
      description: "Guests have their own room in a home, plus access to shared spaces.",
      icon: doorSvg,
    },
    {
      value: "shared",
      label: "A Shared Room",
      description: "Guests sleep in a room or common area that may be shared with you or others.",
      icon: sharedHomeSvg,
    },
  ];

  const handleSelect = (key, value) => {
    dispatch(updateFormData({ [key]: value }));
  };

  return (
    <div>
      <Grid container>
        <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "85vh", marginTop: "10vh" }}>
          <ListingColor
            bg="bg-color-darknavyblue"
            text="What Kind Of Space Can Your Fellow Members Expect?"
            color="text-[#FFFFFF]"
            btn="Step 2"
          />
        </Grid>
        <Grid
          item
          lg={7}
          md={6}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          pt={5}
          className="spaceType_responsiveness"
        >
          <SelectOption
            data={data}
            spaceType={formData.spaceType}
            spaceTypeDetail={formData.spaceTypeDetail}
            onSelect={handleSelect}
          />
        </Grid>
      </Grid>
    </div>
  );
}
