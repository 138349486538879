import React, { useState } from "react";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";

const AuthHeader = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <section className="bg-[#FBFFFB] shadow-sm">
        <Container>
          <div className="flex items-center justify-between md:px-16 md:py-3">
            <button
              className="block text-gray-700 md:hidden focus:outline-none"
              onClick={toggleMobileMenu}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
            <Link to="/">
              <img src={require("assets/img/headerLogo.png")} alt="" className="hidden md:block w-[93px] h-[49.06px]" />
            </Link>
            <ul className="items-center hidden gap-4 md:flex">
              <li> <Link to="/aboutus"> About Us</Link></li>

              <li className="flex items-center justify-between px-2 py-1 border rounded-full cursor-pointer border-color-grey">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="15"
                  viewBox="0 0 20 15"
                  fill="none"
                >
                  <g clipPath="url(#clip0_231_12326)">
                    <rect
                      width="19"
                      height="13.5714"
                      transform="translate(0.261963 0.454102)"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.261963 0.454102H8.40482V6.78744H0.261963V0.454102Z"
                      fill="#1A47B8"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.40482 0.454102V1.35886H19.262V0.454102H8.40482ZM8.40482 2.26363V3.16839H19.262V2.26363H8.40482ZM8.40482 4.07315V4.97791H19.262V4.07315H8.40482ZM8.40482 5.88267V6.78744H19.262V5.88267H8.40482ZM0.261963 7.6922V8.59696H19.262V7.6922H0.261963ZM0.261963 9.50172V10.4065H19.262V9.50172H0.261963ZM0.261963 11.3112V12.216H19.262V11.3112H0.261963ZM0.261963 13.1208V14.0255H19.262V13.1208H0.261963Z"
                      fill="#F93939"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.16675 1.3584V2.26316H2.07151V1.3584H1.16675ZM2.97627 1.3584V2.26316H3.88103V1.3584H2.97627ZM4.7858 1.3584V2.26316H5.69056V1.3584H4.7858ZM6.59532 1.3584V2.26316H7.50008V1.3584H6.59532ZM5.69056 2.26316V3.16792H6.59532V2.26316H5.69056ZM3.88103 2.26316V3.16792H4.7858V2.26316H3.88103ZM2.07151 2.26316V3.16792H2.97627V2.26316H2.07151ZM1.16675 3.16792V4.07268H2.07151V3.16792H1.16675ZM2.97627 3.16792V4.07268H3.88103V3.16792H2.97627ZM4.7858 3.16792V4.07268H5.69056V3.16792H4.7858ZM6.59532 3.16792V4.07268H7.50008V3.16792H6.59532ZM1.16675 4.97745V5.88221H2.07151V4.97745H1.16675ZM2.97627 4.97745V5.88221H3.88103V4.97745H2.97627ZM4.7858 4.97745V5.88221H5.69056V4.97745H4.7858ZM6.59532 4.97745V5.88221H7.50008V4.97745H6.59532ZM5.69056 4.07268V4.97745H6.59532V4.07268H5.69056ZM3.88103 4.07268V4.97745H4.7858V4.07268H3.88103ZM2.07151 4.07268V4.97745H2.97627V4.07268H2.07151Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_231_12326">
                      <rect
                        width="19"
                        height="13.5714"
                        fill="white"
                        transform="translate(0.261963 0.454102)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className="ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                  >
                    <path
                      d="M5.26172 4.34316L9.15078 0.454102L10.2617 1.56504L5.26172 6.56504L0.261719 1.56504L1.37266 0.454102L5.26172 4.34316Z"
                      fill="#333333"
                    />
                  </svg>
                </div>
              </li>
            </ul>
          </div>
        </Container>
        {isMobileMenuOpen && (
          <div className="md:hidden">
            <ul className="p-4 bg-white flex justify-between">
              <li> <Link to="/aboutus"> About Us</Link></li>
              <li className="flex items-center justify-between px-2 py-1 border rounded-full cursor-pointer border-color-grey">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="15"
                  viewBox="0 0 20 15"
                  fill="none"
                >
                  <g clipPath="url(#clip0_231_12326)">
                    <rect
                      width="19"
                      height="13.5714"
                      transform="translate(0.261963 0.454102)"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.261963 0.454102H8.40482V6.78744H0.261963V0.454102Z"
                      fill="#1A47B8"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.40482 0.454102V1.35886H19.262V0.454102H8.40482ZM8.40482 2.26363V3.16839H19.262V2.26363H8.40482ZM8.40482 4.07315V4.97791H19.262V4.07315H8.40482ZM8.40482 5.88267V6.78744H19.262V5.88267H8.40482ZM0.261963 7.6922V8.59696H19.262V7.6922H0.261963ZM0.261963 9.50172V10.4065H19.262V9.50172H0.261963ZM0.261963 11.3112V12.216H19.262V11.3112H0.261963ZM0.261963 13.1208V14.0255H19.262V13.1208H0.261963Z"
                      fill="#F93939"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.16675 1.3584V2.26316H2.07151V1.3584H1.16675ZM2.97627 1.3584V2.26316H3.88103V1.3584H2.97627ZM4.7858 1.3584V2.26316H5.69056V1.3584H4.7858ZM6.59532 1.3584V2.26316H7.50008V1.3584H6.59532ZM5.69056 2.26316V3.16792H6.59532V2.26316H5.69056ZM3.88103 2.26316V3.16792H4.7858V2.26316H3.88103ZM2.07151 2.26316V3.16792H2.97627V2.26316H2.07151ZM1.16675 3.16792V4.07268H2.07151V3.16792H1.16675ZM2.97627 3.16792V4.07268H3.88103V3.16792H2.97627ZM4.7858 3.16792V4.07268H5.69056V3.16792H4.7858ZM6.59532 3.16792V4.07268H7.50008V3.16792H6.59532ZM1.16675 4.97745V5.88221H2.07151V4.97745H1.16675ZM2.97627 4.97745V5.88221H3.88103V4.97745H2.97627ZM4.7858 4.97745V5.88221H5.69056V4.97745H4.7858ZM6.59532 4.97745V5.88221H7.50008V4.97745H6.59532ZM5.69056 4.07268V4.97745H6.59532V4.07268H5.69056ZM3.88103 4.07268V4.97745H4.7858V4.07268H3.88103ZM2.07151 4.07268V4.97745H2.97627V4.07268H2.07151Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_231_12326">
                      <rect
                        width="19"
                        height="13.5714"
                        fill="white"
                        transform="translate(0.261963 0.454102)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className="ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                  >
                    <path
                      d="M5.26172 4.34316L9.15078 0.454102L10.2617 1.56504L5.26172 6.56504L0.261719 1.56504L1.37266 0.454102L5.26172 4.34316Z"
                      fill="#333333"
                    />
                  </svg>
                </div>
              </li>
            </ul>
          </div>
        )}
      </section>
    </>
  );
};

export default AuthHeader;

