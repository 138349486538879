import React, { useState } from "react";

export default function SelectBox({ data, onSelect, propertyType }) {
  const [userOption, setUserOption] = useState(null);

  const selectHandler = (value) => {
    onSelect(value);
    setUserOption(value);
  };

  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
      {data.map((item) => (
        <div
          key={item.value}
          className="cursor-pointer border-2 overflow-hidden border-[#C1E1C2] w-[170px] lg:w-[140px] xl:w-[200px] h-[149px] flex justify-center items-center gap-16 rounded-2xl"
          onClick={() => selectHandler(item.value)}
        >
          <div
            className={`flex justify-center flex-col items-center w-full h-full ${
              item.value === userOption || propertyType === item.value
                ? "bg-[#58C0D0] bg-opacity-[50%]"
                : ""
            }`}
          >
            <div
              className={`${
                item.value === userOption || propertyType === item.value
                  ? "bg-[#FFFFFF]"
                  : "bg-[#58C0D0] bg-opacity-[10%]"
              } w-16 h-16 flex justify-center items-center rounded-full`}
            >
              <img
                src={item.image}
                alt={item.text}
                className="w-[30px] h-[30px]"
              />
            </div>
            <p className="text-center text-[18px] font-medium leading-6 font-avenir text-black mt-2">
              {item.text}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
