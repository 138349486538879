import React from "react";

export default function SelectOption({ data, onSelect, spaceType, spaceTypeDetail }) {
  const handleSelect = (value) => {
    onSelect("spaceType", value);
    if (value !== spaceType) {
      const defaultDetails = { guests: 0, bedrooms: 0, bathrooms: 0 };
      onSelect("spaceTypeDetail", defaultDetails);
    }
  };

  const handleIncrement = (field) => {
    const updatedDetails = {
      ...spaceTypeDetail,
      [field]: (spaceTypeDetail?.[field] || 0) + 1,
    };
    onSelect("spaceTypeDetail", updatedDetails);
  };

  const handleDecrement = (field) => {
    const updatedDetails = {
      ...spaceTypeDetail,
      [field]: Math.max((spaceTypeDetail?.[field] || 0) - 1, 0),
    };
    onSelect("spaceTypeDetail", updatedDetails);
  };

  return (
    <div className="grid gap-3 mx-3">
      {data.map((item) => (
        <div
          key={item.value}
          className={`h-auto px-10 py-5 flex flex-col items-center justify-center gap-4 md:w-full border border-color-green rounded-2xl ${
            item.value === spaceType ? "bg-color-secondry" : ""
          }`}
          onClick={() => handleSelect(item.value)}
        >
          <div className="flex w-full gap-5">
            <div className="flex w-full gap-5 sm:flex-row flex-col">
              <div
                className={`rounded-full flex justify-center items-center w-[100px] h-[100px] sm:w-[66px] sm:h-[66px] p-4 ${
                  item.value === spaceType ? "bg-white" : "bg-color-secondry bg-opacity-10"
                }`}
              >
                <img src={item.icon} alt={item.label} className="w-full m-auto h-full object-contain" />
              </div>
              <div>
                <p className="text-2xl font-medium">{item.label}</p>
                <p className={`text-lg font-normal ${item.value === spaceType ? "text-black" : "text-color-darkgrey"}`}>
                  {item.description}.
                </p>
              </div>
            </div>
          </div>

          {item.value === spaceType && (
            <div className="text-base font-normal w-full flex flex-col sm:flex-row gap-5 flex-wrap">
              {["guests", "bedrooms", "bathrooms"].map((field) => (
                <div
                  key={field}
                  className="rounded-[50px] flex justify-between items-center gap-5 px-3 py-2 bg-white border border-color-grey"
                >
                  <span className="text-color-grey w-[50%] text-sm uppercase">{field.toUpperCase()}</span>
                  <div className="flex gap-5 items-center justify-evenly">
                    <span className="cursor-pointer" onClick={() => handleDecrement(field)}>
                      -
                    </span>
                    <span>{spaceTypeDetail?.[field] || 0}</span>
                    <span className="cursor-pointer" onClick={() => handleIncrement(field)}>
                      +
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
