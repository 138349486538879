import React from 'react';

const InfoBlock = ({ bgColor, title, description, listItems, image, textColor="text-gray-900" }) => {
  return (
    <div
      className={`relative ${bgColor} mt-4 p-6 md:p-8 rounded-3xl shadow-lg w-[95%] lg:max-w-7xl mx-auto overflow-visible`}
    >
      {image && (
        <img
          src={image.src}
          alt={image.alt}
          className="absolute top-0 right-0 lg:-top-10 lg:-right-8 w-40 h-28 lg:w-48 lg:h-32 object-cover shadow-md"
        />
      )}

      <h2 className={`mt-24 lg:mt-3 text-2xl md:text-3xl font-bold mb-4 ${textColor}`}>
        {title}
      </h2>

      {listItems ? (
        <ul className="text-gray-800 list-disc pl-5 space-y-2 mb-6">
          {listItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ) : (
        <p className={`space-y-2 mb-6 ${textColor}`}>{description}</p>
      )}
    </div>
  );
};

export default InfoBlock;
