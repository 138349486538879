import React from 'react'
import GeneralSetting from './GeneralSetting'
import PropertySetting from './PropertySetting'
import BookingSetting from './BookingSetting'
import PaymentSetting from './PaymentSetting'
import EmailSetting from './EmailSetting'
import SecuritySetting from './SecuritySetting'
import PrivacySetting from './PrivacySetting'
import OtherSetting from './OtherSetting'

const SettingsSteps = ({ active, General, Property, Booking, Payment, Email, Security, Privacy, Other }) => {
  return (
    <>
      {active === 1 && <GeneralSetting General={General} />}
      {active === 2 && <PropertySetting Property={Property} />}
      {active === 3 && <BookingSetting Booking={Booking} />}
      {active === 4 && <PaymentSetting Payment={Payment} />}
      {active === 5 && <EmailSetting Email={Email} />}
      {active === 6 && <SecuritySetting Security={Security} />}
      {active === 7 && <PrivacySetting Privacy={Privacy} />}
      {active === 8 && <OtherSetting Other={Other} />}
    </>
  )
}

export default SettingsSteps;