import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import userServices from "services/httpService/userAuth/userServices";
import ErrorService from "services/formatError/ErrorService";
import { localStorageData } from "services/auth/localStorageData";

const WishlistButton = ({
  propertyId,
  favourite,
  favoriteRender,
  setFavoriteRender,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFavorite, setIsFavorite] = useState(favourite);

  const handleWishlist = () => {
    if (!localStorageData("_id")) {
      return toast.error("Please login first");
    }
    setIsLoading(true);
    mutate({ propertyId, userId: localStorageData("_id") });
  };

  const { mutate } = useMutation(
    (propertyId) =>
      userServices.Wishlist("/property/updatePropertyforFavourite", propertyId),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
        setIsLoading(false);
      },
      onSuccess: () => {
        setIsLoading(false);
        setIsFavorite(true);
        setFavoriteRender(!favoriteRender);
      },
    }
  );

  return (
    <button
    className={`fa-regular ${
        isLoading ? "opacity-50 cursor-not-allowed" : ""
      } ${favourite && "text-color-red fa-solid"
} fa-heart w-[17.14px] h-[15.7px]`}
      onClick={handleWishlist}
      disabled={isLoading}
    ></button>
  );
};

export default WishlistButton;
