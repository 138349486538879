import React, { useState } from 'react'
import ReviewsFeedback from './ReviewsFeedback'
import ReviewResponse from '../ReviewResponse/ReviewResponse'

const AdminReviews = () => {
  const [steps, setSteps] = useState('ReviewsFeedback')
  return (
    <>
      {steps === "ReviewsFeedback" &&
        <ReviewsFeedback setSteps={setSteps} />
      }
      {steps === "ReviewResponse" &&
        <ReviewResponse setSteps={setSteps} />
      }
    </>
  )
}

export default AdminReviews