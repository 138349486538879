import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReviewsSteps from "./ReviewsSteps";
import userServices from "services/httpService/userAuth/userServices";
import AdminPropertyDetail from "../PropertyManagement/AdminPropertyDetail";

const ReviewsFeedback = ({ setSteps }) => {
  const [active, setActive] = useState(1);
  const [allProperty, setAllProperty] = useState([]);
  const [Report, setReport] = useState([]);

  const handleActive = (num) => {
    setActive(num);
  };

   const getproperty = async () => {
    let res = await userServices.commonGetService(
      `/property/getAllPropertyAvailable`
    );
    setAllProperty(res.data.data);
  };

  useEffect(() => {
    getproperty();
  }, []);

  return (
    <>
      <section className="bg-[#f8f7fa]">
        <Container maxWidth="xl">
          <div className="my-4 pt-7">
            <h1 className="text-[24px] font-extrabold">Feedback and Reviews</h1>
          </div>
          <div className="">
            <div className="grid grid-flow-row md:grid-cols-6 grid-cols-3 items-center border-b border-b-color-grey relative ">
              <p
                onClick={() => handleActive(1)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mr-2 relative -bottom-[0.5px] ${active == 1
                  ? "border-b-color-yellow text-color-black"
                  : "border-b-transparent"
                  } `}
              >
                All Reviews
              </p>
              <p
                onClick={() => handleActive(2)}
                className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 2
                  ? " border-b-color-yellow text-color-black"
                  : "border-b-transparent"
                  }`}
              >
                Flag / Report
              </p>
            </div>
            <ReviewsSteps
              active={active}
              All={allProperty}
              Report={Report}
              setSteps={setSteps}
            />
          </div>
        </Container>
      </section>
    </>
  )
}

export default ReviewsFeedback