import React from 'react'
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useChat } from 'common/contexts/chatConversationsContext';
import { useNavigate } from 'react-router-dom';

export function ChatNotificationsList({ handleClose }) {
  const navigate = useNavigate()
  const { conversationData2, isLoading2, error, fetchConversations } = useChat();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleClick = () => {
    navigate('/chat')
    handleClose()
  }
  return (
    <>
      {conversationData2?.map((conversation) => (
        <React.Fragment key={conversation.conversationId}>
          {conversation.unreadMessageCount > 0 && (
            <ListItem alignItems="flex-start" sx={{ cursor: 'pointer' }} onClick={handleClick}>
              <ListItemAvatar>
                <Avatar alt={conversation.user.fullName} src={conversation.user.pic} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="body1" fontWeight="bold">
                    {capitalizeFirstLetter(conversation.user.fullName)}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {conversation.lastMessage}
                    </Typography>
                    {conversation.unreadMessageCount > 0 && (
                      <div className="absolute flex items-center justify-center w-5 h-5 text-white bg-[#E8AD21] rounded-full top-1 right-1">
                        <span className="text-[10px]"> {conversation.unreadMessageCount}</span>
                      </div>
                    )}
                  </React.Fragment>
                }
              />
            </ListItem>
          )}
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}

    </>
  );
}