import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AutoAddress from "components/AutoAddress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-input-range/lib/css/index.css";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

const LandingSearchBar = ({
  notPedding,
  onSearchInputChange,
  checkindate,
  checkoutdate,
}) => {
  const [checkIn, setCheckIn] = React.useState(null);
  const [checkOut, setCheckOut] = React.useState(null);
  const [guest, setGuest] = useState("");
  const [rooms, setRooms] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  const [fullAddress, setFullAddress] = useState({
    address: "South Carolina, USA",
    lat: 33.8361,
    long: -81.1637,
    city: "",
    state: "South Carolina",
    stateAbbreviation: "SC",
    country: "United States",
    countryAbbreviation: "USA"
  });

  const [lat, setLat] = useState(33.8361);
  const [long, setLong] = useState(-81.1637);
  const [City, setcity] = useState("");
  const [State, setstate] = useState("South Carolina");
  const [stateAbbr, setstateAbbr] = useState("SC");
  const [Country, setcountry] = useState("United States");
  const [CountryAbbr, setcountryAbbr] = useState("USA");
  const [open, setOpen] = useState(false);
  const [checkOutOpen, setCheckOutOpen] = useState(false);

  const currentDate = dayjs();
  let navigate = useNavigate();
  const { state: locationData } = useLocation();

  useEffect(() => {
    if (!isInitialized && locationData?.fullAddress) {
      const { lat, long, city, state, stateAbbreviation, country, countryAbbreviation, address } = locationData.fullAddress;
      setFullAddress(locationData.fullAddress);
      setLat(lat);
      setLong(long);
      setcity(city || "");
      setstate(state);
      setstateAbbr(stateAbbreviation);
      setcountry(country);
      setcountryAbbr(countryAbbreviation);
      
      if (locationData?.spaceTypeDetail) setGuest(locationData.spaceTypeDetail);
      if (locationData?.bedroomData) setRooms(locationData.bedroomData);
      if (locationData?.checkIn) setCheckIn(dayjs(locationData.checkIn));
      if (locationData?.checkOut) setCheckOut(dayjs(locationData.checkOut));
      
      setIsInitialized(true);
    }
  }, [locationData, isInitialized]);

  const changeAddress = (address, lat, long, city, state, country, stateAbbreviation, countryAbbreviation) => {
    const processedCity = city || "";
    const newAddress = {
      address,
      lat,
      long,
      city: processedCity,
      state,
      stateAbbreviation,
      country,
      countryAbbreviation
    };
    
    setLat(lat);
    setLong(long);
    setcity(processedCity);
    setstate(state);
    setstateAbbr(stateAbbreviation);
    setcountry(country);
    setcountryAbbr(countryAbbreviation);
    setFullAddress(newAddress);
  };


  const handleInputChange = (e) => {
    const value = Math.max(0, parseInt(e.target.value));
    setGuest(value);
    setSearchInput(value);
    if (onSearchInputChange) {
      onSearchInputChange(value);
    }
  };
  
  const handleRoomInput = (e) => {
    const value = Math.max(0, parseInt(e.target.value));
    setRooms(value);
    setSearchInput(value);
    if (onSearchInputChange) {
      onSearchInputChange(value);
    }
  };

const onSearch = () => {
  if (!lat || lat === 0) {
    toast.error("Location required for search");
    return;
  }

  const queryParams = new URLSearchParams();
  if (City && City.trim() !== '') {
    queryParams.append("city", City);
  }
  queryParams.append("state", State);
  queryParams.append("StateAbbr", stateAbbr);
  queryParams.append("country", Country);
  queryParams.append("CountryAbbr", CountryAbbr);

  navigate(`/search?${queryParams.toString()}`, {
    state: {
      spaceTypeDetail: guest,
      bedroomData: rooms,
      checkIn,
      checkOut,
      fullAddress: {
        ...fullAddress,
        lat,
        long,
        city: City || ""
      }
    },
  });
};

  useEffect(() => {
    locationData?.spaceTypeDetail && setGuest(locationData?.spaceTypeDetail);
    locationData?.bedroomData && setRooms(locationData?.bedroomData);
    locationData?.fullAddress?.lat && setLat(locationData.fullAddress.lat);
    locationData?.fullAddress?.long && setLong(locationData.fullAddress.long);
  }, [locationData]);

  return (
    <>
      <Container maxWidth="lg">
        <div className="flex justify-center items-center">
          <span className="text-white font-bold w-[90%] pt-40 lg:pt-24 md:w-[80%] lg:w-[60%] xl:w-[54%] leading-tight  text-center  text-xl md:text-4xl">
            The Exchange Community for Vacation Rental Property Owners
          </span>
        </div>

        <div
          className={`z-10 w-full ${notPedding === "notapply" ? " pt-0" : "md:pt-20 lg:pt-28"
            }  center-styl`}
        >
          <div className="text-start pl-0 md:pl-0 md:text-start pt-20 md:pt-0">
            {notPedding !== "notapply" ? (
              <span className="text-[#FFFFFF] text-[24px] md:text-[30px]  font-extrabold ">
                Find Your Destination
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="block lg:flex pt-5">
            <div className="w-full py-2 flex  md:justify-center lg:h-[100px] h-full bg-white shadow rounded-r-xl rounded-l-xl md:rounded-r-none md:rounded-l-xl">
              <div className="flex flex-col gap-2 lg:flex-row px-4 text-[#AFB5C1]  w-full lg:w-auto items-center justify-between">
                <div className="lg:my-0 md:my-0 w-full lg:w-auto h-[50px]">
                  <div className="flex items-center justify-between px-4 py-3 border border-gray-300 rounded-xl ">
                    <div>
                      <AutoAddress
                        className="outline-none"
                        placeholder="Destination"
                          defaultAddress="South Carolina, USA"

                        onChangeAddress={changeAddress}
                      />
                      

                    </div>
                    <div className="pr-3">
                      <i className="fa-solid fa-location-dot"></i>
                    </div>
                  </div>
                </div>
                <div  className="flex  w-full lg:w-auto items-center justify-center px-4 py-2 border border-gray-300 rounded-xl h-[50px]">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      open={open} 
                      onOpen={() => setOpen(true)} 
                      onClose={() => setOpen(false)}
                      label="Basic example"
                      value={notPedding === "notapply" ? checkindate : checkIn}
                      onChange={(newValue) => {
                        setCheckIn(newValue);
                        setOpen(false);
                      }}
                      minDate={currentDate.toDate()}
                     
                      renderInput={({
                        inputRef,
                        inputProps,
                        InputProps,
                        params,
                      }) => (
                        <div className="flex items-center" onClick={() => setOpen(true)}>
                          <input
                            className="w-full text-black outline-0 cursor-pointer"
                            ref={inputRef}
                            {...inputProps}
                            placeholder="Check-in"
                            {...params}
                          />
                          {InputProps?.endAdornment}
                        </div>
                      )}
                    />
                  </LocalizationProvider>
                  <div className="w-px h-full mx-2 bg-gray-300"></div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                     open={checkOutOpen} 
                     onOpen={() => setCheckOutOpen(true)} 
                     onClose={() => setCheckOutOpen(false)}      
                      label="Basic example"
                      value={notPedding === "notapply" ? checkoutdate : checkOut}
                      onChange={(newValue) => {
                        setCheckOut(newValue);
                        setCheckOutOpen(false);
                      }}
                      minDate={checkIn || currentDate.toDate()}
                      slotProps={{
                        textField: {
                          onClick: (event) => event.currentTarget?.parentElement?.click(),
                          placeholder: "Check-out",
                        },
                      }}
                      renderInput={({
                        inputRef,
                        inputProps,
                        InputProps,
                        params,
                      }) => (
                        <div className="flex items-center" onClick={() => setCheckOutOpen(true)}>
                          <input
                            className="w-full text-black outline-0 cursor-pointer"
                            ref={inputRef}
                            {...inputProps}
                            placeholder="Check-out"
                            {...params}
                          />
                          {InputProps?.endAdornment}
                        </div>
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="flex items-center px-4 py-2 border border-gray-300 rounded-xl w-full lg:w-[170px] h-[50px]">
                  <input
                    placeholder="Guests"
                    className="w-full outline-none"
                    id="guestsInput"
                    type="number"
                    min={0}
                    value={guest}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex items-center px-4 py-2 border border-gray-300 rounded-xl w-full lg:w-[170px] h-[50px]">
                  <input
                    placeholder="Rooms"
                    className="w-full outline-none"
                    id="bedroomsInput"
                    type="number"
                    min={0}
                    value={rooms}
                    onChange={handleRoomInput}
                  />
                </div>
                <div className="flex  w-full lg:w-auto h-[50px] justify-center">
                  <button
                    onClick={onSearch}
                    className="text-sm w-full lg:w-[100px] font-bold text-white transition-all duration-150 ease-linear rounded-full shadow bg-[#2459BF] hover:shadow-lg"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            {/* {notPedding !== "notapply" ? (
              <div className="flex items-center rounded-r-xl rounded-l-xl lg:rounded-r-xl lg:rounded-l-none justify-center mt-2 lg:mt-0 w-full lg:w-[120px] lg:h-[100px] h-[50px] bg-[#E8AD21]">
                <span className="w-[260px] h-[30px] lg:h-[75px] text-center text-[#000000] font-[500] text-[18px] lg:leading-[1.1] lg:px-4 flex items-center justify-center">
                  Where can you stay right now?
                </span>
              </div>
            ) : (
              <div className="flex border-l-2 border-l-[#AFB5C1] items-center rounded-r-xl rounded-l-xl md:rounded-r-xl lg:rounded-l-none justify-center w-full lg:w-[150px] bg-[#E8AD21]">
                <div className="w-[114px] h-[75px] flex items-center justify-center text-center text-[#000000] font-[500] text-[18px] lg:leading-[1.1] lg:px-4">
                  Where can you stay right now!
                </div>
              </div>
            )} */}
          </div>
        </div>
      </Container>
    </>
  );
}

export default LandingSearchBar;