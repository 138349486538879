import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SearchCard from "components/Cards/SearchCard";
import { config } from "../../../config";
import ErrorService from "services/formatError/ErrorService";
import userServices from "services/httpService/userAuth/userServices";
import GoogleMapReact from "google-map-react";
import { useLocation } from "react-router-dom";
import LandingSearchBar from "components/LandingSearchBar/LandingSearchBar";

const PropertyMaker = ({ text, property, onHover }) => {
  return (
    <div
      onMouseEnter={() => {
        console.log(property);
        onHover(property);
      }}
      onMouseLeave={() => {
        onHover(null);
      }}
      className="cursor-pointer relative"
    >
      <img
        src={require("assets/img/bnbyondmapiconborder.png")}
        className="lg:w-25 lg:h-25 xs:w-8 xs:h-8 rounded object-contain"
        alt="Property Marker"
      />
    </div>
  );
};

export default function SearchListings() {
  const { search, state } = useLocation();
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const params = new URLSearchParams(search);
  const city = params.get("city");
  const State = params.get("state");
  const StateAbbr = params.get("StateAbbr");
  const country = params.get("country");
  const CountryAbbr = params.get("CountryAbbr");
  const [allPost, setallPost] = useState([]);
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [filterType, setFilterType] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [hoveredProperty, setHoveredProperty] = useState(null);

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
  };

  const handleFilter = (type) => {
    if (type === "bedroom" || type === "guest") {
      setFilterType(type);
      setSelectedFilter(type);
    }
  };

  const filterPrice = (val) => {
    setSelectedPrice(val);
  };

  const getproperty = async () => {
    setLoading(true);
    try {
      let queryParams = "";
      if (city) queryParams += `city=${city}`;
      if (state) queryParams += `&state=${State}`;
      if (country) queryParams += `&country=${country}`;
      if (StateAbbr) queryParams += `&StateAbbr=${StateAbbr}`;
      if (CountryAbbr) queryParams += `&country=${CountryAbbr}`;
      // const res = await userServices.commonGetService(`/property/getAllProperty/${lat}/${long}/15`);
      const res = await userServices.commonGetService(`/property/getAllProperty?${queryParams}&address=${encodeURIComponent(state.fullAddress.address)}`);


      let filterData = res?.data?.data || [];
      if (state?.spaceTypeDetail) {
        filterData = filterData.filter((item) => item.spaceTypeDetail.guests >= state?.spaceTypeDetail);
      }
      if (state?.bedroomData) {
        filterData = filterData.filter((item) => item.spaceTypeDetail.bedrooms >= state?.bedroomData);
      }
      // Filter based on check-in and check-out dates if provided
      if (state?.checkIn && state?.checkOut) {
        const checkInDate = new Date(state.checkIn);
        const checkOutDate = new Date(state.checkOut);
        filterData = filterData.filter((item) => {
          const bookedDates = item.bookedDates || [];
          const isAvailable = !bookedDates.some((bookedDate) => {
            const bookedDateObj = new Date(bookedDate);
            return (bookedDateObj >= checkInDate && bookedDateObj <= checkOutDate);
          });
          return isAvailable;
        });
      }
      setallPost(filterData);
    } catch (error) {
      ErrorService.handleError(error);
    } finally {
      setLoading(false);
    }
  };

  // Handle data filtering based on price and bedrooms
  const handleFilterData = (priceRange, bedrooms) => {

    const filterData = data.filter((item) => {
      return item.price >= priceRange.min && item.price >= priceRange.max;
    });

    if (filterData.length > 0) {
      setallPost(filterData);
    } else {
      setallPost(filterData);
    }
  };
  useEffect(() => {
    if (state && state.fullAddress) {
      const latitude = parseFloat(state.fullAddress.lat);
      const longitude = parseFloat(state.fullAddress.long);

      if (!isNaN(latitude) && !isNaN(longitude)) {
        setLat(latitude);
        setLong(longitude);
      } else {
        console.error("Invalid lat/long values", state.fullAddress);
        // Optionally, set default fallback values if invalid
        setLat(0);
        setLong(0);
      }
    }
  }, [state]);
  useEffect(() => {
    if (city || state || country) {
      getproperty(); // Fetch properties when the component mounts or when the query parameters change
    }
  }, [city, state, country]);

  return (
    <div className="mt-20">
      <Grid item sm={12} xs={12} style={{ border: 'none' }}>
        <div className="px-4 pt-3 pb-7 bg-cover bg-center  bg-[url('assets/img/headerBg.png')]">
          <LandingSearchBar
            notPedding="notapply"
            checkindate={state.checkIn?.$d}
            checkoutdate={state.checkOut?.$d}
            onSearchInputChange={handleSearchInputChange}
            handleFilter={handleFilter}
            filterType={filterType}
            filterPrice={filterPrice}
            setSelectedPrice={setSelectedPrice}
            handleFilterData={handleFilterData}
          />
        </div>
      </Grid>
      <Grid container spacing={2} >

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="px-2 lg:px-0 w-full md:ml-10 xl:ml-24 h-full overflow-y-auto">
            <p className="px-2 py-4 font-bold text-md">
              {allPost.length} Homes Available
            </p>

            {loading ? (
              <p className="flex items-center justify-center my-4 text-lg font-semibold">
                Loading...
              </p>
            ) : allPost.length === 0 ? (
              <p className="flex items-center justify-center my-4 text-lg font-semibold">
                No data matches
              </p>
            ) : (
              <div>
              {allPost.map((item) => (
  <SearchCard key={item._id} data={item} isHighlighted={hoveredProperty?._id === item._id} />
))}

              </div>
            )}
          </div>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className="w-full h-screen">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: config.mapkey,

              }}
              defaultZoom={11}
              center={{
                lat: lat || 0,
                lng: long || 0,
              }}

            >
              {allPost.length > 0 && allPost.map((item) => {
                return (
                  <PropertyMaker
                  key={item._id}
                  lat={item.loc.coordinates[1]}
                  lng={item.loc.coordinates[0]}
                  text={item.price}
                  property={item}  
                  onHover={(property) => setHoveredProperty(property)} // Set hovered property
                />
                
                
                );
              })}
            </GoogleMapReact>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
