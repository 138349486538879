import React from 'react';
import { useNavigate } from 'react-router-dom';
import TopCard from './TopCard';
import UserList from './UserList';
import userServices from 'services/httpService/userAuth/userServices';
import { toast } from "react-toastify";

const InfluencersCard = ({ setStepsAndDetail, Influencers, allUser }) => {
  const navigate = useNavigate();

  const headers = ["Registration Date", "Ratings", "Verified", "Status", "Last Login", "Action"];
  const heading = "All Influencers";

  const handleDeleteUser = async (userId) => {
    try {
      await userServices.deleteUser(`/userAuth/deleteUser/${userId}`);
      toast.success('User deleted successfully');
    } catch (error) {
      toast.error('Failed to delete user');
      console.error('Error deleting user:', error);
    }
  };

  const goToSTRInnovator = () => {
    navigate("/admin/dashboard/str-innovator");
  };

  return (
    <>
      <TopCard allUsers={allUser} />
      <div className='my-2 w-full flex justify-end items-end'>
        <div
          onClick={goToSTRInnovator}
          className="cursor-pointer bg-white flex justify-between sm:p-4 p-2 items-center  w-[150px] h-[45px]  border border-gray-300 rounded-md"
        >
          <div>
            <p className="font-normal text-xs text-gray-600">+ Add STR Innovator</p>
          </div>
        </div>
      </div>
      <UserList
        headers={headers}
        data={Influencers}
        heading={heading}
        setStepsAndDetail={setStepsAndDetail}
        onDeleteUser={handleDeleteUser}
      />
    </>
  );
};

export default InfluencersCard;
