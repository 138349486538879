import axios from "axios";
import { config } from "../../config";

const Api = axios.create({
  baseURL: config.endPoint,
  headers: {
    "Content-type": "application/json",
  },
});

// Axios Request Interceptor: Add token dynamically before every request
Api.interceptors.request.use(
  (request) => {
    let localData = JSON.parse(localStorage.getItem(config.localToken));
    if (
      (localData && localData?.data?.hasOwnProperty("token")) ||
      (localData && localData?.hasOwnProperty("token"))
    ) {
      request.headers.Authorization = `Bearer ${
        localData?.data?.token || localData?.token
      }`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

export default Api;
