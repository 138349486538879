import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import Rating from "react-rating";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { localStorageData } from "services/auth/localStorageData";
import Grid from "@mui/material/Grid";
import { ButtonWithoutBackground } from "common/buttons/buttonWithoutBackgourd";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import ErrorService from "services/formatError/ErrorService";
import dayjs from "dayjs";
import GuestAndRoomSelector from "components/GuestAndRoomSelector/GuestAndRoomSelector";
import { PaymentCard } from "components/Cards/PaymentCard";
import visaIcon from "../../../assets/img/cardTypes.jpg";
import moment from "moment";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const currentDate = dayjs();
function ReserveDetails() {
  const [paymentType, setPaymentType] = useState("");
  const [isSelectVisible, setIsSelectVisible] = useState({
    selectData: false,
    selectGuest: false,
  });
  const [showAddCard, setShowAddCard] = useState(false);
  const [tripe, setTripe] = useState({
    message: "",
    phoneNumber: "",
  });
  const navigate = useNavigate();
  const [allPost, setallPost] = React.useState([]);
  const location = useLocation();
  const { state } = location;

  // State for API payload, initialized with data from location state
  const [apiPayload, setApiPayload] = useState({
    offerState: {},
    paymentType: "",
    property_id: "",
    propertyUserId: "",
    state,
    tripe: {
      message: "",
      phoneNumber: "",
    },
    userId: localStorageData("_id"),
  });

  // State for various form inputs and selections
  let [checkIn, setCheckIn] = useState(state?.offerState.checkIn);
  let [checkOut, setCheckOut] = useState(state?.offerState.checkOut);
  let [count, setCount] = useState(state?.offerState.count);
  const [points, setPoints] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [existCardNumber, setExistCardNumber] = useState("");
  const [Loading, setLoading] = useState(false);

  const [guests, setGuests] = useState(state.offerState.guest);
  const [rooms, setRooms] = useState(state.offerState.room);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Function to handle guest count changes
  const handleGuestChange = (action) => {
    if (
      action === "increment" &&
      guests < state?.propertyDetail?.spaceTypeDetail?.guests
    ) {
      setGuests((prevGuests) => prevGuests + 1);
    } else if (action === "decrement" && guests > 1) {
      setGuests((prevGuests) => prevGuests - 1);
    }
  };

  // Function to handle room count changes
  const handleRoomChange = (action) => {
    if (
      action === "increment" &&
      rooms < state?.propertyDetail?.spaceTypeDetail?.bedrooms
    ) {
      setRooms((prevRooms) => prevRooms + 1);
    } else if (action === "decrement" && rooms > 1) {
      setRooms((prevRooms) => prevRooms - 1);
    }
  };

  // Mutation to confirm reservation
  const { mutate, isLoading: LoadingReservationConfirm } = useMutation(
    (reservationData) =>
      userServices.Reservation("/reservation/reservation", reservationData),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success("Reservation created successfully");
        navigate(`/ConfirmReservation`, {
          state: { offerState: state, propertyDetail: allPost },
          state: apiPayload,
        });
      },
    }
  );

  // Mutation to add card information
  const { mutate: setMutate, isLoading: LoadingCardAdd } = useMutation(
    (reservationData) =>
      userServices.Reservation("/stripe/AddCardInfo", reservationData),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setCardNumber("");
        setExpiryDate("");
        setCardName("");
        setCvv("");
        passMutate();
        setShowAddCard(false);
        toast.success("Your card added successfully");
      },
    }
  );

  // Mutation to fetch user data (e.g., card info)
  const { mutate: passMutate, isLoading: CardLoading } = useMutation(
    (userData) =>
      userServices.userById(
        `/userAuth/user/${localStorageData("_id")}`,
        userData
      ),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        if (data && data.data && data.data.cardInfo) {
          const { cardNumber } = data?.data?.cardInfo;
          setExistCardNumber(cardNumber);
        }
      },
    }
  );

  // Function to confirm reservation
  const onClickConfirm = () => {
    if (!existCardNumber) {
      return toast.error("please add the card details");
    }
    mutate({ ...apiPayload, state: undefined });
  };

  // Effect to calculate and set points based on count and property detail
  useEffect(() => {
    const sum = state?.offerState?.count * state?.propertyDetail?.points;
    setPoints(sum);
  }, []);
  const handleClick = () => {
    navigate(`/propertydetails/${state.propertyDetail._id}`);
  };
  const handleCheckboxChange = (option) => {
    setSelectedOption((prevOption) => (prevOption === option ? "" : option)); // Toggle the selected option
    if (selectedOption === option) {
      setPaymentType("");
    } else {
      if (option === "one") {
        setPaymentType("full");
      } else if (option === "two") {
        setPaymentType("part");
      }
    }
  };

  const handleAddButtonClick = (field) => {
    setTripe((prevTripe) => ({
      ...prevTripe,
      [field]: !prevTripe[field],
    }));
  };

  // Effect to update API payload whenever state, allPost, paymentType, or tripe changes
  useEffect(() => {
    setApiPayload((prevApiPayload) => ({
      ...prevApiPayload,
      offerState: {
        ...state.offerState,
        checkIn: state.offerState.checkIn.$d,
        checkOut: state.offerState.checkOut.$d,
      },
      property_id: state.propertyDetail._id,
      paymentType: paymentType,
      propertyUserId: state.propertyDetail.userId,
      tripe: { message: tripe.message, phoneNumber: tripe.phoneNumber },
    }));
  }, [state, allPost, paymentType, tripe]);

  //format checkin and checkout date to readable form
  const checkInDate = new Date(state.offerState.checkIn.$d);
  const formattedCheckInDate = checkInDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  const checkOutDate = new Date(state.offerState.checkOut.$d);
  const formattedCheckOutDate = checkOutDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  // Function to toggle visibility of select elements
  const handleButtonClick = (buttonName) => {
    setIsSelectVisible((prevVisibility) => ({
      ...prevVisibility,
      [buttonName]: !prevVisibility[buttonName],
    }));
  };

  const handleUpdateClick = () => {
    state.offerState.checkIn = checkIn;
    state.offerState.checkOut = checkOut;
    state.offerState.guest = guests;
    state.offerState.room = rooms;

    // Extract actual Date objects
    const checkInDate =
      checkIn.$d instanceof Date ? checkIn.$d : new Date(checkIn.$d);
    const checkOutDate =
      checkOut.$d instanceof Date ? checkOut.$d : new Date(checkOut.$d);

    // Calculate count of days
    const count = (checkOutDate - checkInDate) / (24 * 60 * 60 * 1000);
    state.offerState.count = count;

    const pointsArray = Array.isArray(state.offerState.pointsPerNight)
      ? state.offerState.pointsPerNight
      : [];

    const updatedTotalPoints = pointsArray.reduce((acc, p) => {
      const pointDate = new Date(p.date);
      if (pointDate >= checkInDate && pointDate <= checkOutDate) {
        return acc + p.point;
      }
      return acc;
    }, 0);

    state.offerState.total = updatedTotalPoints;
    state.offerState.points = updatedTotalPoints;

    const serviceFee = updatedTotalPoints * 0.05;
    state.offerState.serviceFee = serviceFee;

    // Update the payload for the API request if needed
    setApiPayload((prevApiPayload) => ({
      ...prevApiPayload,
      offerState: {
        ...state.offerState,
        checkIn: checkInDate.toISOString(),
        checkOut: checkOutDate.toISOString(),
        guests: guests,
        rooms: rooms,
        total: updatedTotalPoints,
        points: updatedTotalPoints,
        serviceFee: serviceFee,
      },
    }));
    // Hide the selection modals
    setIsSelectVisible({
      selectData: false,
      selectGuest: false,
    });
  };

  const handleCheckInChange = (newValue) => {
    setCheckIn(newValue);
  };
  const handleCheckOutChange = (newValue) => {
    setCheckOut(newValue);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value; // Initialize formatted value

    switch (name) {
      case "cardNumber":
        formattedValue = value.replace(/\D/g, "").slice(0, 16);
        setCardNumber(formattedValue);
        break;
      case "cardName":
        if (/^[a-zA-Z\s]*$/.test(value)) {
          // Check if value contains only letters and spaces
          setCardName(value);
        }
        break;
      case "expiryDate":
        // Remove non-numeric characters and format as MM/YY
        formattedValue = value.replace(/\D/g, "");
        if (formattedValue.length > 2) {
          formattedValue = `${formattedValue.slice(
            0,
            2
          )}/${formattedValue.slice(2, 4)}`;
        }
        setExpiryDate(formattedValue);
        break;
        break;
      case "cvv":
        // Remove non-numeric characters and limit to 3 digits
        formattedValue = value.replace(/\D/g, "").slice(0, 3);
        setCvv(formattedValue);
        break;
      default:
        break;
    }
  };

  const handleAddCard = async () => {
    setLoading(true);
    try {
      await setMutate({
        cardNumber: cardNumber,
        expiryDate: expiryDate,
        cardName: cardName,
        cvv: cvv,
        userId: localStorageData("_id"),
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    passMutate();
  }, []);

  const [averageRating, setAverageRating] = useState(0);

  //calculating average rating and reviews
  useEffect(() => {
    if (
      state?.propertyDetail.reviews &&
      state.propertyDetail.reviews.length > 0
    ) {
      const ratings = state.propertyDetail.reviews.map(
        (review) => review.rating
      );
      const total = ratings.reduce((acc, rating) => acc + rating, 0);
      const average = total / ratings.length;
      setAverageRating(Number(average.toFixed(1)));
    } else {
      setAverageRating(0);
    }
  }, [state?.propertyDetail.reviews]);

  return (
    <>
      <Container maxWidth="xl">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item lg={8} xs={12}>
              <div className="w-full ">
                <div className="flex h-24 item-center">
                  <div className="flex items-center ">
                    <i
                      className="cursor-pointer fas fa-angle-left"
                      onClick={handleClick}
                    ></i>
                    <h4 className="ml-6 text-2xl font-semibold">
                      Confirm And Pay
                    </h4>
                  </div>
                </div>

                <div className="mt-4">
                  <h4 className="mb-2 ml-1 text-lg font-semibold">
                    Stay Duration
                  </h4>
                  <div className="flex items-center justify-between ">
                    <h4 className="ml-1 font-semibold text-md">Dates</h4>
                    {isSelectVisible.selectData ? (
                      <button
                        onClick={handleUpdateClick}
                        className="text-color-yellow"
                      >
                        Update
                      </button>
                    ) : (
                      <ButtonWithoutBackground
                        onClick={() => handleButtonClick("selectData")}
                        text="Edit"
                      />
                    )}
                  </div>

                  {isSelectVisible.selectData ? (
                    "Selected Data"
                  ) : (
                    <p className="ml-1 text-sm">
                      {formattedCheckInDate} - {formattedCheckOutDate}
                    </p>
                  )}
                  {isSelectVisible.selectData && (
                    <div className="flex w-full border-2 border-gray-400 rounded-t">
                      <div className="p-2 border-r-2 border-gray-400">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Basic example"
                            value={checkIn.$d}
                            dateFormat="LLL"
                            onChange={handleCheckInChange}
                            minDate={currentDate.toDate()}
                            shouldDisableDate={(date) =>
                              state.propertyDetail.bookDates.includes(
                                date.toISOString()
                              )
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                              params,
                            }) => (
                              <div className="flex items-center ">
                                <input
                                  className="w-24 text-black outline-0"
                                  ref={inputRef}
                                  {...inputProps}
                                  placeholder="Check-in"
                                  {...params}
                                />
                                {InputProps?.endAdornment}
                              </div>
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="p-2">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Basic example"
                            value={checkOut.$d}
                            onChange={handleCheckOutChange}
                            minDate={currentDate.toDate()}
                            shouldDisableDate={(date) =>
                              state.propertyDetail.bookDates.includes(
                                date.toISOString()
                              )
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                              params,
                            }) => (
                              <div className="flex items-center">
                                <input
                                  className="w-24 text-black outline-0"
                                  ref={inputRef}
                                  {...inputProps}
                                  placeholder="Check-out"
                                  {...params}
                                />
                                {InputProps?.endAdornment}
                              </div>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  <div className="flex items-center justify-between">
                    <h4 className="ml-1 font-semibold text-md">
                      Guests & Rooms
                    </h4>
                    {isSelectVisible.selectGuest ? (
                      <button
                        onClick={handleUpdateClick}
                        className="text-color-yellow"
                      >
                        Update
                      </button>
                    ) : (
                      <ButtonWithoutBackground
                        onClick={() => handleButtonClick("selectGuest")}
                        text="Edit"
                      />
                    )}
                  </div>

                  <p className="ml-1 text-sm">
                    {isSelectVisible.selectGuest
                      ? "Selected option"
                      : `${state?.offerState?.guest} guests & ${state?.offerState?.room} rooms`}
                  </p>
                  <div className="w-full my-3">
                    {isSelectVisible.selectGuest && (
                      <GuestAndRoomSelector
                        handleGuestChange={handleGuestChange}
                        handleRoomChange={handleRoomChange}
                        guests={guests}
                        rooms={rooms}
                      />
                    )}
                  </div>
                </div>



                <hr className="my-6 border-[#C1E1C2] " />

                <div className="">
                  <h4 className="mt-2 mb-2 text-lg font-semibold ">Payment</h4>
                  <div className="w-[40%]">
                    {existCardNumber && (
                      <PaymentCard
                        name="VISA Master Card"
                        icon={visaIcon}
                        number={existCardNumber}
                        verification="verified"
                      />
                    )}
                  </div>
                  <div className="mt-4">
                    {!showAddCard && (
                      <i
                        className="fa-solid fa-plus"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowAddCard(true)}
                      >
                        add new card
                      </i>
                    )}
                  </div>
                  {showAddCard && (
                    <div className="p-2 border rounded-md border-[#C1E1C2]">
                      <div className="flex">
                        <div className="w-[50%] ">
                          <h4 className="mt-2 mb-2 text-lg font-semibold ">
                            Credit/Debit Card
                          </h4>
                        </div>
                        <div className="w-[50%] flex justify-end">
                          <i
                            className="fa-solid fa-x"
                            onClick={() => setShowAddCard(false)}
                          ></i>
                        </div>
                      </div>
                      <hr className="my-2 border-[#C1E1C2] " />
                      <div className="flex flex-wrap gap-y-2 items-center">
                        <img
                          src={require("assets/img/visa.png")}
                          alt=""
                          className="w-16 mx-2"
                        />
                        <div className="border border-[#C1E1C2] rounded-md">
                          <img
                            src={require("assets/img/paypal.png")}
                            alt=""
                            className="py-1 mx-3"
                          />
                        </div>
                        <div className="border border-[#C1E1C2] rounded-md mx-2">
                          <img
                            src={require("assets/img/pay.png")}
                            alt=""
                            className="py-1 mx-2"
                          />
                        </div>

                        <img
                          src={require("assets/img/point.png")}
                          alt=""
                          className="h-10 rounded "
                        />
                        <div className="py-2">
                          <button className="px-2 mx-2 text-sm border rounded-md  h-9 border-[#C1E1C2]">
                            {" "}
                            Other
                          </button>
                        </div>
                      </div>
                      <div>
                        <img
                          src={require("assets/img/all.png")}
                          alt=""
                          className="p-2 "
                        />
                      </div>
                      <div className="flex items-center gap-2 mt-2">
                        <div className="w-1/2">
                          <label htmlFor="">Card Number*</label>
                          <input
                            type="text"
                            name="cardNumber"
                            className="input-styl placeholder:text-color-grey"
                            placeholder="Card Number"
                            value={cardNumber}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="w-1/2">
                          <label htmlFor="">Name on Card*</label>
                          <input
                            type="text"
                            name="cardName"
                            value={cardName}
                            onChange={handleInputChange}
                            className="input-styl placeholder:text-color-grey"
                            placeholder="Name on card"
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-2 mt-2">
                        <div className="w-1/2">
                          <label
                            htmlFor=""
                            className="text-[15px] md:text-[17px]"
                          >
                            Expiration Date*
                          </label>
                          <input
                            type="text"
                            name="expiryDate"
                            className="input-styl placeholder:text-color-grey"
                            placeholder="MM/YY"
                            value={expiryDate}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="w-1/2">
                          <label htmlFor="">CVC*</label>
                          <input
                            type="text"
                            className="input-styl placeholder:text-color-grey"
                            placeholder="CVC"
                            name="cvv"
                            value={cvv}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <button
                        className="btn-styl-blue"
                        onClick={handleAddCard}
                        disabled={LoadingCardAdd}
                      >
                        {LoadingCardAdd ? "Adding Card..." : "Add Card"}
                      </button>
                      <div className="flex p-2 rounded-md bg-color-blue/25 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="22"
                          viewBox="0 0 19 22"
                          fill="none"
                        >
                          <path
                            d="M17.7793 6.25855C17.7692 6.03223 17.7627 5.80576 17.7596 5.57923C17.747 4.72827 17.0775 4.03382 16.2278 3.98636C13.629 3.84118 11.5966 2.97675 9.87985 1.30346C9.59258 1.04013 9.16171 1.04013 8.87444 1.30346C7.15771 2.97675 5.12529 3.84118 2.5265 3.98636C1.67679 4.03382 1.00732 4.72827 0.994682 5.57919C0.99162 5.80573 0.985065 6.03221 0.975023 6.25855C0.879515 11.2735 0.736337 18.1465 9.07763 21.0551C9.11757 21.069 9.15843 21.0801 9.19991 21.0884L9.20032 21.0885C9.31705 21.1118 9.43724 21.1118 9.55397 21.0885C9.59561 21.0802 9.63692 21.0689 9.67703 21.0549C17.9941 18.1464 17.8748 11.2972 17.7793 6.25855Z"
                            stroke="black"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M14.0487 11.106C14.0487 13.6862 11.9571 15.7778 9.37689 15.7778C6.79673 15.7778 4.70508 13.6862 4.70508 11.106C4.70508 8.52577 6.79673 6.43412 9.37689 6.43412C11.9571 6.43412 14.0487 8.52577 14.0487 11.106Z"
                            stroke="black"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M11.3398 9.90287L8.43945 12.8032"
                            stroke="black"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                          />
                          <path
                            d="M7.33105 11.6949L8.43941 12.8032"
                            stroke="black"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                          />
                        </svg>
                        <p className="ml-2 text-sm">
                          This transaction is protected with a secure and
                          encrypted payment system by BnByond.
                        </p>
                      </div>
                    </div>
                  )}
                  <hr className="my-6 border-[#C1E1C2] " />
                  <div>
                    <h4 className="mt-2 mb-2 text-lg font-semibold ">
                      Required for your trip
                    </h4>
                    <div className="flex items-center justify-between gap-5">
                      <div>
                        <p className="text-md">Message the Host</p>
                        <p className="text-xs mb-2">
                          Let the Host know why you're traveling and when you'll
                          check in.
                        </p>
                        {tripe.showTextarea && ( // Render textarea only when showTextarea is true
                          <textarea
                            value={tripe.message}
                            onChange={(e) =>
                              setTripe({ ...tripe, message: e.target.value })
                            }
                            placeholder="Type something..."
                            className="w-full p-2 border border-color-grey rounded-xl"
                          />
                        )}
                      </div>
                      <button
                        className="px-4 py-1 text-sm border rounded-full text-color-yellow border-color-yellow"
                        onClick={() => handleAddButtonClick("showTextarea")}
                      >
                        {tripe.showTextarea ? "CLOSE" : "ADD"}
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-3">
                    <div>
                      <p className="text-md">Phone number</p>
                      <p className="text-xs mb-2">
                        Add and confirm your phone number to get trip updates.
                      </p>
                      {tripe.showPhoneField && (
                        <input
                          type="tel"
                          value={tripe.phoneNumber}
                          onChange={(e) =>
                            setTripe({ ...tripe, phoneNumber: e.target.value })
                          }
                          placeholder="Enter phone number..."
                          className="w-full p-2 border border-color-grey rounded-xl"
                        />
                      )}
                    </div>
                    <button
                      className="px-4 py-1 text-sm border rounded-full text-color-yellow border-color-yellow"
                      onClick={() => handleAddButtonClick("showPhoneField")}
                    >
                      {tripe.showPhoneField ? "CLOSE" : "ADD"}
                    </button>
                  </div>

                  <hr className="my-6 border-[#C1E1C2] " />
                  <div>
                    <h4 className="mt-2 mb-2 text-lg font-semibold ">
                      Cancellation policy
                    </h4>
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-xs">
                          Free cancellation within 48 hours. Cancel at least 15 - 30 days before your check-in date for a partial refund.
                        </p>
                        <Link to="/pricing" className="text-sm underline">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr className="my-6 border-[#C1E1C2] " />
                  <div className="my-4">
                    <p className="text-xs">
                      By selecting the button below, I agree to the Host's House
                      Rules, Ground Rules for Guests and BnByond's Refund and
                      cancellation policies.
                    </p>
                    <button
                      className="btn-styl-blue"
                      onClick={onClickConfirm}
                      disabled={LoadingReservationConfirm}
                    >
                      {LoadingReservationConfirm ? "Reserving..." : "reserve"}
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={4} xs={12}>
              {" "}
              <div className="flex mt-2 reserveDetailWrapper">
                {/* Image Areas */}
                <div className="h-full mt-20 ml-4 border border-[#C1E1C2] height-line rounded-xl">
                  <div className="reserveImage ">
                    {state.propertyDetail.pics ? (
                      <img
                        src={state.propertyDetail.pics[0]}
                        className="object-cover w-full h-52 rounded-t-xl "
                        alt=""
                      />
                    ) : (
                      <img
                        src={require("assets/img/background.png")}
                        className="object-cover w-full h-52 rounded-t-xl "
                        alt=""
                      />
                    )}
                  </div>
                  <div className="items-center h-full ">
                    <div className="flex flex-col items-start justify-between propertyName">
                      <p className="flex items-center pl-3 pt-3">
                        {averageRating > 0 ? (
                          <div className="flex items-center gap-2">
                            <Rating
                              initialRating={averageRating}
                              readonly
                              emptySymbol={<FaRegStar color="#d3d3d3" />}
                              halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                              fullSymbol={<FaStar color="#ffd700" />}
                              fractions={2}
                            />
                            <span>({averageRating})</span>
                          </div>
                        ) : (
                          <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                        )}
                      </p>
                      <p className="my-1 ml-3 text-2xl font-semibold">
                        {state.propertyDetail.title}
                      </p>
                    </div>
                    <div className="flex items-center justify-between mt-8 font-semibold propertyName ">
                      <div className="flex items-center gap-x-1">
                        <p className="ml-3 font-md">
                          {state.offerState.count ? state.offerState.count : 0}{" "}
                          nights
                        </p>
                        <button onClick={toggleDropdown}>
                          {isDropdownOpen ? (
                            <MdKeyboardArrowUp className="ml-2 text-black text-xl" />
                          ) : (
                            <MdKeyboardArrowDown className="ml-2 text-black text-xl" />
                          )}
                        </button>
                      </div>
                      <p className="flex items-center mr-3">
                        {" "}
                        {state.offerState.total
                          ? state.offerState.total
                          : 0}{" "}
                        points
                      </p>
                    </div>
                    <div>
                      {isDropdownOpen && (
                        <ul className="pl-4 mt-2">
                          {state.offerState.pointsPerNight
                            .filter((p) => {
                              const pointDate = new Date(p.date);
                              return (
                                pointDate >= new Date(checkIn.$d) &&
                                pointDate <= new Date(checkOut.$d)
                              );
                            })
                            .map((p, index) => (
                              <li key={index} className="text-sm text-gray-600">
                                {moment(new Date(p.date)).format("DD MMMM")} /{" "}
                                {p.point} Points
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>

                    <div className="flex items-center justify-between mt-2 font-semibold propertyName">
                      <p className="ml-3 font-md">BnByond service fee-5%</p>
                      <p className="flex items-center mr-3">
                        {" "}
                        ${state.offerState.serviceFee}
                      </p>
                    </div>
                    <hr className="mt-6 border-[#C1E1C2] " />
                    <div className="flex items-center justify-between mt-2 font-semibold propertyName">
                      <p className="ml-3 font-md">Total Points</p>
                      <p className="flex items-center mr-3">
                        {" "}
                        {state.offerState.points} points
                      </p>
                    </div>
                    <div className="flex items-center justify-between mt-2 font-semibold propertyName">
                      <p className="ml-3 font-md ">Service Fee</p>
                      <p className="flex items-center mr-3 ">
                        {" "}
                        ${state.offerState.serviceFee}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <br />
    </>
  );
}

export default ReserveDetails;
