// import Grid from '@mui/material/Grid';
// import SelectBox from 'components/Select/SelectBox';
// import ListingColor from 'components/Cards/ListingColor';

// export default function PropertyType({ state, setState }) {

//   const data = [
//     { value: 'house', image: require('assets/img/houseImg.png'), text: 'House' },
//     { value: 'apartment', image: require('assets/img/Apartment.png'), text: 'Apartment' },
//     { value: 'loft', image: require('assets/img/Loft.png'), text: 'Loft' },
//     { value: 'boat', image: require('assets/img/Boat.png'), text: 'Boat' },
//     { value: 'unique', image: require('assets/img/Unique.png'), text: 'Unique' },
//     { value: 'camper', image: require('assets/img/Camper.png'), text: 'Camper' },
//     { value: 'cabin', image: require('assets/img/Cabin.png'), text: 'Cabin' },
//   ];

//   return (
//     <div className=''>
//       <Grid container>
//         <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "80vh", marginTop: '10vh' }}>
//           <ListingColor
//             bg='bg-color-darknavyblue'
//             text='What kind of Place or property is it?'
//             color='text-[#FFFFFF]'
//             btn='step 1'

//           />
//         </Grid>
//         <Grid item lg={7} md={6} sm={12} xs={12} display={'flex'} gap={'2'} justifyContent={'center'} alignItems={'center'}
//           className='propertyType_responsiveness'
//         >
//           <div className='flex items-center justify-center xs:mb-36 xs:mt-5 md:mb-36 md:mt-20 xl:mb-20 lg:mb-20 lg:mt-0 py-10'>
//             <SelectBox
//               data={data}
//               propertyType={state.propertyType}
//               onSelect={(value) =>
//                 setState((prevState) => ({ ...prevState, propertyType: value }))
//               }
//             />
//           </div>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }



import React from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import SelectBox from "components/Select/SelectBox";
import ListingColor from "components/Cards/ListingColor";
import { updateFormData } from "redux/store/actions/userAction/formAction";

export default function PropertyType() {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.form); // Fetch Redux state

  const data = [
    { value: "house", image: require("assets/img/houseImg.png"), text: "House" },
    { value: "apartment", image: require("assets/img/Apartment.png"), text: "Apartment" },
    { value: "loft", image: require("assets/img/Loft.png"), text: "Loft" },
    { value: "boat", image: require("assets/img/Boat.png"), text: "Boat" },
    { value: "unique", image: require("assets/img/Unique.png"), text: "Unique" },
    { value: "camper", image: require("assets/img/Camper.png"), text: "Camper" },
    { value: "cabin", image: require("assets/img/Cabin.png"), text: "Cabin" },
  ];

  const handleSelect = (value) => {
    dispatch(updateFormData({ propertyType: value })); // Update Redux state
  };

  return (
    <div className="">
      <Grid container>
        <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "80vh", marginTop: "10vh" }}>
          <ListingColor
            bg="bg-color-darknavyblue"
            text="What kind of Place or property is it?"
            color="text-[#FFFFFF]"
            btn="Step 1"
          />
        </Grid>
        <Grid
          item
          lg={7}
          md={6}
          sm={12}
          xs={12}
          display={"flex"}
          gap={"2"}
          justifyContent={"center"}
          alignItems={"center"}
          className="propertyType_responsiveness"
        >
          <div className="flex items-center justify-center xs:mb-36 xs:mt-5 md:mb-36 md:mt-20 xl:mb-20 lg:mb-20 lg:mt-0 py-10">
            <SelectBox
              data={data}
              propertyType={formData.propertyType} // Pass current property type from Redux
              onSelect={handleSelect} // Handle selection
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
