import React from 'react'
import { Container, Rating } from "@mui/material";
import bookingpic from '../../../assets/img/bookingpic.png'
import Ellipse77 from "../../../assets/img/Ellipse77.png";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlineDislike } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import { IoIosArrowBack } from 'react-icons/io';

const ReviewResponse = ({ setSteps }) => {
  return (
    <>
      <section className="bg-[#f8f7fa]">
        <Container maxWidth="xl">
          <button
            className="flex justify-center items-center text-blue-500 font-medium mb-4 "
            onClick={() => setSteps("ReviewsFeedback")}
          >
            <IoIosArrowBack /> Back
          </button>
          <div>
            <h2 className='text-black font-Avenir text-3xl font-bold py-6'>
              Review Response
            </h2>
            <div className='flex lg:flex-row flex-col gap-6 w-full'>
              <div className='lg:w-[37%] xl:w-[30%] w-full  h-full border border-[#C1E1C2] bg-[#FFF] rounded-2xl overflow-auto flex flex-col gap-4'>
                <img src={bookingpic} alt='' className='w-full' />
                <div className='flex gap-2 w-full '>
                  <p className='font-medium text-lg ml-4'>Stayed at:</p>
                  <span className='font-medium text-lg text-[#AFB5C1]'>B2b Suites</span>
                </div>
                <div className='flex gap-2 w-full'>
                  <p className='font-medium text-lg ml-4'>Location:</p>
                  <span className='font-medium text-lg text-[#AFB5C1]'>Harbourview, Dartmouth, Nova Scotia, Cana...</span>
                </div>
                <div className='flex justify-center items-center mb-3'>
                  <button className='bg-white  border border-[#E8AD21] w-[230px] h-[45px] rounded-3xl text-[#E8AD21] text-base font-bold uppercase '>See Detail</button>
                </div>
              </div>
              <div className='lg:w-[63%] xl:w-[70%] w-full h-full border border-[#C1E1C2] bg-[#FFF] rounded-2xl px-5 flex flex-col gap-4 pt-10 pb-8'>
                <div className="flex justify-between">
                  <div className="flex flex-col items-start">
                    <div className="flex items-center">
                      <img src={Ellipse77} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                      <div className="flex flex-col">
                        <span className="font-Avenir font-medium text-base leading-6 text-black">
                          Gustavo Donin
                        </span>
                        <div className="flex items-center">
                          <span className="text-sm pr-2 m-auto">5.0</span>
                          <Rating name=" size-small" readOnly defaultValue={5} size="small" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-2 font-medium text-sm leading-5 text-black">
                    <BsThreeDotsVertical />
                  </div>
                </div>
                <div>
                  <p className='text-custom-paragraph font-Lato text-base font-normal leading-relaxed text-[#4B4B4B]'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna a
                  </p>
                </div>
                <div className=' w-full h-[149px] flex-shrink-0 pb-8 md:pb-4'>
                  <label htmlFor='name' className='block font-semibold text-base text-black font-Avenir text-transform-capitalize'>
                    Describe Your Experience (required)
                  </label>
                  <textarea
                    type='text'
                    id='name'
                    name='name'
                    placeholder='What was it like to host this guest?'
                    className='w-full h-full border border-[#C1E1C2] rounded-lg p-2 bg-white'
                  />
                  <span className='text-[#646464]'>0/4000</span>
                </div>
                <div>
                  <p className='text-black font-Avenir text-xl font-medium mt-10'>
                    Would you recommend this guest?
                  </p>
                  <p className='text-black font-Avenir text-base font-light'>
                    Your answer will not be posted on your profile or your guest’s profile.
                  </p>
                </div>
                <div className='flex gap-8'>
                  <div className='flex gap-2 justify-center items-center'>
                    <AiOutlineDislike className='w-[38px] h-[40px]' />
                    <p>No</p>
                  </div>
                  <div className='flex gap-2 justify-center items-center'>
                    <AiOutlineLike className='w-[38px] h-[40px]' />
                    <p>Yes!</p>
                  </div>
                </div>
                <div className='pt-2'>
                  <button className='bg-[#2459BF] rounded-full py-2 px-4 text-white'>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default ReviewResponse