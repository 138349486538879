import icon3 from "assets/img/filter.png"
import icon4 from "assets/img/searchbar.png"
import icon5 from "assets/img/export.png"
import React, { useState, useEffect } from "react";
import Pagination from 'components/Pagination/Pagination'
import Modal from 'components/Modal/Modal';
import {
    FaCcVisa,
    FaCcMastercard,
    FaCcAmex,
    FaCcDiscover,
    FaCcDinersClub,
    FaCcJcb
} from 'react-icons/fa';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import userServices from "services/httpService/userAuth/userServices";

const FinancialTransactions = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [search, setSearch] = useState('');
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedTransactions, setSelectedTransactions] = useState([]);

    const getTransactions = async () => {
        try {
            setIsLoading(true);
            let res = await userServices.getStripeTransactions('/stripe/transactions');
            setTransactions(res.data);
        } catch (error) {
            console.error('Failed to fetch transaction:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getTransactions();
    }, []);

    const filteredData = transactions.filter(item =>
        item.transactionId.toLowerCase().includes(search.toLowerCase())
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentPageData = filteredData.slice(firstIndex, lastIndex);

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const openModal = (transaction) => {
        setSelectedTransaction(transaction);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedTransaction(null);
    };

    function CardBrandIcon({ brand }) {
        switch (brand.toLowerCase()) {
            case 'visa':
                return <FaCcVisa className="text-blue-600 w-14 h-10" />;
            case 'mastercard':
                return <FaCcMastercard className="text-red-600 w-14 h-10" />;
            case 'amex':
            case 'american express':
                return <FaCcAmex className="text-blue-800 w-14 h-10" />;
            case 'discover':
                return <FaCcDiscover className="text-orange-500 w-14 h-10" />;
            case 'diners':
            case 'diners club':
                return <FaCcDinersClub className="text-blue-500 w-14 h-10" />;
            case 'jcb':
                return <FaCcJcb className="text-green-600 w-14 h-10" />;
            default:
                return <span>{brand}</span>;
        }
    }

    const handleSelectAll = () => {
        const newIsAllSelected = !isAllSelected;
        setIsAllSelected(newIsAllSelected);

        const updatedData = transactions.map((transaction) => ({
            ...transaction,
            isSelected: newIsAllSelected,
        }));
        setTransactions(updatedData);

        if (newIsAllSelected) {
            setSelectedTransactions(updatedData.map((transaction) => transaction.transactionId));
        } else {
            setSelectedTransactions([]);
        }
    };

    useEffect(() => {
        setIsAllSelected(
            selectedTransactions.length === transactions.length && transactions.length > 0
        );
    }, [selectedTransactions, transactions]);
    const handleExportClick = () => {
        if (selectedTransactions.length === 0) {
            toast.warning("Please select transactions to export");
            return;
        }

        document.getElementById("test-table-xls-button").click();
    };

    return (
        <div>
            <div className='flex md:flex-row flex-col justify-between items-center px-5  py-3 '>
                <div>
                    <span>
                        <h1 className='font-bold text-[24px]'>Financial Transactions</h1>
                    </span>
                </div>
            </div>

            <div className='mx-5 my-3 flex flex-col mt-6 rounded-tl-[15px] rounded-tr-[15px] border border-gray-300 bg-white '>
                <div className='w-full md:p-4 p-2 flex justify-between gap-2 bg-white  rounded-t-xl items-center '>
                    <div className=''>
                        <p className='font-medium sm:text-lg text-sm'>Transactions</p>
                    </div>
                    <div className='flex  space-x-2'>
                        <div className='flex justify-between sm:p-4 p-2 items-center  w-[100px] h-[45px]  border border-gray-300 rounded-md'>
                            <div>
                                <img src={icon3} />
                            </div>
                            <div>
                                <p className='font-normal text-xs text-gray-600'>Fillter</p>
                            </div>
                        </div>

                        <button
                            onClick={handleExportClick}
                            className="flex justify-between sm:p-4 p-2 items-center w-full sm:w-[100px] h-[45px] border border-gray-300 rounded-md"
                        >
                            <div className="w-4 h-4">
                                <img src={icon5} alt="Export Icon" className="w-full h-full" />
                            </div>
                            <div>
                                <p className='font-normal text-xs text-gray-600'>Export</p>
                            </div>
                        </button>
                    </div>
                    <div className="relative md:w-64  ">
                        <input
                            type="text"
                            placeholder='Search'
                            className='border border-gray-400 sm:py-3 sm:px-4 py-3 text-xs rounded-md w-full'
                        />
                        <img
                            src={icon4}
                            alt="search icon"
                            className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
                        />
                    </div>
                </div>
            </div>

            <div className='overflow-auto'>
                <table className="table-auto w-full bg-white ">
                    <thead>
                        <tr className='bg-[#fafbff]'>
                            <th className="px-4 py-2 border-t border-b text-left">
                                <input
                                    type="checkbox"
                                    className="mr-2 border border-gray-500"
                                    checked={isAllSelected}
                                    onChange={handleSelectAll}
                                />
                                Transaction ID
                            </th>
                            <th className="px-4 py-2 text-left border-t border-b">Date and Time</th>
                            <th className="px-4 py-2 border-t  text-left border-b">Amount</th>
                            <th className="px-4 py-2 border-t text-left border-b">Payment</th>
                            <th className="px-4 py-2 border-t text-left border-b">Source</th>
                            <th className="px-4 py-2 border-t text-left border-b">Description</th>
                            <th className="px-4 py-2 border-t text-left border-b">status</th>

                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan='7' className="text-center py-10">
                                    <CircularProgress />
                                </td>
                            </tr>
                        ) : currentPageData.length === 0 ? (
                            <tr>
                                <td colSpan='7' className="text-center py-10 text-gray-500 font-medium">
                                    No Data Found
                                </td>
                            </tr>
                        ) : (
                            currentPageData.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <input type="checkbox" className="mr-2" />
                                            <span className="font-bold text-sm">
                                                {item.transactionId.substring(0, 12)}...
                                            </span>
                                        </td>

                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <div className='flex flex-col'>
                                                <span className='font-bold'>{new Date(item.date).toLocaleDateString()}</span>
                                                <span className="font-medium text-xs leading-4 text-gray-400">{new Date(item.date).toLocaleTimeString()}</span>
                                            </div>
                                        </td>

                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <span className="font-bold">$ {item.amount}</span>
                                        </td>
                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <div className='flex gap-2 items-center'>
                                                <CardBrandIcon brand={item.cardBrand} />
                                            </div>
                                        </td>

                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <span className='font-bold text-gray-500'>{item.description}</span>
                                        </td>
                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <div className='flex flex-col'>
                                                <span
                                                    className="text-blue-500 cursor-pointer"
                                                    onClick={() => openModal(item)}
                                                >
                                                    View Details
                                                </span>                                                </div>
                                        </td>
                                        <td className="px-4 py-2 text-green-500 border-t border-b font-medium text-sm leading-5 ">
                                            {item.status}
                                        </td>
                                    </tr>
                                )
                            }))}
                    </tbody>
                </table>
                <table id="selected-users-table" style={{ display: 'none' }}>
                    <thead>
                        <tr>
                            <th>Transaction ID</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>Amount</th>
                            <th>Source</th>
                            <th>Date and Time</th>
                            <th>Card Brand</th>
                            <th>Card No</th>
                            <th>Card Expiry Month</th>
                            <th>Payment Method</th>
                            <th>Receipt</th>
                            <th>Transaction Type</th>
                            <th>status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.transactionId}</td>
                                    <td> {item.name}</td>
                                    <td> {item.email}</td>
                                    <td>$ {item.amount}</td>
                                    <td>{item.description}</td>
                                    <td>{new Date(item.date).toLocaleDateString() + " " + new Date(item.date).toLocaleTimeString()}</td>
                                    <td> {item.cardBrand}</td>
                                    <td> ************{item.last4}</td>
                                    <td> {item.expMonth + " " + item.expYear}</td>
                                    <td> {item.paymentMethod}</td>
                                    <td> {<a href={item.receiptUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">View Receipt</a>}</td>
                                    <td> {item.transactionType}</td>
                                    <td> {item.status}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

            <Modal isOpen={modalOpen} onClose={closeModal}>
                <div className="px-2 md:px-6 py-2 bg-white rounded-lg shadow-lg">
                    <h2 className="text-2xl font-semibold mb-4">Transaction Details</h2>

                    {selectedTransaction && (
                        <div className="space-y-2">
                            <div className="flex flex-col sm:flex-row justify-between">
                                <span className="font-medium text-gray-600">Transaction ID:</span>
                                <span className="font-semibold">{selectedTransaction.transactionId}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Name:</span>
                                <span className="font-semibold">{selectedTransaction.name}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Email:</span>
                                <span className="text-blue-500 underline">{selectedTransaction.email}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Amount:</span>
                                <span className="font-semibold">${selectedTransaction.amount}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Status:</span>
                                <span className="text-green-500 font-semibold">{selectedTransaction.status}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Description:</span>
                                <span className="text-gray-800">{selectedTransaction.description}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Date:</span>
                                <span className="text-gray-800">{new Date(selectedTransaction.date).toLocaleDateString()}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Time:</span>
                                <span className="text-gray-800">{new Date(selectedTransaction.date).toLocaleTimeString()}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Card Brand:</span>
                                <span className="text-gray-800 capitalize">{selectedTransaction.cardBrand}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Card Number:</span>
                                <span className="text-gray-800">************{selectedTransaction.last4}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Expiration:</span>
                                <span className="text-gray-800">{`${selectedTransaction.expMonth}/${selectedTransaction.expYear}`}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Payment Method:</span>
                                <span className="text-gray-800 capitalize">{selectedTransaction.paymentMethod}</span>
                            </div>

                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Receipt:</span>
                                <a href={selectedTransaction.receiptUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">View Receipt</a>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-medium text-gray-600">Transaction Type:</span>
                                <span className="text-gray-800 capitalize">{selectedTransaction.transactionType}</span>
                            </div>
                        </div>
                    )}
                    <div className='flex justify-center items-center'>
                        <button
                            className="mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>

                </div>
            </Modal>

        </div>

    )
}

export default FinancialTransactions;