import React, { useEffect, useRef, useState } from "react";
import { ButtonFilled } from "common/buttons/buttonFilled";
import { TextEditor } from "common/textEditor";
import { FileUploader } from "react-drag-drop-files";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";
import userServices from "services/httpService/userAuth/userServices";

const BlogPost = ({ postId, onSuccess, onCancel, buttonText = "Post" }) => {
  const editorRef = useRef(null);
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null); // State for image preview
  const [description, setDescription] = useState("");
  const [isFetchingPost, setIsFetchingPost] = useState(false);
  const [isEditorReady, setIsEditorReady] = useState(false);

  // Function to handle file input changes
  const handleChange = (file) => {

    setFile(file);
    setPreview(URL.createObjectURL(file)); // Set preview URL
  };

  // Fetches blog post data if postId is provided
  useEffect(() => {
    if (postId) {
      setIsFetchingPost(true);
      userServices
        .commonGetService(`post/blog/${postId}`)
        .then((response) => {
          const data = response?.data?.data;
          setTitle(data?.title);
          setDescription(data?.description);
          setPreview(data?.pic); // Set preview URL from fetched data
          setIsEditorReady(true);
          setIsFetchingPost(false);
        })
        .catch((error) => {
          console.error("Error fetching post:", error);
          setIsFetchingPost(false);
        });
    }
  }, [postId]);

  // Mutation hook to handle creating or updating a blog post
  const { mutate: handleBlogPost, isLoading } = useMutation(

    (formData) => {
      if (postId) {
        return userServices.commonPutService(`post/blogupdate/${postId}`, formData);
      } else {
        return userServices.Blogpost("post/blog", formData); // Create a new blog post
      }
    },
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: () => {
        setTitle("");
        setFile(null);
        setPreview(null);
        if (editorRef.current) {
          editorRef.current.setContent("");
        }
        toast.success(`Blog post ${postId ? "updated" : "created"} successfully`);
        onSuccess();
      },
    }
  );

  // Function to prepare and submit the blog post form data
  const postBlog = () => {
    const formData = new FormData();
    if (!title || (!file && !postId)) {
      toast.error("Please fill the form");
      return;
    }
    formData.append("title", title);
    if (file) {
      formData.append("pic", file);
    }
    // else if (postId && file) {
    //   formData.append("pic", file);
    // }
    if (editorRef.current) {
      formData.append("description", editorRef.current.getContent());
    }
    handleBlogPost(formData); // Trigger the mutation to create or update the blog post
  };

  if (isFetchingPost) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex justify-center mb-10 lg:w-full">
      <div className="w-[80%] mt-8">
        <p className="text-4xl font-medium leading-relaxed font-avenir">
          {postId ? "Edit Blog Post" : "Create Blog Post"}
        </p>
        <div className="flex gap-10">
          <div className="flex flex-col gap-8">
            <div className="w-full pt-4">
              <FileUploader handleChange={handleChange} name="file" value={file} types={fileTypes} />
            </div>
            <div>
              <input
                className="w-full h-10 sm:w-[511.52px] sm:h-[50px] rounded-[10px] mt-[20px] border-2 border-[#AFB5C1] font-lato text-base font-medium pl-5"
                placeholder="Enter Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div>
            {preview && (
              <div className="mt-4">
                <img src={preview} alt="Preview" className="w-36 h-36 max-w-xs object-cover" />
              </div>
            )}
          </div>
        </div>
        <div className="h-[272px] w-full rounded-[10px] mt-[24px]">
          <TextEditor editorRef={editorRef} log={description} />
        </div>
        <div className="flex items-center justify-center pt-10">
          <div className="w-[100px] flex gap-3">
            <ButtonFilled text={isLoading ? "Loading.." : buttonText} disabled={isLoading} onClick={postBlog} />
            <ButtonFilled text="Cancel" onClick={onCancel} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
