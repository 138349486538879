// import React, { useState, useEffect } from 'react';
// import Grid from '@mui/material/Grid';
// import ListingColor from 'components/Cards/ListingColor';
// import MultiSelect from 'components/Select/MultiSelect';


// export default function AddAmenities({ state, setState, AddAmenities }) {
//   const [data, setData] = useState(AddAmenities);

//   // Toggles the selection of an amenity: adds it if not present, removes it if already selected
//   const onSelect = (selectedAmenity) => {
//     setState((prevState) => {
//       const existingIndex = prevState.amenities.findIndex(
//         (amenity) => amenity.id === selectedAmenity.id
//       );

//       if (existingIndex !== -1) {
//         // Amenity with the same ID exists, remove it
//         const updatedAmenities = [...prevState.amenities];
//         updatedAmenities.splice(existingIndex, 1);

//         return {
//           ...prevState,
//           amenities: updatedAmenities,
//         };
//       } else {
//         // Amenity with the ID doesn't exist, add it
//         return {
//           ...prevState,
//           amenities: [...prevState.amenities, selectedAmenity],
//         };
//       }
//     });
//   };

//   return (
//     <div className='z-[10px]'>
//       <Grid container>
//         {/* lg 5 */}
//         <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "85vh", marginTop: '10vh' }}>
//           <ListingColor
//             bg='bg-color-darknavyblue'
//             text='What cool features or amenities should your fellow members know about?'
//             color='text-[#FFFFFF]'
//             btn='step 4'
//           />
//         </Grid>
//         <Grid item lg={7} md={6} sm={13} xs={13} display={'flex'} justifyContent={'center'} alignItems={'center'}
//           className='addAmeneties_responsiveness'
//         >
//           <div className='grid grid-cols-1 gap-8 lg:grid-cols-3 md:grid-cols-2 xs:mb-36 xs:mt-5 md:mb-36 md:mt-20 lg:mb-20 lg:mt-0'>
//             {data.map((item) => (
//               <MultiSelect
//                 data={item}
//                 withImg={true}
//                 allData={data}
//                 onSelect={onSelect}
//               />
//             ))}
//           </div>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }



import React from "react";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { updateFormData } from "redux/store/actions/userAction/formAction";
import ListingColor from "components/Cards/ListingColor";
import MultiSelect from "components/Select/MultiSelect";
import { AmenitiesData } from "data/AmenitiesData"; 

export default function AddAmenities() {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.form); // Fetch Redux state

  // Handle amenity selection: toggle between adding and removing
  const handleSelect = (selectedAmenity) => {
    const existingIndex = formData.amenities.findIndex(
      (amenity) => amenity.id === selectedAmenity.id
    );

    let updatedAmenities;
    if (existingIndex !== -1) {
      // If amenity exists, remove it
      updatedAmenities = [...formData.amenities];
      updatedAmenities.splice(existingIndex, 1);
    } else {
      // If amenity does not exist, add it
      updatedAmenities = [...formData.amenities, selectedAmenity];
    }

    dispatch(updateFormData({ amenities: updatedAmenities })); 
  };


  // const handleSelect = (selectedAmenity) => {
  //   const existingIndex = formData.amenities.findIndex(
  //     (amenity) => amenity.id === selectedAmenity.id
  //   );
  
  //   let updatedAmenities;
  //   if (existingIndex !== -1) {
  //     // If amenity exists, remove it and set status to false
  //     updatedAmenities = [...formData.amenities];
  //     updatedAmenities.splice(existingIndex, 1);
  //     // Also update the status of the amenity in AmenitiesData (if needed globally)
  //     selectedAmenity.status = false;
  //   } else {
  //     // If amenity does not exist, add it and set status to true
  //     updatedAmenities = [
  //       ...formData.amenities,
  //       { ...selectedAmenity, status: true },
  //     ];
  //   }
  
  //   // Dispatch the updated list to Redux
  //   dispatch(updateFormData({ amenities: updatedAmenities }));
  // };
  

  return (
    <div className="z-[10px]">
      <Grid container>
        <Grid
          item
          lg={5}
          md={6}
          sm={12}
          xs={12}
          style={{ height: "85vh", marginTop: "10vh" }}
        >
          <ListingColor
            bg="bg-color-darknavyblue"
            text="What cool features or amenities should your fellow members know about?"
            color="text-[#FFFFFF]"
            btn="Step 4"
          />
        </Grid>
        <Grid
          item
          lg={7}
          md={6}
          sm={13}
          xs={13}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          className="addAmeneties_responsiveness"
        >
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 md:grid-cols-2 xs:mb-36 xs:mt-5 md:mb-36 md:mt-20 lg:mb-20 lg:mt-0">
            {AmenitiesData.map((item) => (
              <MultiSelect
                key={item.id}
                data={item}
                withImg={true}
                onSelect={handleSelect}
                selectedAmenities={formData.amenities} // Pass selected amenities
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
