import WishlistButton from "common/buttons/WishlistButton/WishlistButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageData } from "services/auth/localStorageData";
import Rating from "react-rating";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import userServices from "services/httpService/userAuth/userServices";
import AdminReservationDetail from "pages/admin/ReservationDetails/AdminReservationDetail";

const UpComming = ({ upcoming }) => {
  const navigate = useNavigate();

  const [reservation, setReservation] = useState([]);
  const [ReservationDetail, setReservationDetail] = useState(null);
  const [showReservationDetail, setShowReservationDetail] = useState(false);

  const calculateAverageRating = (reviews) => {
    if (reviews && reviews.length > 0) {
      const total = reviews.reduce((acc, review) => acc + review.rating, 0);
      return Number((total / reviews.length).toFixed(1));
    }
    return 0;
  };

  const getReservation = async () => {
    try {
      let res = await userServices.commonGetService(
        `/reservation/reservations/${localStorageData("_id")}?role=admin`
      );
      setReservation(res.data); 
    } catch (error) {
      console.error("Error fetching reservations:", error);
    }
  };

  useEffect(() => {
    getReservation();
  }, []);

  const handleReservationById = (_id) => {
    const detail = reservation.find(
      (res) => res.property_id && res.property_id._id === _id
    );
    if (detail) {
      setReservationDetail(detail);
      setShowReservationDetail(true);
    } else {
      console.error(`Reservation with property ID ${_id} not found.`);
    }
  };

  if (showReservationDetail) {
    return (
      <AdminReservationDetail
        ReservationDetail={ReservationDetail}
        onBack={() => setShowReservationDetail(false)}
      />
    );
  }

  return (
    <>
      <div className="md:flex flex-wrap gap-2">
        {upcoming.map((items, index) => {
          const favourite =
            items?.favouriteList &&
            items.favouriteList.find(
              (user) => user.userId === localStorageData("_id")
            );

          const averageRating = calculateAverageRating(
            items.property_id?.reviews || []
          );

          return (
            <div
              key={items._id || index} 
              className="md:flex gap-2 w-full md:w-[49%] my-4 border lg:h-[270px] xl:h-64 border-color-grey rounded-md overflow-hidden"
            >
              <div className="w-full flex justify-between p-2">
                <div>
                  {averageRating > 0 ? (
                    <div className="flex items-center">
                      <Rating
                        initialRating={averageRating}
                        readonly
                        emptySymbol={<FaRegStar color="#d3d3d3" />}
                        halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                        fullSymbol={<FaStar color="#ffd700" />}
                        fractions={2}
                      />
                      <span>({averageRating})</span>
                    </div>
                  ) : (
                    <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                  )}
                  <p className="text-xl font-semibold mt-2 min-h-16">
                    {items.property_id?.title?.length > 45
                      ? items.property_id?.title?.slice(0, 45) + "..."
                      : items.property_id?.title}
                  </p>
                  <p className="text-sm text-color-grey">
                    An entire place booked for you
                  </p>
                  <button
                    className="border border-yellow-500 rounded-full px-5 py-2 mt-2 text-sm"
                    onClick={() => navigate(`/refund/${items._id}`)}
                  >
                    Cancel Booking
                  </button>
                  <div className="flex flex-col md:flex-row gap-2">
                  <button
                    className="bg-blue-800 rounded-full px-4 mt-10 text-white block py-2"
                    onClick={() =>
                      navigate(`/propertydetails/${items.property_id._id}`)
                    }
                  >
                   Property Detail 
                  </button>
                  <button
                    className="bg-blue-800 rounded-full px-4 mt-10 text-white block py-2"
                    onClick={() => handleReservationById(items.property_id._id)}
                  >
                    Booking Detail
                  </button>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-96 lg:w-80 min-h-fit relative">
                <img
                  src={items?.property_id?.pics[0]}
                  alt=""
                  className="w-full h-full object-cover"
                />
                <span className="bg-color-yellow px-4 py-2 absolute top-2 text-sm text-white rounded-full right-2">
                  Pending
                </span>
              </div>
            </div>
          );
        })
        }
      </div>
    </>
  );
};

export default UpComming;
