import React, { useState } from "react";
import PopupModal from "components/PopupModal/PopupModal";
import { useNavigate, useParams } from "react-router-dom";
import userServices from "services/httpService/userAuth/userServices";
import ErrorService from "services/formatError/ErrorService";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

const RefundStep3 = ({ step, setStep, reservationDetails, refundReason, refundPoints, refundServiceFee, reducedPoints }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id: reservationId } = useParams();

  const refundReservation = async () => {
    try {
      setLoading(true);

      const refundApiUrl = `/reservation/refundReservation/${reservationId}`;
      const updateReservationUrl = `/reservation/reservation/${reservationId}`;

      await userServices.refundReservation(refundApiUrl, { refundReason });
      await userServices.updateReservation(updateReservationUrl, { isRefundRequest: "true" });

      toast.success("Refund Request Successfully Processed");
      navigate("/");
    } catch (error) {
      toast.error(ErrorService.uniformError(error?.response?.data?.error));
    } finally {
      setLoading(false);
    }
  };

  const handleRefund = () => setOpenAlert(true);
  const handleCancelModal = () => setOpenAlert(false);
  const handleAcceptModal = () => {
    setOpenAlert(false);
    refundReservation();
  };

  const renderRow = (label, value, isBold = false) => (
    <div className="pt-2 flex justify-between items-center">
      <p className={isBold ? "font-bold py-2" : "py-2"}>{label}</p>
      <p className={isBold ? "font-bold" : ""}>{value}</p>
    </div>
  );

  return (
    <>
      {openAlert && (
        <PopupModal
          handleCancle={handleCancelModal}
          handleAccept={handleAcceptModal}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          description="Are you sure you want to proceed with the refund process? This action cannot be undone."
          title="Confirm Refund"
          acceptbtnText="Confirm"
        />
      )}

      <div>
        <div className="md:flex gap-10">
          <div>
            <p className="font-bold">Does this look right?</p>

            {renderRow("Total Price", `${reservationDetails?.offerState?.points} Points`)}
            <hr />
            {renderRow("Cleaning Fee", "0")}
            <hr />
            {renderRow("Service Fee", refundServiceFee)}
            <hr />
            {renderRow("Total eligible refund points", refundPoints, true)}
            {renderRow("Reduced points", reducedPoints, true)}
            {renderRow("Points due on reservation", reservationDetails?.offerState?.points)}
            <hr />
            {renderRow("Total Refund Points", refundPoints, true)}

            <p className="pt-2">
              Your reservation will be canceled immediately and you’ll be refunded within 10 days.
            </p>

            <div className="pt-2">
              <button
                className={`bg-blue-500 text-sm rounded p-2 px-4 text-white flex items-center justify-center ${loading ? "cursor-not-allowed" : ""}`}
                onClick={handleRefund}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <FaSpinner className="animate-spin mr-2" />
                    Processing...
                  </>
                ) : (
                  "Refund"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundStep3;
