import { CircularProgress, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import TripCard from "./TripCard";
import userServices from "services/httpService/userAuth/userServices";
import { localStorageData } from "services/auth/localStorageData";

const MyTrip = () => {
  const [active, setActive] = useState(1);
  const [upcoming, setUpcoming] = useState([]);
  const [history, setHistory] = useState([]);
  const [Cancelled, setCancelled] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleActive = (num) => {
    setActive(num);
  };
  let userId = localStorageData("_id");
  const getReservation = async () => {
    setLoading(true);
    try {
      const userId = localStorageData("_id");
      const apiUrl = `/reservation/reservations/${userId}`;
      let res = await userServices.getReservationByuserId(apiUrl)
  
      const currentDate = new Date();

      const upcomingReservation =
        res.data &&
        res.data.filter((item) => {
          return item.status === "pending";
        });
      const cancelledReservation =
        res.data &&
        res.data.filter((item) => {
          return item.status === "cancelled";
        });
      const completedReservation =
        res.data &&
        res.data.filter((item) => {
          return item.status === "completed";
        });

      setUpcoming(upcomingReservation);
      setHistory(completedReservation);
      setCancelled(cancelledReservation);
    } catch (error) {
      console.error("Error fetching reservation data", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getReservation();
  }, [active]);
  return (
    <>
      <section>
        <Container maxWidth="xl">
          <Container maxWidth="xl">
            <div className="my-4">
              <h1 className="text-xl font-medium ml-4">My Trips</h1>
            </div>
            <div className=" md:p-4 p-1">
              <div className="flex items-center flex-wrap border-b border-b-color-grey relative ">
                <p
                  onClick={() => handleActive(1)}
                  className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mr-2 relative -bottom-[0.5px] ${active == 1
                    ? "border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                    } `}
                >
                  Upcoming
                </p>
                <p
                  onClick={() => handleActive(2)}
                  className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 2
                    ? "border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                    } `}
                >
                  Cancelled
                </p>
                <p
                  onClick={() => handleActive(3)}
                  className={`px-4 py-2 cursor-pointer text-color-grey border-b-2 mx-2 relative -bottom-[0.5px] ${active == 3
                    ? " border-b-color-yellow text-color-black"
                    : "border-b-transparent"
                    }`}
                >
                  History
                </p>
              </div>

              {loading ? (
                <div className="flex justify-center items-center pt-20">
                  <CircularProgress />
                </div>
              ) : (
                <TripCard
                  active={active}
                  upcoming={upcoming}
                  history={history}
                  Cancelled={Cancelled}
                  currentUserId={userId}
                />
              )}
              {!loading && active === 1 && upcoming.length === 0 && (
                <div className="text-center p-4">
                  <p className="text-xl font-semibold py-5">No trips booked yet.</p>
                  <p className="text-md text-gray-500">
                    You have no upcoming trips at the moment. Start planning your next adventure!
                  </p>
                </div>
              )}
              {!loading && active === 2 && Cancelled.length === 0 && (
                <div className="text-center p-4">
                  <p className="text-center p-4 text-xl font-semibold py-5">No cancelled trips.</p>
                  <p className="text-md text-gray-500 text-center">
                    You have no cancelled trips at the moment. Your upcoming trips are all set to go!
                  </p>

                </div>
              )}
              {!loading && active === 3 && history.length === 0 && (
                <div className="text-center p-4">
                  <p className="text-xl font-semibold py-5">No past trips found.</p>
                  <p className="text-md text-gray-500">
                    It looks like you haven't taken any trips yet. Start planning your first adventure now!
                  </p>
                </div>
              )}
            </div>
          </Container>
        </Container>
      </section>
    </>
  );
};

export default MyTrip;
