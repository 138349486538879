import React from "react";
import { config } from "../../config";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Divider, Tooltip } from "@mui/material";
import { localStorageData } from "services/auth/localStorageData";
import userServices from "services/httpService/userAuth/userServices";
import WishlistButton from "common/buttons/WishlistButton/WishlistButton";
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

export default function HomeCard({
  data,
  favourite,
  favoriteRender,
  setFavoriteRender,
}) {
  const [userId, setUserId] = useState("");
  const coverImge = data.pics[0];
  const [image, setImage] = useState([]);

  const getUserData = async () => {
    try {
      let res = await userServices.userById(
        `/userAuth/user/${localStorageData("_id")}`
      );
      setUserId(localStorageData("_id"));
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    let allimages = data.pics.map((item) => {
      return config.ImageEndPoint + item;
    });
    setImage(allimages);
    getUserData();
  }, []);

  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    if (data?.reviews && data.reviews.length > 0) {
      const ratings = data.reviews.map((review) => review.rating);
      const total = ratings.reduce((acc, rating) => acc + rating, 0);
      const average = total / ratings.length;
      setAverageRating(Number(average.toFixed(1)));
    } else {
      setAverageRating(0);
    }
  }, [data?.reviews]);

  const minPoint = Array.isArray(data.points) && data.points.length > 0
    ? data.points.reduce((min, p) => (p.point < min ? p.point : min), data.points[0].point)
    : null;

  return (
    <>
      <div className="flex flex-wrap justify-between gap-6 pt-6">
        <div className="md:w-[265px] w-full">
          <Tooltip title={userId ? "" : "Please login to view details"} arrow>
            {userId ? (
              <Link to={`/propertydetails/${data._id}`}>
                <div
                  className="md:w-[265px] rounded-t-xl w-full h-[200px] bg-center bg-cover"
                  style={{
                    backgroundImage: `url(${coverImge})`,
                  }}
                ></div>
              </Link>
            ) : (
              <div
                className="md:w-[265px] rounded-t-xl w-full h-[200px] bg-center bg-cover pointer-events-none"
                style={{
                  backgroundImage: `url(${coverImge})`,
                }}
              ></div>
            )}
          </Tooltip>
          <div className="md:w-[265px] w-full py-3 h-auto rounded-b-xl bg-white border flex flex-col justify-between">
            <div className="px-4">
              <div className="flex justify-between">
                {averageRating > 0 ?
                  <div className="flex items-center gap-2">
                    <Rating
                      initialRating={averageRating}
                      readonly
                      emptySymbol={<FaRegStar color="#d3d3d3" />}
                      halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                      fullSymbol={<FaStar color="#ffd700" />}
                      fractions={2}
                    />
                    <span>({averageRating})</span>
                  </div>
                  :
                  <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                }
                <div className="">
                  <WishlistButton
                    propertyId={data._id}
                    favourite={favourite}
                    favoriteRender={favoriteRender}
                    setFavoriteRender={setFavoriteRender}
                  />
                </div>
              </div>
              <div style={{ height: "3.3rem" }} className="mb-2">
                <Tooltip title={userId ? "" : "Please login to view details"} arrow>
                  {userId ? (
                    <Link to={`/propertydetails/${data._id}`}>
                      <h4
                        className="text-[#000000] text-lg font-bold hover:text-blue-500 overflow-hidden"
                        style={{ display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}
                      >
                        {data.title}
                      </h4>
                    </Link>
                  ) : (
                    <h4
                      className="text-[#000000] text-lg font-bold pointer-events-none overflow-hidden"
                      style={{ display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}
                    >
                      {data.title}
                    </h4>
                  )}
                </Tooltip>
              </div>
              <div>
                <h4 className="text-[#000000] text-base font-medium">
                  {data.address.length > 25 ? data.address.substring(0, 25) + '...' : data.address}
                </h4>
              </div>
              <div className="text-[#AFB5C1]">
                <span className="text-base font-normal">
                  {data.spaceTypeDetail &&
                    data.spaceTypeDetail.bathrooms &&
                    data.spaceTypeDetail.bedrooms &&
                    data.spaceTypeDetail.guests &&
                    (
                      <>
                        Guests:{" "}
                        {data.spaceTypeDetail.guests || 0}{" | "}
                        Bedrooms:{" "}
                        {data.spaceTypeDetail.bedrooms || 0}{" "}
                        Bathrooms:{" "}
                        {data.spaceTypeDetail.bathrooms || 0}
                      </>
                    )}
                </span>
              </div>
            </div>
            <div className="pt-2">
              <Divider />
            </div>
            <div className="px-4 pb-2">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-[#AFB5C1] text-base font-normal">From</p>
                </div>
                <div className="flex items-center gap-1">
                  <span className="text-[#AFB5C1] text-sm line-through">
                    {minPoint}
                  </span>
                  <span className="text-[#C0392D] text-base">
                    {minPoint} Points
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}