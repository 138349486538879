import React, { useState, useEffect } from 'react'
import { DeviceHistory } from './DeviceHistory';
import { ChangePassword } from './ChangePassword';
import userServices from 'services/httpService/userAuth/userServices';
import { storeLocalData } from "services/auth/localStorageData";
import { localStorageData } from "services/auth/localStorageData";
export const LoginPrivacy = () => {
    const [selectedTab, setSelectedTab] = useState('password');
    const [userData, SetUserData] = useState(null);
    const [userConnect, setUserConnect] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState(null);
    const userdatabyId = async () => {
        try {
            const userId = localStorageData("_id");
            const apiUrl = `userAuth/userdatabyId/${userId}`;
            let res = await userServices.userDatagetById(apiUrl);
            SetUserData(res?.data.data); 
            storeLocalData(res?.data.data);
        } catch (error) {
            console.error("Error fetching userdatabyid data", error);
        }
    };
    const getDeviceInfo = async () => { 
        try { 
            let res = await userServices.getUserDeviceInfo(`/userAuth/users/${localStorageData('_id')}/devices`); 
            setDeviceInfo(res.data?.devices || []); 
        } catch (err) { 
            console.error(err); 
        }
     };

    useEffect(() => {
        userdatabyId();
        getDeviceInfo();
    }, []);
    useEffect(() => {
        if (userConnect) {
            userdatabyId();
        }
    }, [userConnect]);
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    return (
        <div className='md:my-10 md:mx-20 flex flex-col gap-4'>
            <div className="rounded-2xl border border-color-green h-auto w-full p-5">
                <div className="text-xl font-medium text-center text-gray-500 border-b-2 border-gray-200">
                    <ul className="flex flex-wrap -mb-px cursor-pointer">
                        <li className="me-2 text-[#AFB5C1]">
                            <p
                                onClick={() => handleTabClick('password')}
                                className={`inline-block p-4 border-b-2 ${selectedTab === 'password'
                                    ? 'border-yellow-500 rounded-t-lg text-black'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                    }`}
                            >
                                Change Password
                            </p>
                        </li>
                        <li className="me-2 ">
                            <p
                                onClick={() => handleTabClick('deviceHistory')}
                                className={`inline-block p-4 border-b-2 ${selectedTab === 'deviceHistory'
                                    ? 'border-yellow-500 rounded-t-lg text-black'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                    }`}
                            >
                                Device History
                            </p>
                        </li>
                    </ul>
                </div>
                {selectedTab === 'password' && <ChangePassword 
                userData={userData} 
                SetUserData={SetUserData} 
                userConnect={userConnect}
                setUserConnect={setUserConnect}
                />}
                {selectedTab === 'deviceHistory' && <DeviceHistory 
                deviceInfo={deviceInfo} 
                setDeviceInfo={setDeviceInfo}
                />}
            </div>
        </div>
    )
}
