import React from "react";

export default function MultiDec({ data, onSelect, isSelected }) {
  
  const handleClick = () => {
    onSelect(data);
  };

  return (
    <div
      onClick={handleClick}
      className={`cursor-pointer ${isSelected ? "bg-[#58C0D0] bg-opacity-[50%] overflow-hidden rounded-2xl" : "border-0"}`}
    >
      <div className="border-2 w-[150px] xl:w-[200px] border-[#C1E1C2] h-[73px] flex justify-center items-center rounded-2xl">
        <p className="text-center">{data.label}</p>
      </div>
    </div>
  );
}
