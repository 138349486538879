
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LocationInfo = ({ latitude, longitude }) => {
  const [wikiData, setWikiData] = useState(null);
  useEffect(() => {
    const apiUrl = `https://en.wikipedia.org/w/api.php?action=query&format=json&list=geosearch&gscoord=${latitude}%7C${longitude}&gsradius=1000&gslimit=1&origin=*`;
    
    axios.get(apiUrl)
      .then(response => {
        const pageId = response.data.query.geosearch[0].pageid;
  
        const extractUrl = `https://en.wikipedia.org/w/api.php?action=query&format=json&prop=extracts&pageids=${pageId}&exintro&explaintext&origin=*`;
        return axios.get(extractUrl);
      })
      .then((response) => {
        const page = response.data.query.pages;
        const firstPageId = Object.keys(page)[0];
        setWikiData(page[firstPageId].extract);
      })
      .catch((error) => {
        console.error("Error fetching Wikipedia data:", error);
      });
  }, [latitude, longitude]);
  

  return (
    <div>
      {wikiData ? (
        <div>
          <h2>Wikipedia Information</h2>
          <p>{wikiData}</p>
        </div>
      ) : (
        <p>Loading Wikipedia information...</p>

      )}
    </div>
  );
};

export default LocationInfo;
