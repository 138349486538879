import React, { useState, useEffect } from 'react';
import visaCard from "../../../../assets/img/cardTypes.jpg";
import { PaymentCard } from 'components/Cards/PaymentCard';
import { ButtonOutlined } from 'common/buttons/buttonOutlined';
import { useMutation } from "react-query";
import { toast } from 'react-toastify';
import userServices from "services/httpService/userAuth/userServices";
import { localStorageData } from "services/auth/localStorageData";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

export const Payments = () => {
  const [showAddCard, setShowAddCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardName, setCardName] = useState('');
  const [savedCard, setSavedCard] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const { mutate: fetchCard } = useMutation(
    () => userServices.userById(`/userAuth/user/${localStorageData("_id")}`),
    {
      onError: (error) => toast.error(error.message),
      onSuccess: (data) => {
        const { cardInfo } = data?.data || {};
        setSavedCard(cardInfo || null);
      },
    }
  );

  const { mutate: addOrUpdateCard } = useMutation(
    (reservationData) => userServices.Reservation('/stripe/AddCardInfo', reservationData),
    {
      onError: (error) => {
        toast.error("Failed to add card: " + error.message);
        setLoading(false);
      },
      onSuccess: () => {
        toast.success('Your card was added successfully!');
        setShowAddCard(false);
        fetchCard();
      },
    }
  );

  const handleAddCard = async () => {
    setLoading(true);
    if (!stripe || !elements) {
      toast.error("Stripe has not loaded properly.");
      setLoading(false);
      return;
    }

    if (!cardName.trim()) {
      toast.error("Please enter the name on the card.");
      setLoading(false);
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      toast.error("Invalid card details.");
      setLoading(false);
      return;
    }
    

    const { token, error } = await stripe.createToken(cardNumberElement);

    if (error) {
      toast.error(`Card error: ${error.message}`);
      setLoading(false);
      return;
    }

    try {
      await addOrUpdateCard({
        stripeToken: token.id,
        userId: localStorageData("_id"),
        cardName: cardName,
      });
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCardNameChange = (e) => {
    setCardName(e.target.value);
  };

  useEffect(() => {
    fetchCard();
  }, []);

  return (
    <div className="p-5 md:p-10 flex flex-col gap-5">
      <div className="flex flex-col gap-2">
        <div className="pb-4">
          <p className="text-2xl font-medium">Manage Payment</p>
          <p className="text-base font-normal text-[#4B4B4B]">
            You can manage your credit card from here. Having methods connected will make your checkout experience faster.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {savedCard ? (
            <div className="w-[100%]">
              <PaymentCard
                name={savedCard.cardName || "Unknown Card"}
                icon={visaCard}
                number={savedCard.cardNumber || "**** XXXX"}
                verification="verified"
              />
            </div>
          ) : (
            <p className="text-gray-500">No card saved. Add a card to manage payments.</p>
          )}
        </div>
      </div>
      <div className="w-[100%] mr-auto">
        {!showAddCard && (
          <ButtonOutlined text="Add Payments" style={{ width: "300px" }} onClick={() => setShowAddCard(true)} />
        )}
        {showAddCard && (
          <div className="p-2 border rounded-md border-[#C1E1C2]">
            <div className="flex justify-between">
              <h4 className="mt-2 mb-2 text-lg font-semibold">Credit/Debit Card</h4>
              <i className="fa-solid fa-x cursor-pointer" onClick={() => setShowAddCard(false)}></i>
            </div>
            <hr className="my-2 border-[#C1E1C2]" />

            <div className="flex justify-between gap-4">
              <div className="w-1/2">
                <label htmlFor="">Card Number*</label>
                <CardNumberElement className="input-styl placeholder:text-color-grey" />
              </div>
              <div className="w-1/2">
                <label htmlFor="">Name on Card*</label>
                <input
                  type="text"
                  name="cardName"
                  placeholder="Name on card"
                  className="input-styl placeholder:text-color-grey"
                  value={cardName}
                  onChange={handleCardNameChange}
                />
              </div>
            </div>

            <div className="flex justify-between gap-4 mt-4">
              <div className="w-1/2">
                <label htmlFor="">Expiration Date*</label>
                <CardExpiryElement className="input-styl placeholder:text-color-grey" />
              </div>
              <div className="w-1/2">
                <label htmlFor="">CVC*</label>
                <CardCvcElement className="input-styl placeholder:text-color-grey" />
              </div>
            </div>

            <button
              className="btn-styl-blue mt-4"
              onClick={handleAddCard}
              disabled={loading}
            >
              {loading ? 'Adding Card...' : 'Add Card'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
