import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Link, useLocation } from "react-router-dom";
import { localStorageData } from "services/auth/localStorageData";
import IndexDropdown from "components/Dropdowns/IndexDropdown";
import { IoIosArrowRoundForward } from "react-icons/io";
import userServices from "services/httpService/userAuth/userServices";

function BnbNav() {
  let userData = localStorageData("roles");
  let isInfluencer = userData?.includes("Influencer");
  let location = useLocation();
  const [loginAs, setLoginAs] = useState("");
  const [points, setPoints] = useState("");

  const getUserData = async () => {
    let res = await userServices.userById(
      `/userAuth/user/${localStorageData("_id")}`
    );
    setPoints(res?.data.points);
  };

  useEffect(() => {
    const loginAsValue = localStorageData("loginAs");
    setLoginAs(loginAsValue);
    getUserData();
  }, []); 

  const handleChangeRole = (newRole) => {
    setLoginAs(newRole);
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    window.location.href = '/';
  };

  return (
    <nav
      className={`${
        location.pathname === "/"
          ? "absolute top-0 left-0 z-50 w-full text-white"
          : "relative top-0 left-0 overflow-hidden w-full bnbNvar_wrappe text-black shadow bg-[#fbfffb]"
      }`}
    >
      <Grid container spacing={2} className="h-24 bnbNvar_wrapper_home">
        <Grid item xs={4} sm={3} className="flex items-center justify-center">
          <div
            className="bnbLogo"
            style={{ 
              position: "relative",
              zIndex: 9999
            }}
          >
            <div className="relative inline-block py-2 ml-4 text-sm font-bold leading-relaxed uppercase whitespace-nowrap">
              <Link 
                to="/" 
                onClick={handleLogoClick}
                style={{
                  position: 'relative',
                  zIndex: 9999,
                  cursor: 'pointer',
                  display: 'block',
                  pointerEvents: 'all'
                }}
              >
                {location.pathname === "/" ? (
                  <img
                    className="object-fit w-full h-12"
                    src={require("assets/img/whitelogo.png")}
                    alt="Logo"
                    style={{
                      display: "block",
                      pointerEvents: "all",
                      zIndex: 9999,
                      position: 'relative'
                    }}
                  />
                ) : (
                  <img
                    className="object-fit w-[93px] h-[49.06px]"
                    src={require("assets/img/blacklogo.png")}
                    alt="Logo"
                    style={{
                      display: "block",
                      pointerEvents: "all",
                      zIndex: 9999,
                      position: 'relative'
                    }}
                  />
                )}
              </Link>
              {location.pathname === "/" &&
                localStorageData("_id") &&
                localStorageData("subscription") === true && (
                  <>
                    <div>
                      <img
                        src={require("assets/img/badgeline.png")}
                        alt=""
                        className="absolute z-10 top-12 md:left-[50px] left-9"
                        style={{ zIndex: 99 }}
                      />
                      <div className="absolute top-24 sm:left-0 md:left-0 md:w-[200px] sm:w-[100px]">
                        <div className="relative">
                          <img
                            src={require("assets/img/pointbadge.png")}
                            alt=""
                            className=""
                          />
                          <div className="absolute top-7 left-3 md:top-9 md:left-6">
                            <div className="flex flex-col justify-center items-center">
                              <p className="font-semibold md:text-4xl text-2xl pl-0">
                                {points}
                              </p>
                              <p className="flex items-center font-thin capitalize pl-2 md:pl-0">
                                Points{" "}
                                <span>
                                  <IoIosArrowRoundForward className="text-xl md:ml-2" />
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </Grid>
        <Grid item xs={8} sm={8} className="">
          <ul className="items-center justify-end hidden h-full bnbNav-items lg:flex">
            <li>
              <Link to="/blogs">Blogs & News</Link>
            </li>
            <li>
              <Link to="/aboutus">About Us</Link>
            </li>
            <li>
              <Link to="/contactus">Contact Us</Link>
            </li>
            {(loginAs === "Host" || isInfluencer) && (
              <li className="px-2 py-2">
                <Link to="/propertylisting">List Your Properties</Link>
              </li>
            )}

            <li className="">
              {localStorageData("_id") ? (
                <IndexDropdown onRoleChange={handleChangeRole} />
              ) : (
                <Link to="/auth/signin">
                  <div className="bg-white flex gap-2 items-center rounded-full w-[104.25px] h-[37.27px]">
                    <img
                      className="object-contain w-[28px] ml-2 h-[28px] rounded-full"
                      src={require("assets/img/log.png")}
                    />
                    <button className="text-[#000000] text-base font-normal">
                      Login
                    </button>
                  </div>
                </Link>
              )}
            </li>
          </ul>
        </Grid>
      </Grid>
    </nav>
  );
}

export default BnbNav;