import { useMutation } from "react-query";
import { useState } from "react";
import Container from "@mui/material/Container";
import { toast } from "react-toastify";
import userService from "../../services/httpService/userAuth/userServices";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  
  // Use useMutation with the new service method
  const { mutate, isLoading } = useMutation(
    (email) => userService.subscribeNewsletter(email),
    {
      onError: (error) => {
        toast.error(`Error: ${error.message}`);
      },
      onSuccess: () => {
        toast.success("Successfully subscribed!");
        setEmail("");
      }
    }
  );

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      mutate(email);
    }
  };

  return (
    <div className="flex justify-center w-full">
      <section className="flex relative z-30 md:top-20 w-[94%] lg:mb-10">
        <div className="">
          <img
            src={require("../../assets/img/icon.png")}
            className="absolute left-[-20px] lg:w-[170.64px] lg:h-[261.17px] lg:block hidden opacity-[21%] z-[-10]"
          />
        </div>
        <Container
          sx={{
            width: "100%",
            maxWidth: "100%",
            "@media (min-width: 1024px)": {
              width: "90%",
              maxWidth: "90%",
            },
          }}
          style={{ paddingLeft: "6px", paddingRight: "6px" }}
        >
          <div
            className="bg-[#AF3227]/95 md:h-[300px] h-full lg:w-[95%] xl:w-[96%] mx-auto rounded-2xl"
            style={{
              backgroundImage: "url(" + require("assets/img/badgebg.png") + ")",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Container>
              <div className="items-center w-full justify-between md:h-[300px] h-[500px] md:flex">
                <div className="lg:pl-10">
                  <div className="md:w-[445px] flex items-center justify-center w-full">
                    <p className="text-white text-[25px] text-center pt-3 md:pt-0 md:text-[36px] font-bold">
                      Get travel tips, offers and inspiration from BnByond
                    </p>
                  </div>
                  <div className="relative pt-4">
                    <input
                      type="email"
                      placeholder="Email address"
                      className="md:w-[400px] lg:w-[495px] bg-transparent pl-5 border text-white placeholder-white border-white w-full h-[50px] rounded-2xl"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      className="text-[#C0392D] absolute right-0 text-base font-medium bg-white rounded-2xl md:w-[150px] w-full h-[50px]"
                      onClick={handleSubmit}
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting..." : "Subscribe"}
                    </button>
                  </div>
                </div>
                <div>
                  <img src={require("assets/img/downrrow.png")} />
                </div>
                <div className="relative">
                  <div className="lg:w-80 lg:h-52 md:w-full md:h-40 w-full mt-6 md:mt-0 h-full border-4 border-white rounded-xl transform rotate-6">
                    <img
                      src={require("assets/img/squadNew.jpeg")}
                      className="w-full h-full rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Container>
        <div className="">
          <img
            src={require("../../assets/img/icon.png")}
            className="absolute right-[-20px] lg:w-[170.64px] lg:h-[261.17px] lg:block hidden opacity-[21%] transform scale-x-[-1] z-[-10]"
          />
        </div>
      </section>
    </div>
  );
};

export default NewsLetter;