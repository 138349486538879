import BnbNavMob from 'components/NavBar/BnbNavMob';
import React from 'react';

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <>
      <nav className='absolute top-0 z-50 flex flex-wrap items-center justify-between w-full px-2 py-3 navbar-expand-lg overflow-hidden'>
        <div className=' flex flex-wrap items-center justify-between px-4 w-full'>
          <div className='relative flex justify-end w-full lg:w-auto lg:static lg:block lg:justify-start'>

            <button
              className='block px-3 pt-5 text-xl leading-none bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer lg:hidden focus:outline-none '
              type='button'
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className='text-gray-500 fas fa-bars'></i>
            </button>
          </div>
          <div
            className={
              'lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none' +
              (navbarOpen ? 'absolute top-0 w-full m-0 block rounded shadow-lg' : ' hidden')
            }
            id='example-navbar-warning'
          >
            <BnbNavMob navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />

          </div>
        </div>
      </nav>
    </>
  );
}
