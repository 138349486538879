import React, { useEffect, useState } from "react";
import { ButtonOutlined } from "common/buttons/buttonOutlined";
import checkedImg from "../../assets/img/checkedImg.png";
import locationImg from "../../assets/img/locationimg.png";
import { localStorageData } from "services/auth/localStorageData";
import { storeLocalData } from "services/auth/localStorageData";
import { MdOutlineCancel } from "react-icons/md";
import { useMutation } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";
import { useNavigate } from "react-router-dom";
import PopupModal from "components/PopupModal/PopupModal";
import { loadStripe } from "@stripe/stripe-js";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import config from "../../config";

const userId = localStorageData("_id");

export const ProfileLeftSection = ({ hidediv }) => {
  const [showModal, setShowModal] = useState(false);
  const [stripeCode, setStripeCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userConnect, setUserConnect] = useState(false);
  const [userData, SetUserData] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  let navigate = useNavigate();
  let standard_clientId = config.Standard_ClientId;
  let stripePromise = loadStripe(config.stripe_publishkey);

  const { mutate: passMutate, isLoading: isAuthorize } = useMutation(
    (token) =>
      userServices.stripeauthorize(`stripe/authorize-seller/${userId}`, token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        storeLocalData(data?.data.userData);
        navigate("/profile");
      },
    }
  );

  const navigateToAccount = () => {
    navigate("/account");
  };

  const connectAccount = async () => {
    toast.error("This feature will be available soon. Stay tuned for updates!");
    setTimeout(() => {
      toast.success("Purchase a plan now and start enjoying your travels!");
    }, 10000);
    setClickCount((prev) => prev + 1);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    setStripeCode(code);
    if (code && state) {
      setShowModal(true);
    }
  }, []);

  const { mutate: setMutate, isLoading: loadingg } = useMutation(
    (token) =>
      userServices.googleVerification(
        `/userAuth/gmailVerification/${userId}`,
        token
      ),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setUserConnect(true);
      },
    }
  );

  const { mutate: subscriptionMutate } = useMutation(
    (token) =>
      userServices.stripeauthorize(`stripe/create-checkout-session`, token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
        setDisableBtn(false);
      },
      onSuccess: (data) => {
        let sessionId = data.data?.sessionId;
  
        if (data.data.success) {
          toast.success(data.data.message);
          setDisableBtn(false);
        }
  
  
        if (sessionId) {
          (async () => {
            const stripe = await stripePromise;  
            if (!stripe) {
              console.error("Stripe.js failed to load.");
              return;
            }
            const result = await stripe.redirectToCheckout({ sessionId });
            if (result.error) {
              console.error("Stripe Checkout Error:", result.error.message);
              toast.error(`Error: ${result.error.message}`);
            }
          })();
        } else {
          console.error("Session ID is undefined");
        }
      },
    }
  );
  

  const userdatabyId = async () => {
    try {
      const userId = localStorageData("_id");
      const apiUrl = `userAuth/userdatabyId/${userId}`;
      let res = await userServices.userDatagetById(apiUrl);
      SetUserData(res?.data.data);
      storeLocalData(res?.data.data);
    } catch (error) {
      console.error("Error fetching userdatabyid data", error);
    }
  };

  useEffect(() => {
    userdatabyId();
  }, [userConnect, disableBtn]);

  const handleGoogleVerification = () => {
    setMutate();
  };

  const handleOpenModal = () => {
    if (userData?.verify === "no") {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubscription = () => {
    setDisableBtn(true);
    subscriptionMutate({
      email: localStorageData("email"),
      userId: localStorageData("_id"),
    });
  };

  return (
    <div>
      <div className=" mx-auto bg-white rounded-xl overflow-hidden border border-[#C1E1C2] p-6  md:w-[360px] w-full">
        <div className="flex items-center justify-center">
          <img
            className="object-cover w-40 h-40 text-center rounded-full"
            src={localStorageData("pic") || require("assets/img/profile.png")}
            alt="not found"
          />
        </div>
        <div className="pb-10 mt-4 space-y-8 text-center">
          <div>
            <p className="text-4xl font-medium capitalize">
              {localStorageData("fname")} {localStorageData("lname")}
            </p>
            <p className="text-[#929293] text-center font-Lato text-[16px] font-normal leading-[22px] capitalize">
              {localStorageData("loginAs") && localStorageData("loginAs").includes("admin") ? "Admin" :
                localStorageData("loginAs") && localStorageData("loginAs").includes("Host") ? "Host" :
                  localStorageData("loginAs") && localStorageData("loginAs").includes("Guest") ? "Guest" : "Ambassador"}
            </p>

            <div className="flex items-center justify-center mb-4">
              <div className="px-2">
                <img src={locationImg} alt="location" />
              </div>
              <p className="flex items-center text-[#3D3D3D] text-center font-Lato text-[14px] font-medium leading-[20px]">
                {localStorageData("address")}
              </p>
            </div>
          </div>
          <hr className="my-4 border-t border-[#C1E1C2]" />
          <div className="space-y-2">
            <p className="text-xl font-semibold ">
              <span className="capitalize">{localStorageData("fname")}</span>{" "}
              confirmed information
            </p>
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center w-8 h-8">
                {localStorageData("verify") === "no" ? (
                  <MdOutlineCancel className="text-lg text-[#A0DBE4]" />
                ) : (
                  <img src={checkedImg} alt="location" />
                )}
              </div>
              <p className="text-[#929293] text-center font-Lato text-[16px] font-normal leading-[22px]">
                {localStorageData("email")}
              </p>
            </div>
          </div>

          <hr className="my-4 border-t border-[#C1E1C2]" />

          <div className="">
            <div className="space-y-2">
              <p className="text-xl font-semibold">Verify your identity </p>
              {localStorageData("verify") === "no" ? (
                <p className="  text-[#929293] text-center font-Lato text-[16px] font-normal leading-[22px]">
                  Before you book or Host on BnByond, you'll need to complete
                  this step.
                </p>
              ) : (
                ""
              )}
            </div>

            <button
              className="pt-5 w-full md:w-[100%] lg:w-4/5 flex mx-auto"
              onClick={handleOpenModal}
            >
              {userData?.verify === "no" ? (
                <p className="w-full py-2 px-5 rounded-full border-yellow-500 text-yellow-500 border text-lg font-semibold">
                  Verify Account
                </p>
              ) : (
                <div className="w-full flex justify-center items-center mx-auto border border-yellow-500 py-2 px-5 rounded-full">
                  <span className=" text-green-500 text-lg font-semibold">
                    Verified
                  </span>
                  <img
                    src={checkedImg}
                    alt="verification"
                    className="pl-1 w-5 h-5"
                    style={{ filter: "hue-rotate(300deg) saturate(4)" }}
                  />
                </div>
              )}
            </button>
            <PopupModal
              icon={
                <div className="bg-green-200 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
              }
              handleCancle={handleCloseModal}
              handleAccept={() => {
                handleGoogleVerification();
                if (userData?.verify === "no") {
                  toast.success("verification email has been sent to you!");
                }

                setIsModalOpen(false);
              }}
              openAlert={isModalOpen}
              setOpenAlert={setIsModalOpen}
              description={
                userData?.verify === "no"
                  ? "Do you want to verify your account?"
                  : ""
              }
              title={userData?.verify === "no" ? "Verify Your Account" : ""}
              acceptbtnText={
                userData?.verify === "no" ? (
                  <div className="inline-flex w-full  rounded-md  py-2 text-sm font-bold shadow-sm ">
                    Verify
                  </div>
                ) : (
                  ""
                )
              }
            />
          </div>

          <div className="w-full md:w-[100%] lg:w-4/5 flex mx-auto mt-5">
            <ButtonOutlined
              onClick={navigateToAccount}
              text="Advanced Settings"
            />
          </div>

          <div className="w-full md:w-[100%] lg:w-4/5 flex flex-col items-center justify-center gap-4 mx-auto mt-5">
            <button 
              onClick={connectAccount}
              disabled={ clickCount <= 1} // Disable after 3 clicks
              className={`w-[100%] py-2 rounded-full border-yellow-500 bg-[#fff] text-yellow-500 border text-lg font-semibold flex items-center justify-center ${ clickCount >= 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                Billing 
            </button>
            {userData && userData.subscription ? (
              <div className="w-[100%] py-2.5 rounded-full border-yellow-500 bg-[#fff] text-white border text-base font-semibold text-center flex items-center justify-center">
                <span className="text-green-500">Plan Subscribed</span>
                <img
                  src={checkedImg}
                  alt="verification"
                  className="pl-1 w-5 h-5"
                  style={{ filter: "hue-rotate(300deg) saturate(4)" }}
                />
              </div>
            ) : (
              <button
                onClick={handleSubscription}
                disabled={disableBtn}
                className="w-full mt-4 py-2 rounded-full border-yellow-500 text-yellow-500 border text-lg font-semibold flex items-center justify-center"
              >
                <span>Subscription Details</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
