import React, { createContext, useContext, useState, useEffect } from 'react'
import { localStorageData } from 'services/auth/localStorageData'
import userServices from 'services/httpService/userAuth/userServices'

const ChatContext = createContext()

export const useChat = () => useContext(ChatContext)

export const ChatProvider = ({ children }) => {
    const [conversationData2, setConversationData2] = useState(null)
    const [messagesData2, setMessagesData2] = useState(null)
    const [loading2, setLoading2] = useState(false)
    const [onlineUsers2, setOnlineUsers2] = useState(null)
    const [error, setError] = useState(null)
    const [socket, setSocket] = useState(null)
    const userId = localStorageData("_id")

    const fetchMessagesData2 = async (conversationId, user) => {
        try {
            setLoading2(true)
            const res = await userServices.fetchMessages(`/chat/message/${conversationId}`)
            setMessagesData2({ messages: res?.data, receiver: user, conversationId })
        } catch (error) {
            console.error(error)
            setError(error)
        } finally {
            setLoading2(false)
        }
    }
    const fetchConversations = async () => {
        try {
            setLoading2(true)
            const res = await userServices.getConversation(`/chat/conversation/${userId}`)
            setConversationData2(res?.data)
        } catch (error) {
            setError(error)
        } finally {
            setLoading2(false)
        }
    }

    useEffect(() => {
        if (socket) {
            socket.emit('addUser', userId)
            socket.on('getUsers', users => {
                setOnlineUsers2(users)
            })
            return () => {
                socket.off('getUsers')
            }
        }
    }, [socket, userId])

    return (
        <ChatContext.Provider
            value={{
                error,
                socket,
                loading2,
                setLoading2,
                onlineUsers2,
                messagesData2,
                setMessagesData2,
                conversationData2,
                fetchConversations,
                fetchMessagesData2,
            }}
        >
            {children}
        </ChatContext.Provider>
    )
}
